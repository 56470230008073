// import React from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Table,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableContainer,
//   Paper,
//   Typography,
//   TableHead,
//   IconButton,
//   Box,
//   Grid,
//   Link,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import CustomTypography from "../../globalStyles/fonts";
// import { format } from "date-fns";
// import config from "../../config";

// const OrderDetailsModal = ({ open, onClose, order, edit }) => {
//   if (!order) return null;

//   const handleUpdateStatus = (order) => {
//     console.log("Updating status for order:", order.order_id);
//     // Example: Update the order status in your application state or database
//     // setOrder({ ...order, status: "Updated Status" });
//   };

//   const handleIssueRefund = () => {
//     console.log("Issuing refund for order:", order.order_id);

//     // setOrder({ ...order, refundStatus: "Processing" });

//     onClose();
//   };

//   const handlePrintInvoice = (order) => {
//     console.log("Printing invoice for order:", order._id);

//     // window.open(`/printable-invoice/${order.order_id}`, "_blank");
//   };
//   const formattedDate = format(
//     new Date(order.order_date),
//     "yyyy/MM/dd, HH:mm:ss"
//   );

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
//       <div
//         style={{
//           justifyContent: "space-between",
//           display: "flex",
//           borderWidth: 1,
//           borderColor: "#CACEE3",
//           alignItems: "center",
//           padding: 10,
//         }}
//       >
//         <CustomTypography fontSize={20} fontWeight={500} color={"#000000"}>
//           Order Details
//         </CustomTypography>
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             color: "#000000",
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </div>

//       <DialogContent
//         sx={{
//           overflowY: "auto",
//           "&::WebkitScrollbar": {
//             width: "5px",
//           },
//           "&::WebkitScrollbar-track": {
//             backgroundColor: "#D9D9D9",
//             borderRadius: 14,
//           },
//           "&::WebkitScrollbar-thumb": {
//             backgroundColor: "#273894",
//             borderRadius: "6px",
//             "&:hover": {
//               backgroundColor: "#555",
//             },
//           },
//         }}
//       >
//         <div
//           style={{
//             borderWidth: 0.5,
//             borderColor: "#979797",
//             borderRadius: 8,
//           }}
//         >
//           <div
//             style={{
//               justifyContent: "space-between",
//               padding: 10,
//             }}
//           >
//             <div
//               style={{
//                 margin: 10,
//                 display: "flex",
//                 justifyContent: "space-between",
//               }}
//             >
//               <CustomTypography
//                 fontSize={16}
//                 color={"#333333"}
//                 fontWeight={600}
//               >
//                 Order ID : {order.order_id}
//               </CustomTypography>
//               <div
//                 style={{
//                   display: "flex",
//                 }}
//               >
//                 <CustomTypography
//                   fontSize={13}
//                   variant="body1"
//                   sx={{
//                     cursor: "pointer",
//                     marginRight: 2,
//                     textDecoration: "underline",
//                     color: "#2C41FF",
//                   }}
//                   onClick={() => handleUpdateStatus(order)}
//                 >
//                   [ Update Status ]
//                 </CustomTypography>
//                 <CustomTypography
//                   fontSize={13}
//                   variant="body1"
//                   sx={{
//                     cursor: "pointer",
//                     marginRight: 2,
//                     textDecoration: "underline",
//                     color: "#2C41FF",
//                   }}
//                   onClick={handleIssueRefund}
//                 >
//                   [ Issue Refund ]
//                 </CustomTypography>
//                 <CustomTypography
//                   fontSize={13}
//                   variant="body1"
//                   sx={{
//                     cursor: "pointer",
//                     textDecoration: "underline",
//                     color: "#2C41FF",
//                   }}
//                   onClick={() => handlePrintInvoice(order)}
//                 >
//                   [ Print Invoice ]
//                 </CustomTypography>
//               </div>
//             </div>
//             <div
//               style={{
//                 backgroundColor: "#FFF3CF",
//                 borderWidth: 1,
//                 borderRadius: 6,
//                 borderColor: "#FF8A00B3",
//                 padding: 6,
//                 width: "75px",
//                 marginTop: 10,
//                 display: "flex",
//                 justifyContent: "center",
//                 marginLeft: 10,
//               }}
//             >
//               <CustomTypography
//                 color={"#FF8A00"}
//                 fontSize={12}
//                 fontWeight={500}
//               >
//                 {order.status}
//               </CustomTypography>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 marginTop: 10,
//                 gap: 15,
//                 marginLeft: 10,
//               }}
//             >
//               <span
//                 style={{
//                   backgroundColor: "#EEEEEEF0",
//                   padding: 7,
//                   borderRadius: 4,
//                 }}
//               >
//                 <CustomTypography
//                   fontSize={12}
//                   fontWeight={500}
//                   color={"#333333"}
//                 >
//                   Placed on : {formattedDate}
//                 </CustomTypography>
//               </span>
//               <span
//                 style={{
//                   backgroundColor: "#0076131A",
//                   padding: 7,
//                   borderRadius: 4,
//                 }}
//               >
//                 <CustomTypography
//                   fontSize={12}
//                   fontWeight={500}
//                   color={"#007613"}
//                 >
//                   Paid on : {}
//                 </CustomTypography>
//               </span>
//               <span
//                 style={{
//                   backgroundColor: "#007DFF33",
//                   padding: 7,
//                   borderRadius: 4,
//                 }}
//               >
//                 <CustomTypography
//                   fontSize={12}
//                   fontWeight={500}
//                   color={"#007DFF"}
//                 >
//                   Updated on : {}
//                 </CustomTypography>
//               </span>
//             </div>
//           </div>
//         </div>
//         <div style={{ width: "100%", display: "flex", marginTop: 10, gap: 10 }}>
//           <div
//             style={{
//               width: "50%",
//               borderWidth: 0.5,
//               borderColor: "#979797",
//               backgroundColor: "#FFFFFF",
//               borderRadius: 6,
//               minHeight: 400,
//             }}
//           >
//             <div style={{ margin: 20, gap: 10, padding: 10 }}>
//               <CustomTypography
//                 fontSize={18}
//                 fontWeight={500}
//                 color={"#333333"}
//               >
//                 Customer & Order
//               </CustomTypography>
//               <Box mt={2}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Name
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {order.customer.first_name} {order.customer.last_name}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Email
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {" "}
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {order.customer.email}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Phone No
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {order.customer.phone}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Payment Method
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {order.payment_method}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Total Bill
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {" "}
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {order.total_line_items_price}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Billing Address
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {order.billing_address.address1}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Shipping Address
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 4 }}
//                       >
//                         {order.shipping_address.name +
//                           ", " +
//                           (order.shipping_address.address1 || "") +
//                           ", " +
//                           (order.shipping_address.address2 || "") +
//                           ", " +
//                           (order.shipping_address.city || "") +
//                           ", " +
//                           (order.shipping_address.state || "") +
//                           ", " +
//                           (order.shipping_address.zip || "") +
//                           ", " +
//                           order.shipping_address.country}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Box>
//               <div></div>
//             </div>
//           </div>
//           <div
//             style={{
//               width: "50%",
//               borderWidth: 0.5,
//               borderColor: "#979797",
//               backgroundColor: "#FFFFFF",
//               borderRadius: 6,
//               minHeight: 400,
//             }}
//           >
//             <div style={{ margin: 20, gap: 10, padding: 10 }}>
//               <CustomTypography
//                 fontSize={18}
//                 fontWeight={500}
//                 color={"#333333"}
//               >
//                 Shipping information
//               </CustomTypography>
//               <Box mt={2}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Shipping Method
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {new Set(order.shipments.map((x) => x.provider))}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Tracking Number
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {" "}
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {new Set(order.shipments.map((x) => x.tracking_number))}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Expected Delivery
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Shipping Cost
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {" "}
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Shipping ID
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {new Set(order.shipments.map((x) => x.shipment))}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Label
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {new Set(order?.shipments?.map((x) => x.label_file))
//                         .values()
//                         .toArray()
//                         .map((x) => ({ link: x?.split("/")[1], key: x }))
//                         .map((x) => (
//                           <Link
//                             target="_blank"
//                             href={`${config.baseURL}/fetch-file/${x.key}`}
//                             onClick={() =>
//                               `${config.baseURL}/fetch-file/${x.key}`
//                             }
//                             fontSize={14}
//                             fontWeight={400}
//                             fontFamily={"Poppins"}
//                             style={{ marginLeft: 5 }}
//                           >
//                             {x.link}
//                           </Link>
//                         ))}
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Over-Pack ID
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {new Set(order.shipments.map((x) => x.overpack))}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Manifest ID
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {" "}
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {new Set(order.shipments.map((x) => x.manifest))}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Master AWB
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       {" "}
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Bill of Lading (BOL)
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Weight
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Dimension
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Typography
//                       fontSize={14}
//                       fontWeight={400}
//                       color={"#333333"}
//                       fontFamily={"Poppins"}
//                     >
//                       Last Mile Delivery Details
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={7} container alignItems="flex-start">
//                     <Grid item>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                       >
//                         :
//                       </Typography>
//                     </Grid>
//                     <Grid item xs>
//                       <Typography
//                         fontSize={14}
//                         fontWeight={400}
//                         color={"#333333"}
//                         fontFamily={"Poppins"}
//                         style={{ marginLeft: 5 }}
//                       >
//                         {}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Box>
//               <div></div>
//             </div>
//           </div>
//         </div>
//         <div
//           style={{
//             borderWidth: 0.5,
//             borderRadius: 8,
//             borderColor: "#979797",
//             padding: 15,
//             marginTop: 10,
//           }}
//         >
//           <CustomTypography fontSize={18} fontWeight={500} color={"#333333"}>
//             ITEMS ORDERED
//           </CustomTypography>
//           <TableContainer
//             sx={{
//               maxHeight: "400px",
//               overflowY: "auto",
//               overflowY: "auto",
//               "&::WebkitScrollbar": {
//                 width: "5px",
//               },
//               "&::WebkitScrollbar-track": {
//                 backgroundColor: "#D9D9D9",
//                 borderRadius: 14,
//               },
//               "&::WebkitScrollbar-thumb": {
//                 backgroundColor: "#273894",
//                 borderRadius: "6px",
//                 "&:hover": {
//                   backgroundColor: "#555",
//                 },
//               },
//               marginTop: 2,
//             }}
//           >
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell
//                     style={{
//                       backgroundColor: "#EEEEEE",
//                       borderRight: "1px solid #9F9F9F",
//                       width: "25%",
//                       border: "1px solid #9F9F9F",
//                       borderRadius: 0,
//                     }}
//                   >
//                     <CustomTypography fontSize={14}>
//                       Product Name
//                     </CustomTypography>
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       backgroundColor: "#EEEEEE",
//                       borderRight: "1px solid #9F9F9F",
//                       width: "25%",
//                       border: "1px solid #9F9F9F",
//                     }}
//                   >
//                     <CustomTypography fontSize={14}>Quantity</CustomTypography>
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       backgroundColor: "#EEEEEE",
//                       borderRight: "1px solid #9F9F9F",
//                       width: "25%",
//                       border: "1px solid #9F9F9F",
//                     }}
//                   >
//                     <CustomTypography fontSize={14}>
//                       Unit Price
//                     </CustomTypography>
//                   </TableCell>
//                   <TableCell
//                     style={{
//                       backgroundColor: "#EEEEEE",
//                       borderRight: "1px solid #9F9F9F",
//                       width: "25%",
//                       border: "1px solid #9F9F9F",
//                     }}
//                   >
//                     <CustomTypography fontSize={14}>Price</CustomTypography>
//                   </TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {order.line_items.map((item, index) => (
//                   <>
//                     <TableRow key={index}>
//                       <TableCell
//                         style={{
//                           borderRight: "1px solid #9F9F9F",
//                           border: "1px solid #9F9F9F",
//                           maxWidth: 350,
//                         }}
//                       >
//                         <CustomTypography multiline fontSize={14}>
//                           {item.name}
//                         </CustomTypography>
//                       </TableCell>
//                       <TableCell
//                         style={{
//                           borderRight: "1px solid #9F9F9F",
//                           border: "1px solid #9F9F9F",
//                         }}
//                       >
//                         <CustomTypography fontSize={14}>
//                           {item.quantity}
//                         </CustomTypography>
//                       </TableCell>
//                       <TableCell
//                         style={{
//                           borderRight: "1px solid #9F9F9F",
//                           border: "1px solid #9F9F9F",
//                         }}
//                       >
//                         <CustomTypography fontSize={14}>
//                           {item.price}
//                         </CustomTypography>
//                       </TableCell>
//                       <TableCell
//                         style={{
//                           borderRight: "1px solid #9F9F9F",
//                           border: "1px solid #9F9F9F",
//                         }}
//                       >
//                         <CustomTypography fontSize={14}>
//                           {item.price}
//                         </CustomTypography>
//                       </TableCell>
//                     </TableRow>
//                   </>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </div>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default OrderDetailsModal;
import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Typography,
  TableHead,
  IconButton,
  Box,
  Grid,
  Link,
  TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomTypography from '../../globalStyles/fonts'
import { format } from 'date-fns'
import config from '../../config'
import { orderService } from '../../utils/services/orderService'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '../../globalStyles/mobile'

const OrderDetailsModal = ({ open, onClose, order, edit, fetchOrderData }) => {
  const [editingItem, setEditingItem] = useState(null)
  const [editedValues, setEditedValues] = useState({})
  const navigate = useNavigate()
  if (!order) return null

  const handleUpdateStatus = (order) => {
    console.log('Updating status for order:', order.order_id)
    // Example: Update the order status in your application state or database
    // setOrder({ ...order, status: "Updated Status" });
  }

  const handleIssueRefund = () => {
    console.log('Issuing refund for order:', order.order_id)

    // setOrder({ ...order, refundStatus: "Processing" });

    onClose()
  }

  const handlePrintInvoice = (order) => {
    console.log('Printing invoice for order:', order._id)

    // window.open(`/printable-invoice/${order.order_id}`, "_blank");
  }

  const handleEditClick = (index, field) => {
    setEditingItem({ index, field })
    setEditedValues({
      ...editedValues,
      [field]: order.line_items[index][field]
    })
  }

  const handleInputChange = (field, value) => {
    setEditedValues({
      ...editedValues,
      [field]: value
    })
  }

  const handleSave = async (index) => {
    try {
      const updatedItem = {
        ...order.line_items[index],
        ...editedValues
      }
      const updatedOrder = {
        ...order,
        line_items: [...order.line_items.slice(0, index), updatedItem, ...order.line_items.slice(index + 1)]
      }
      const res = await orderService.edit(updatedOrder, order._id)
      if (res?._id) {
        console.log(res)
        fetchOrderData()
      }
      setEditingItem(null)
      onClose()
    } catch (error) {
      console.error('Error updating order:', error)
    }
  }

  const handleDelete = async (index) => {
    try {
      const updatedOrder = {
        ...order,
        line_items: [...order.line_items.slice(0, index), ...order.line_items.slice(index + 1)]
      }
      await orderService.edit(updatedOrder, order._id)
      onClose()
    } catch (error) {
      console.error('Error deleting item:', error)
    }
  }

  const formattedDate = format(new Date(order.order_date), 'yyyy/MM/dd, HH:mm:ss')

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          borderWidth: 1,
          borderColor: '#CACEE3',
          alignItems: 'center',
          padding: 10
        }}
      >
        <CustomTypography fontSize={20} fontWeight={500} color={'#000000'}>
          Order Details
        </CustomTypography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: '#000000'
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent
        sx={{
          overflowY: 'auto',
          '&::WebkitScrollbar': {
            width: '5px'
          },
          '&::WebkitScrollbar-track': {
            backgroundColor: '#D9D9D9',
            borderRadius: 14
          },
          '&::WebkitScrollbar-thumb': {
            backgroundColor: '#273894',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: '#555'
            }
          }
        }}
      >
        <div
          style={{
            borderWidth: 0.5,
            borderColor: '#979797',
            borderRadius: 8
          }}
        >
          <div
            style={{
              justifyContent: 'space-between',
              padding: 10
            }}
          >
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <CustomTypography fontSize={16} color={'#333333'} fontWeight={600}>
                Order ID : {order.order_id}
              </CustomTypography>
              {/* <div
                style={{
                  display: "flex",
                }}
              >
                <CustomTypography
                  fontSize={13}
                  variant="body1"
                  sx={{
                    cursor: "pointer",
                    marginRight: 2,
                    textDecoration: "underline",
                    color: "#2C41FF",
                  }}
                  onClick={() => handleUpdateStatus(order)}
                >
                  [ Update Status ]
                </CustomTypography>
                <CustomTypography
                  fontSize={13}
                  variant="body1"
                  sx={{
                    cursor: "pointer",
                    marginRight: 2,
                    textDecoration: "underline",
                    color: "#2C41FF",
                  }}
                  onClick={handleIssueRefund}
                >
                  [ Issue Refund ]
                </CustomTypography>
                <CustomTypography
                  fontSize={13}
                  variant="body1"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#2C41FF",
                  }}
                  onClick={() => handlePrintInvoice(order)}
                >
                  [ Print Invoice ]
                </CustomTypography>
              </div> */}
            </div>
            <div
              style={{
                backgroundColor: '#FFF3CF',
                borderWidth: 1,
                borderRadius: 6,
                borderColor: '#FF8A00B3',
                padding: 6,
                width: '75px',
                marginTop: 10,
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 10
              }}
            >
              <CustomTypography color={'#FF8A00'} fontSize={12} fontWeight={500}>
                {order.status}
              </CustomTypography>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: 10,
                gap: 15,
                marginLeft: 10,
                flexDirection: isMobile() ? 'column' : 'row'
              }}
            >
              <span
                style={{
                  backgroundColor: '#EEEEEEF0',
                  padding: 7,
                  borderRadius: 4
                }}
              >
                <CustomTypography fontSize={12} fontWeight={500} color={'#333333'}>
                  Placed on : {formattedDate}
                </CustomTypography>
              </span>
              {/* <span
                style={{
                  backgroundColor: "#0076131A",
                  padding: 7,
                  borderRadius: 4,
                }}
              >
                <CustomTypography
                  fontSize={12}
                  fontWeight={500}
                  color={"#007613"}
                >
                  Paid on : {}
                </CustomTypography>
              </span> */}
              <span
                style={{
                  backgroundColor: '#007DFF33',
                  padding: 7,
                  borderRadius: 4
                }}
              >
                <CustomTypography fontSize={12} fontWeight={500} color={'#007DFF'}>
                  Updated on : {format(new Date(order?.updatedAt), 'yyyy/MM/dd, HH:mm:ss')}
                </CustomTypography>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginTop: 10,
            gap: 10,
            flexDirection: isMobile() ? 'column' : 'row'
          }}
        >
          <div
            style={{
              width: isMobile() ? '100%' : '50%',
              borderWidth: 0.5,
              borderColor: '#979797',
              backgroundColor: '#FFFFFF',
              borderRadius: 6,
              minHeight: 400
            }}
          >
            <div style={{ margin: 20, gap: 10, padding: 10 }}>
              <CustomTypography fontSize={18} fontWeight={500} color={'#333333'}>
                Customer & Order
              </CustomTypography>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5, wordBreak: 'break-word' }}
                      >
                        {order.customer?.first_name} {order.customer?.last_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {' '}
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5, wordBreak: 'break-word' }}
                      >
                        {order?.customer?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Phone No
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.customer?.phone || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Payment Method
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.payment_method || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Total Bill
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {' '}
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.total_price || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Billing Address
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.billing_address?.address1 || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Shipping Address
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 4 }}
                      >
                        {order?.shipping_address?.name +
                          ', ' +
                          (order?.shipping_address?.address1 || '') +
                          ', ' +
                          (order?.shipping_address?.address2 || '') +
                          ', ' +
                          (order?.shipping_address?.city || '') +
                          ', ' +
                          (order?.shipping_address?.state || '') +
                          ', ' +
                          (order?.shipping_address?.zip || '') +
                          ', ' +
                          order?.shipping_address?.country}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <div></div>
            </div>
          </div>
          <div
            style={{
              width: isMobile() ? '100%' : '50%',
              borderWidth: 0.5,
              borderColor: '#979797',
              backgroundColor: '#FFFFFF',
              borderRadius: 6,
              minHeight: 400
            }}
          >
            <div style={{ margin: 20, gap: 10, padding: 10 }}>
              <CustomTypography fontSize={18} fontWeight={500} color={'#333333'}>
                Shipping information
              </CustomTypography>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Last Mile Delivery
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.lastMileDelivery || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Weight
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.shipment_weight ?? ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Dimension
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {`${order?.shipment_length ? order?.shipment_length + 'x' : ''}  ${
                          order?.shipment_width ? order?.shipment_width + 'x' : ''
                        } ${order?.shipment_height ?? ''}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Tracking Number
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          localStorage.setItem('path', `/order-tracking-history/${order._id}`)
                          navigate(`/order-tracking-history/${order._id}`)
                        }}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          color={'#333333'}
                          fontFamily={'Poppins'}
                          style={{
                            marginLeft: 5,
                            color: 'blue',
                            wordBreak: 'break-word'
                          }}
                        >
                          {new Set(order?.shipments?.map((x) => x.tracking_number))}
                        </Typography>
                      </Link>{' '}
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Expected Delivery
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Shipping Cost
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {order?.shipping_lines?.map((x) => `${x.price}`)}
                        {/* {order.shipping_lines?.map((x) => `${x.price} 
                        (${x.code})
                        `)} */}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Shipping ID
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {new Set(order?.shipments.map((x) => x.shipment))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Label
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {new Set(order?.shipments?.map((x) => x.label_file))
                        .values()
                        .toArray()
                        .map((x) => ({ link: x?.split('/')[1], key: x }))
                        .map((x) => (
                          <Link
                            target="_blank"
                            href={`${config.baseURL}/fetch-file/${x.key}`}
                            onClick={() => `${config.baseURL}/fetch-file/${x.key}`}
                            fontSize={14}
                            fontWeight={400}
                            fontFamily={'Poppins'}
                            style={{ marginLeft: 5, wordBreak: 'break-word' }}
                          >
                            {x.link}
                          </Link>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Over-Pack ID
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {new Set(order?.shipments.map((x) => x.overpack))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Over-Pack Label
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {new Set(order?.shipments?.map((x) => x.overpack_label))
                        .values()
                        .toArray()
                        .map((x) => ({ link: x?.split('/')[1], key: x }))
                        .map((x) => (
                          <Link
                            target="_blank"
                            href={`${config.baseURL}/fetch-file/${x.key}`}
                            onClick={() => `${config.baseURL}/fetch-file/${x.key}`}
                            fontSize={14}
                            fontWeight={400}
                            fontFamily={'Poppins'}
                            style={{ marginLeft: 5 }}
                          >
                            {x.link}
                          </Link>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Manifest ID
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {' '}
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {new Set(order?.shipments.map((x) => x.manifest))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Master AWB
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {' '}
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                      Bill of Lading (BOL)
                    </Typography>
                  </Grid>
                  <Grid item xs={7} container alignItems="flex-start">
                    <Grid item>
                      <Typography fontSize={14} fontWeight={400} color={'#333333'} fontFamily={'Poppins'}>
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={'#333333'}
                        fontFamily={'Poppins'}
                        style={{ marginLeft: 5 }}
                      >
                        {}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <div></div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderWidth: 0.5,
            borderRadius: 8,
            borderColor: '#979797',
            padding: 15,
            marginTop: 10
          }}
        >
          <CustomTypography fontSize={18} fontWeight={500} color={'#333333'}>
            ITEMS ORDERED
          </CustomTypography>
          <TableContainer
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
              overflowY: 'auto',
              '&::WebkitScrollbar': {
                width: '5px'
              },
              '&::WebkitScrollbar-track': {
                backgroundColor: '#D9D9D9',
                borderRadius: 14
              },
              '&::WebkitScrollbar-thumb': {
                backgroundColor: '#273894',
                borderRadius: '6px',
                '&:hover': {
                  backgroundColor: '#555'
                }
              },
              marginTop: 2
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderRight: '1px solid #9F9F9F',
                      width: '25%',
                      border: '1px solid #9F9F9F',
                      borderRadius: 0
                    }}
                  >
                    <CustomTypography fontSize={14}>Product Name</CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderRight: '1px solid #9F9F9F',
                      width: '25%',
                      border: '1px solid #9F9F9F'
                    }}
                  >
                    <CustomTypography fontSize={14}>Quantity</CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderRight: '1px solid #9F9F9F',
                      width: '25%',
                      border: '1px solid #9F9F9F'
                    }}
                  >
                    <CustomTypography fontSize={14}>Unit Price</CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderRight: '1px solid #9F9F9F',
                      width: '25%',
                      border: '1px solid #9F9F9F'
                    }}
                  >
                    <CustomTypography fontSize={14}>Price</CustomTypography>
                  </TableCell>
                  {edit && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {order.line_items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        borderRight: '1px solid #9F9F9F',
                        border: '1px solid #9F9F9F',
                        maxWidth: 350
                      }}
                    >
                      {editingItem?.index === index && editingItem?.field === 'name' ? (
                        <TextField
                          value={editedValues.name || item.name}
                          onChange={(e) => handleInputChange('name', e.target.value)}
                          onBlur={() => handleSave(index)}
                          autoFocus
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        <>
                          <CustomTypography multiline fontSize={14}>
                            {item.name}
                          </CustomTypography>
                          <CustomTypography multiline fontSize={14}>
                            {`(${item.sku})`}
                          </CustomTypography>
                          {edit && (
                            <IconButton size="small" onClick={() => handleEditClick(index, 'name')}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: '1px solid #9F9F9F',
                        border: '1px solid #9F9F9F'
                      }}
                    >
                      {editingItem?.index === index && editingItem?.field === 'quantity' ? (
                        <TextField
                          value={editedValues.quantity || item.quantity}
                          onChange={(e) => handleInputChange('quantity', e.target.value)}
                          onBlur={() => handleSave(index)}
                          autoFocus
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        <>
                          <CustomTypography fontSize={14}>{item.quantity}</CustomTypography>
                          {edit && (
                            <IconButton size="small" onClick={() => handleEditClick(index, 'quantity')}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: '1px solid #9F9F9F',
                        border: '1px solid #9F9F9F'
                      }}
                    >
                      <CustomTypography fontSize={14}>{item.price}</CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: '1px solid #9F9F9F',
                        border: '1px solid #9F9F9F'
                      }}
                    >
                      <CustomTypography fontSize={14}>{item.price}</CustomTypography>
                    </TableCell>
                    {edit && (
                      <TableCell>
                        <IconButton size="small" onClick={() => handleDelete(index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default OrderDetailsModal
