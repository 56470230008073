import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Button,
  IconButton,
  TextField,
  Menu,
  List,
  ListItem,
  CircularProgress,
  Card,
  CardContent,
  Box,
  Typography,
  Autocomplete,
  Grid,
} from "@mui/material";
import { getZipZoneKeyName } from "../../utils/getZipZoneKeyName";
import { zipZoneService } from "../../utils/services/zipZoneService";
import { zoneService } from "../../utils/services/zoneService";
import { portService } from "../../utils/services/portService";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AppContext from "../context/context";
import { clientService } from "../../utils/services/clientService";
import FilterIcon from "../../globalStyles/icons/filter-icon";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import CustomTypography from "../../globalStyles/fonts";
import AssignClient from "../client-assign";
import debounce from "lodash.debounce";
import { isMobile } from "../../globalStyles/mobile";
import PortAutocomplete from "../portAutocomplete";
import ZoneAutocomplete from "../zoneAutocomplete";
import { toast } from "react-toastify";
import CustomPagination from "../pagination";

const ZipZoneDataTable = ({
  data,
  onDeleteZipZoneData,
  onEditData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  onClickUploadStatusButton,
  onFilterZone,
  onFilterPort,
  dataCount,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [portFilter, setportFilter] = useState(null);
  const [zoneFilter, setZoneFilter] = useState(null);
  const [zones, setZones] = useState([]);
  const [port, setPort] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [clientFilter, setClientFilter] = useState(null);
  const [anchorElPort, setAnchorElPort] = useState(null);
  const [anchorElZone, setAnchorElZone] = useState(null);
  const { user } = useContext(AppContext);
  const autocompleteRefPort = useRef(null);
  const autocompleteRefZone = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [openClientAssignModal, setOpenClientAssignModal] = useState(false);
  const [selectedZipZone, setSelectedZipZone] = useState(null);
  const [zoneSearchValue, setZoneSearchValue] = useState("");
  const [portSearchValue, setPortSearchValue] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [zonePage, setZonePage] = useState(0);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPorts = [
    { name: "None" },
    ...port.filter((port) =>
      port.name?.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  ];

  useEffect(() => {
    if (anchorElPort && autocompleteRefPort.current) {
      autocompleteRefPort.current.focus();
    }
  }, [anchorElPort]);

  const handlePortFilterClick = (event) => {
    console.log("Filter icon clicked");
    setAnchorElPort(event.currentTarget);
    //setimeout 3sec
    setTimeout(() => {
      if (autocompleteRefPort.current) {
        autocompleteRefPort.current.focus();
      }
    }, 3000);
  };
  const handleZoneFilterClick = (event) => {
    console.log("Filter icon clicked");
    setAnchorElZone(event.currentTarget);
  };

  const handlePortMenuClose = () => {
    setAnchorElPort(null);
    setAnchorElZone(null);
  };

  const clientId = localStorage.getItem("client_id");

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  const fetchZoneData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await zoneService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );

        if (pageNumber === 0) {
          setZones(response.data);
        } else {
          setZones((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );
  useEffect(() => {
    fetchZoneData(zoneSearchValue, zonePage);
  }, [zoneSearchValue, zonePage, fetchZoneData]);

  const filteredZones = [
    { name: "None" },
    ...zones.filter((zone) =>
      zone.name?.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  ];
  const handleZoneFilterChange = (newValue) => {
    if (newValue) {
      setZoneFilter(newValue);
      onFilterZone && onFilterZone(newValue);
    } else {
      setZoneSearchValue("");
      fetchZoneData("", 0);
    }
  };
  const handlePortFilterChange = (newValue) => {
    if (newValue) {
      setportFilter(newValue);
      onFilterPort && onFilterPort(newValue);
    } else {
      setPortSearchValue("");
      fetchPortData("", 0);
    }
  };
  const fetchPortData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await portService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );

        if (pageNumber === 0) {
          setPort(response.data);
        } else {
          setPort((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );
  useEffect(() => {
    fetchZoneData(zoneSearchValue, zonePage);
  }, [zoneSearchValue, zonePage, fetchZoneData]);

  useEffect(() => {
    fetchPortData(portSearchValue, zonePage);
  }, [portSearchValue, zonePage, fetchPortData]);

  const deleteData = async (id) => {
    try {
      await zipZoneService.delete(id);
      setOpenConfirmation(false);
      onDeleteZipZoneData && onDeleteZipZoneData();
      toast.success("ZipZone deleted successfully!")
    } catch (error) {
      console.error("Delete failed:", error);
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpenConfirmation = (id) => {
    setDeletingItemId(id);
    setOpenConfirmation(true);
  };
  const handleClientAssignClick = () => {
    // setSelectedClient(client);
    setOpenClientAssignModal(true);
  };
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        //  height:"70%",
      }}
    >
      <img
        src="no_data_zipZone.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );
  const handleScroll = (event, type) => {
    if (event?.target) {
      const bottom =
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
      if (bottom && (type === 'port' ? port.length < total : zones.length < total) && !loading) {
        setZonePage((prevPage) => prevPage + 1)
      }
    }
  };

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((row, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Destination
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.destination}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Service Day
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.service_day}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Port
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.port?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Zone
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.zone?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          State
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.state}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {user.role == "admin" && (
                    <Box
                      mt={2}
                      justifyContent={"flex-end"}
                      display="flex"
                      gap={2}
                    >
                      <IconButton
                        style={{
                          color: "#007613",
                          padding: 0,
                          background: "#00761333",
                          borderRadius: 5,
                          height: 30,
                          width: 30,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleClientAssignClick();
                          setSelectedZipZone(row);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="client"
                          src="/client-assign.png"
                        ></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#FEC50F",
                          padding: 0,
                          background: "#FEC50F33",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="edit"
                        onClick={() => onEditData && onEditData(row)}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#D13438",
                          padding: 0,
                          background: "#D1343833",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="delete"
                        onClick={() => handleOpenConfirmation(row._id)}
                      >
                        <img
                          style={{ height: 15, width: 15 }}
                          alt="delete"
                          src="/delete.png"
                        ></img>
                      </IconButton>
                    </Box>
                  )}
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };




  return (
    <div
      style={{ height: "100%", paddingTop: 0, width: "100%" }}
      className="table-container"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: isMobile() ? 8 : 0,
        }}
      >
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            alignItems: "center",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {isMobile() ? (
            <div style={{ display: "flex", gap: 5 }}>
              <PortAutocomplete
                onPortChange={(port) => {
                  setportFilter(port);
                  onFilterPort && onFilterPort(port);
                }}
                selectedPort={portFilter}
                label={"Filter by Port"}
              />

              {/* <Autocomplete
                fullWidth
                size="small"
                renderOption={(props, option) => (
                  <div {...props}>{option.name}</div>
                )}
                options={zones}
                value={zoneFilter?.name ? zoneFilter : null}
                getOptionLabel={(option) => option.name || ""}
                onChange={handleZoneFilterValue}
                ListboxProps={{
                  onScroll: (event) => handleScroll(event, "zone"),
                }}
                onInputChange={handleZoneSearchChange}
                onOpen={() => {
                  fetchClientData("", 0);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Zone"
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    style={{ width: 150 }}
                  />
                )}
                style={{ maxWidth: 200 }}
              /> */}
              <ZoneAutocomplete
                onZoneChange={(zone) => {
                  setZoneFilter(zone);
                  onFilterZone && onFilterZone(zone);
                }}
                selectedZone={zoneFilter}
                label={"Filter by Zone"}
              />
            </div>
          ) : null}

          <Button
            size="small"
            style={{
              backgroundColor: "#007DFF",
              marginLeft: isMobile() ? 8 : 3,
              minWidth: 200,
              maxHeight: 40,
              marginTop: isMobile() ? 5 : 0,
              minHeight: 40,
            }}
            variant="contained"
            onClick={onClickUploadStatusButton}
          >
            Show Upload Status
          </Button>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          overflow: isMobile() ? "auto" : "hidden",
          paddingBottom: 100,
          maxHeight: "65vh",
        }}
      >
        {isMobile() ? (
          <MobileCardView data={data} />
        ) : (
          <TableContainer
            sx={{
              maxHeight: "60vh",
              overflowY: "auto",
              paddingInline: 0.4,
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0 5px",
                paddingBottom: 7,
              }}
            >
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#EEEEEE",
                    boxShadow: "0px 0px 4px 0px #00000040",
                    borderWidth: 1,
                    borderRadius: 8,
                  }}
                >
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEEEE",
                      textAlign: "center",
                      borderBottom: "none",
                      padding: 5,
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Destination
                    </CustomTypography>
                  </TableCell>

                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEEEE",
                      padding: 5,
                      textAlign: "center",

                      borderBottom: "none",
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Service Day
                    </CustomTypography>
                  </TableCell>

                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEEEE",
                      borderBottom: "1px solid #EEEEEE",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      {getZipZoneKeyName("port_name")}
                      <IconButton
                        size="small"
                        aria-label="filter list"
                        onClick={handlePortFilterClick}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorElPort}
                        open={Boolean(anchorElPort)}
                        onClose={handlePortMenuClose}
                        PaperProps={{
                          style: {
                            maxHeight: "300px",
                            overflow: "auto",
                          },
                          onScroll: (event) => handleScroll(event, "port"),
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                          }}
                        >
                          <TextField
                            label="Search Port"
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                          />
                          {loading ? (
                            <div
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <List>
                              {filteredPorts.length === 0 ? (
                                <ListItem>No ports available</ListItem>
                              ) : (
                                filteredPorts.map((port, index) => (
                                  <ListItem
                                    onClick={() => handlePortFilterChange(port)}
                                    sx={{
                                      cursor: "pointer",
                                      borderWidth: 0.5,
                                      margin: 0.5,
                                      backgroundColor:
                                        port?.name === portFilter?.name &&
                                        port?.name !== "None"
                                          ? "#C3E1FF"
                                          : "#FFF",
                                    }}
                                    key={port._id}
                                  >
                                    {port?.name}
                                  </ListItem>
                                ))
                              )}
                            </List>
                          )}
                        </div>
                      </Menu>
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEEEE",
                      borderBottom: "1px solid #EEEEEE",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      {getZipZoneKeyName("zone_name")}
                      <IconButton
                        size="small"
                        aria-label="filter list"
                        onClick={handleZoneFilterClick}
                      >
                        <FilterIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorElZone}
                        open={Boolean(anchorElZone)}
                        onClose={handlePortMenuClose}
                        PaperProps={{
                          style: {
                            maxHeight: "300px",
                            overflow: "auto",
                          },
                          onScroll: (event) => handleScroll(event, "zone"),
                        }}
                      >
                        <div style={{ padding: "10px" }}>
                          <TextField
                            label="Search Zone"
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                          />
                          <List>
                            {filteredZones.map((zone, index) => (
                              <ListItem
                                onClick={() => handleZoneFilterChange(zone)}
                                sx={{
                                  cursor: "pointer",
                                  borderWidth: 0.5,
                                  margin: 0.5,
                                  backgroundColor:
                                    zone?.name === zoneFilter?.name &&
                                    zone?.name !== "None"
                                      ? "#C3E1FF"
                                      : "#FFF",
                                }}
                                key={zone._id}
                              >
                                {zone?.name}
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </Menu>
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEEEE",
                      textAlign: "center",
                      borderBottom: "none",
                      padding: 5,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      State
                    </CustomTypography>
                  </TableCell>
                  {user.role == "admin" && (
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEEEE",
                        padding: "5px",
                        textAlign: "center",
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Action
                      </CustomTypography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} style={{ borderBottom: "none" }}>
                      <NoDataImage />
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{
                        marginBottom: 10,
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      key={index}
                    >
                      <TableCell
                        align="center"
                        style={{
                          borderBottom: "none",
                          borderRadius: "8px 0 0 8px",
                          cursor: "pointer",
                          textAlign: "center",
                          padding: user.role == "admin" ? 6 : 4,
                        }}
                      >
                        <CustomTypography
                          fontSize="13px"
                          color="#333333"
                          fontWeight={400}
                        >
                          {row.destination}
                        </CustomTypography>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          borderBottom: "none",
                          padding: user.role == "admin" ? 4 : 10,
                        }}
                      >
                        <CustomTypography
                          fontSize="13px"
                          color="#333333"
                          fontWeight={400}
                        >
                          {row?.service_day}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: user.role == "admin" ? 4 : 10,
                          textAlign: "center",
                          borderBottom: "none",
                        }}
                      >
                        <CustomTypography
                          fontSize="13px"
                          color="#333333"
                          fontWeight={400}
                        >
                          {row.port?.name || ""}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: user.role == "admin" ? 4 : 10,
                          textAlign: "center",
                          borderBottom: "none",
                          padding: 4,
                        }}
                      >
                        <CustomTypography
                          fontSize="13px"
                          color="#333333"
                          fontWeight={400}
                        >
                          {row.zone?.name || ""}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderBottom: "none",
                          padding: user.role === "admin" ? 4 : 10,
                          ...(user.role !== "admin" && {
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                          }),
                        }}
                      >
                        <CustomTypography
                          fontSize="13px"
                          color="#333333"
                          fontWeight={400}
                        >
                          {row?.state}
                        </CustomTypography>
                      </TableCell>
                      {user.role == "admin" && (
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            borderBottom: "none",
                            padding: 7,
                          }}
                        >
                          <div>
                            <IconButton
                              style={{
                                color: "#007613",
                                padding: 0,
                                background: "#00761333",
                                borderRadius: 5,
                                marginLeft: 10,
                                height: 30,
                                width: 30,
                                padding: 0,
                                boxShadow: "0px 0px 4px 0px #00000040",
                              }}
                              aria-label="view"
                              onClick={() => {
                                handleClientAssignClick();
                                setSelectedZipZone(row);
                              }}
                            >
                              <img
                                style={{ height: 14, width: 20 }}
                                alt="client"
                                src="/client-assign.png"
                              ></img>
                            </IconButton>
                            <IconButton
                              style={{
                                color: "#FEC50F",
                                padding: 0,
                                background: "#FEC50F33",
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 15,
                                boxShadow: "0px 0px 4px 0px #00000040",
                                marginTop: 5,
                              }}
                              aria-label="edit"
                              onClick={() => onEditData && onEditData(row)}
                            >
                              <BorderColorIcon sx={{ height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton
                              style={{
                                color: "#D13438",
                                padding: 0,
                                background: "#D1343833",
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 10,
                                boxShadow: "0px 0px 4px 0px #00000040",
                                marginTop: 5,
                              }}
                              aria-label="delete"
                              onClick={() => handleOpenConfirmation(row._id)}
                            >
                              <img
                                style={{ height: 15, width: 15 }}
                                alt="delete"
                                src="/delete.png"
                              ></img>
                            </IconButton>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {/* <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false,
            },
          },
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete this ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          deleteData(deletingItemId);
        }}
      />
      <AssignClient
        selectedData={selectedZipZone}
        mapKey="zip_zone"
        open={openClientAssignModal}
        onClose={() => setOpenClientAssignModal(false)}
      />
    </div>
  );
};

export default ZipZoneDataTable;
