import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { Box, CircularProgress } from "@mui/material";
import { providerService } from "../../utils/services/providerService";
import ProviderModal from "../../components/providerModal";
import ProviderList from "../../components/providerList";

const Provider = () => {
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);

  const fetchProvider = async () => {
    setLoading(true);
    try {
      const response = await providerService.fetch(
        100,
        0,
        clientFilter?._id,
        searchValue
      );
      setProviderList(response?.data);
      setLoading(false);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProvider();
  }, [page, rowsPerPage, clientFilter, searchValue]);
  return (
    <>
      <DashboardContainer
        header={"Provider List"}
        hideUploadButton
        title={"+ Add Provider"}
        onClickAddButton={() => setOpenModal(true)}
        setViewMode={setViewMode}
        onSearchData={(x) => {
          if (x.trim()) {
            setSearchValue(x);
            setPage(0);
          } else {
            setSearchValue("");
          }
        }}
        hideFilterClient
      >
        <ProviderModal
          selectedProvider={selectedProvider}
          handleClose={() => {
            setOpenModal(false);
            setSelectedProvider(null);
          }}
          open={openModal}
          onAddData={fetchProvider}
        ></ProviderModal>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <ProviderList
            dataCount={dataCount}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            onDeleteData={fetchProvider}
            onEditData={(e) => {
              setSelectedProvider(e);
              setOpenModal(true);
            }}
            data={providerList}
            viewMode={viewMode}
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default Provider;
