import React, { useEffect, useState } from "react";
import { providerService } from "../../utils/services/providerService";
import TMSProviderModal from "./tms-provider";
import WMSProviderModal from "./wms-provider";
import OMSProviderModal from "./oms-provider";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";

const CustomTextField = ({
  label,
  value,
  onChange,
  readOnly = false,
  placeholder,
}) => (
  <FormControl fullWidth variant="outlined">
    <CustomTypography fontSize={12}>{label}</CustomTypography>
    <OutlinedInput
      size="small"
      sx={{
        marginTop: 1,
        backgroundColor: "#EEEEEEEE",
        fontSize: 12,
        color: "#7C7C7C",
      }}
      placeholder={placeholder || label}
      id={label}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      notched
    />
  </FormControl>
);

const ClientProviderModal = ({
  open,
  setOpen,
  onClose,
  oms,
  wms,
  tms,
  selectedClient,
  selectedData,
  onAddProviderData,
  setLoading,
  loading,
  onDeleteSuccess,
}) => {
  const [providerList, setProviderList] = useState([]);
  const [contactCode, contactNumber] = (() => {
    const parts = (selectedClient?.contact || "").split("-");
    return parts.length > 1 ? [parts[0], parts[1]] : ["", parts[0]];
  })();

  const fetchProviders = async () => {
    try {
      const response = await providerService.fetch(100, 0);
      setProviderList(response?.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <CustomTypography
          fontSize={18}
          style={{ paddingInline: 3, paddingTop: 2, fontWeight: "500" }}
        >
          {`Manage ${tms ? "TMS" : wms ? "WMS" : "OMS"} Provider`}
        </CustomTypography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid style={{ padding: 10 }} container spacing={2}>
        <Grid style={{ padding: 10, paddingLeft: 20 }} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              readOnly
              value={selectedClient?.name || ""}
              label="Client Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              value={selectedClient?.email || ""}
              readOnly
              label="Email"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CustomTextField
              value={contactCode || ""}
              readOnly
              label="Country Code"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomTextField
              value={contactNumber || ""}
              readOnly
              label="Mobile Number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              value={selectedClient?.business_name || ""}
              readOnly
              label="Business Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              value={selectedClient?.country || ""}
              readOnly
              label="Country"
            />
          </Grid>
        </Grid>

        <DialogContent>
          <Divider />
          {tms ? (
            <TMSProviderModal
              setLoading={setLoading}
              loading={loading}
              onAddProviderData={onAddProviderData}
              onClose={handleClose}
              providerList={providerList.filter((x) => x.type === "TMS")}
              onDeleteSuccess={onDeleteSuccess}
              selectedClient={selectedClient}
              selectedData={selectedData}
              setOpen={setOpen}
            />
          ) : wms ? (
            <WMSProviderModal
              setLoading={setLoading}
              loading={loading}
              onAddProviderData={onAddProviderData}
              onClose={handleClose}
              providerList={providerList.filter((x) => x.type === "WMS")}
              onDeleteSuccess={onDeleteSuccess}
              selectedClient={selectedClient}
              selectedData={selectedData}
              setOpen={setOpen}
            />
          ) : (
            <OMSProviderModal
              setLoading={setLoading}
              loading={loading}
              onAddProviderData={onAddProviderData}
              onClose={handleClose}
              providerList={providerList.filter((x) => x.type === "OMS")}
              onDeleteSuccess={onDeleteSuccess}
              selectedClient={selectedClient}
              selectedData={selectedData}
              setOpen={setOpen}
            />
          )}
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default ClientProviderModal;
