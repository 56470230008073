import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import IconButton from "@mui/material/IconButton/IconButton";
import Button from "@mui/material/Button/Button";
import { supplierService } from "../../utils/services/supplierService";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import UpIcon from "@mui/icons-material/ArrowUpwardOutlined";
import DownIcon from "@mui/icons-material/ArrowDownwardOutlined";
import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Icon,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import { isMobile } from "../../globalStyles/mobile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import FilterModal from "../filterModal";
import { portService } from "../../utils/services/portService";
import AssignClient from "../client-assign";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import PortDetailsModal from "../portDetailsViewModal";
import ClientAssignIcon from "../../globalStyles/icons/client-assign-icon";
import { useTheme } from "@mui/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    // color: theme.palette.common.black,
    padding: "4px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "4px",
    textAlign: "start",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const PortTableCell = ({
  port,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
  handleClientAssignClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(port);
        break;
      case "edit":
        handleEditClick(port);
        break;
      case "delete":
        handleDeleteClick(port);
        break;
      case "assign":
        handleClientAssignClick(port);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <Button
            onClick={() => handleActionClick("edit")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <EditIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("view")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick("delete")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <DeleteIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Delete
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("assign")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ClientAssignIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Client Assign
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
const PortList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  viewMode,
  selectedPort,
  setSelectedPort,
  currentPage,
  itemsPerPage,
}) => {
  const [selectedClientData, setSelectedClient] = useState({ name: "" });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedPortData, setSelectedPortData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const startIndex = currentPage * itemsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  const handleDeleteClick = (port) => {
    setSelectedPort(port);
    setOpenConfirmation(true);
  };

  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await portService.delete(selectedPort._id);
      onDeleteData && onDeleteData();
      setOpenConfirmation(false);
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (port) => {
    setSelectedPort(port);
    setOpenViewModal(true);
  };

  const handleClientAssignClick = (client) => {
    setSelectedClient(client);
    setModalOpen(true);
  };
  useEffect(() => {
    setSelectedPortData(
      data?.map((port) => ({
        ...port,
        action: (
          <PortTableCell
            port={port}
            handleViewClick={handleViewClick}
            handleEditClick={onEditData}
            handleDeleteClick={handleDeleteClick}
            handleClientAssignClick={handleClientAssignClick}
          />
        ),
      }))
    );
  }, [data]);
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {selectedPortData?.map((port) => (
        <Grid item xs={12} sm={6} md={3} key={port.id}>
          <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 15,
                  }}
                >
                  <Tooltip title={port?.name} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "70%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {port?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                {port?.action}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data?.map((port, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffffff",
              borderRadius: 4,
              position: "relative",
            }}
          >
            <CardContent>
              <Box position="absolute" top={2} right={5}>
                {port?.action}
              </Box>
              <Box display="flex" flexDirection="column" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Supplier Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {port?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Client
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {port?.client_id?.business_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {port?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {port?.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        // paddingBottom: 100,
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={selectedPortData} />
      ) : (
        <TableContainer
          //  className="custom-container"
          sx={{
            // maxHeight: "70vh",
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "name")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      PORT NAME
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "email")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    >
                      COUNTRY NAME
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //   onClick={(event) => handleOpenFilter(event, "email")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                    textAlign={"start"}
                  >
                    LOCATION
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "address.city")
                      ?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "address.city")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    >
                      POSTAL CODE
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //   onClick={(event) =>
                      //     handleOpenFilter(event, "address.city")
                      //   }
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "province")
                      ?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "province")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    >
                      PORT TYPE
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //   onClick={(event) =>
                      //     handleOpenFilter(event, "address.province")
                      //   }
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedPortData?.map((port, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                      style={{ marginLeft: 30 }}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <Tooltip title={port?.name}>
                    <StyledTableCell>
                      <Typography
                        style={{
                          // marginLeft: 22,
                          maxWidth: 100,
                          whiteSpace: "nowrap", // Ensure single line
                          overflow: "hidden", // Prevent text overflow
                          textOverflow: "ellipsis", // Show ellipsis for overflow text
                        }}
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        title={port?.name} // Add a title attribute to show full text on hover
                      >
                        {port?.name}
                      </Typography>
                    </StyledTableCell>
                  </Tooltip>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {port?.country}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "250px",
                      }}
                    >
                      {port?.location}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                    >
                      {port?.postal_code}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                    >
                      {port?.type}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                    {port?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
      )}

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedPort?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          confirmDelete(e);
        }}
      />
      <AssignClient
        selectedData={selectedPort}
        mapKey="port"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <PortDetailsModal
        port={selectedPort}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  );
};

export default PortList;
