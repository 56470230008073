import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  TextField,
  Typography,
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const FilterComponent = ({
  open,
  onClose,
  fields = [],
  fetchApi,
  searchKey,
  filterKey,
  displayKey,
  id,
  onSave,
  options = [],
  selectedValues = [],
  currentSortOrder,
  hasMore,
  onFieldSelection,
}) => {
  const [localSelection, setLocalSelection] = useState(selectedValues || []);
  const [sortOrder, setSortOrder] = useState(currentSortOrder);
  const [searchValue, setSearchValue] = useState(searchKey || "");
  const [optionList, setOptionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const clientId = localStorage.getItem("client_id");
  const [page, setPage] = useState(1);
  const [selectedField, setSelectedField] = useState(null);
  const limit = 15;

  useEffect(() => {
    if (open) {
      setLocalSelection([...selectedValues]);
      setSortOrder(currentSortOrder);
      setSearchValue(searchKey || "");
      const restoredField = fields.find((field) => field.key === filterKey);
      setSelectedField(restoredField);
      setOptionList((prevList) => {
        const uniqueOptions = new Map();
        selectedValues.forEach((value) => {
          uniqueOptions.set(value, {
            [id]: value,
            [displayKey]: value,
          });
        });
        prevList.forEach((option) => {
          uniqueOptions.set(option[id], option);
        });

        return [...uniqueOptions.values()];
      });
    }
  }, [
    open,
    selectedValues,
    currentSortOrder,
    searchKey,
    filterKey,
    fields,
    id,
    displayKey,
  ]);

  useEffect(() => {
    setSearchValue(searchKey);
  }, [searchKey]);

  const handleFieldSelection = (field) => {
    if (onFieldSelection) {
      setSelectedField(field);
      onFieldSelection(field);
    }
    setSearchValue("");
    setPage(1);

    if (field.fetchApi) {
      setLoading(true);
      field
        .fetchApi(searchValue, clientId, limit, (page - 1) * limit)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            if (page == 1) {
              setOptionList(res.data);
            } else {
              setOptionList([...optionList, ...res.data]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching API data:", error);
          setLoading(false);
        });
    } else {
      console.warn("fetchApi is undefined for the selected field:", field);
    }
  };
  useEffect(() => {
    if (selectedField?.fetchApi) {
      setLoading(true);
      selectedField
        .fetchApi(searchValue, clientId, limit, (page - 1) * limit)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setOptionList((prevList) => [...prevList, ...res.data]);
          }
        })
        .catch((error) => {
          console.error("Error fetching API data:", error);
          setLoading(false);
        });
    }
  }, [page]);

  const handleSortChange = (order) => {
    if (sortOrder === order) setSortOrder(0);
    else setSortOrder(order);
  };

  const handleCheckboxChange = (optionId) => {
    if (["All", "all"].includes(optionId)) {
      if (localSelection.includes(optionId)) {
        setLocalSelection([]);
      } else {
        setLocalSelection(optionList.map((x) => x[id]));
      }
    } else {
      setLocalSelection((prevSelection) =>
        prevSelection.includes(optionId)
          ? prevSelection.filter((id) => id !== optionId)
          : [...prevSelection, optionId]
      );
    }

    if (localSelection.find((x) => ["all", "All"].includes(x))) {
      setLocalSelection((prev) =>
        prev.filter((x) => !["all", "All"].includes(x))
      );
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setPage(1);
  };

  const handleSave = () => {
    onSave(localSelection, filterKey, sortOrder, searchValue);
    onClose();
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (options && options.length) {
      setOptionList([
        { [id]: "All", [displayKey]: "All" },
        ...options.filter((x) =>
          x[displayKey]
            .toLowerCase()
            .includes((searchValue || "").toLowerCase())
        ),
      ]);
    }
  }, [options, searchValue, id, displayKey]);

  return (
    open && (
      <Box sx={{ width: 600, bgcolor: "background.paper", boxShadow: 24 }}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box display={"flex"}>
          <Box
            sx={{
              width: "40%",
              borderRight: "1px solid #ccc",
              overflowY: "auto",
            }}
          >
            <List>
              {fields.map((field, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleFieldSelection(field)}
                  sx={{
                    backgroundColor:
                      selectedField?.key === field?.key
                        ? "#F6F6F6"
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        selectedField?.key === field?.key
                          ? "#e0e0e0"
                          : "#f5f5f5",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          fontFamily: "poppins",
                          color:
                            selectedField?.key === field?.key
                              ? "#DC585C"
                              : "#757575",
                        }}
                      >
                        {field.name}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box sx={{ flex: 1, pl: 2, p: 2 }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems={"start"}
              mb={2}
              gap={2}
            >
              <Button
                onClick={() => handleSortChange(1)}
                size="small"
                style={{ width: 150 }}
                variant={sortOrder === 1 ? "contained" : "outlined"}
              >
                Sort by A to Z
              </Button>
              <Button
                onClick={() => handleSortChange(-1)}
                size="small"
                style={{ width: 150 }}
                variant={sortOrder === -1 ? "contained" : "outlined"}
              >
                Sort by Z to A
              </Button>
            </Box>
            <TextField
              fullWidth
              placeholder={`Search here...`}
              value={searchValue}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ maxHeight: 200, overflowY: "auto", mt: 2 }}>
              {optionList.map((option, index) => (
                <Box
                  key={"option - " + option[id] + " - " + index}
                  display="flex"
                  alignItems="center"
                  mb={1}
                >
                  <Checkbox
                    checked={localSelection.includes(option[id])}
                    onChange={() => handleCheckboxChange(option[id])}
                  />
                  <Typography>{option[displayKey]}</Typography>
                </Box>
              ))}
              {hasMore &&
              optionList.length % limit === 0 &&
              optionList.length === limit * page ? (
                <Box
                  key={`load-more`}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={1}
                >
                  <Button onClick={handleLoadMore} variant="outlined">
                    Load More
                  </Button>
                </Box>
              ) : null}
            </Box>

            <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSave} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default FilterComponent;
