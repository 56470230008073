import React, { useEffect, useReducer, useState } from 'react'
import DashboardContainer from '../../components/dashboard-container'
import OrderList from '../../components/order-management-list'
import { orderService } from '../../utils/services/orderService'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import CustomPagination from '../../components/pagination'
import FileSaver from 'file-saver'
import { Button, IconButton, Typography } from '@mui/material'
import DownloadIcon from '../../globalStyles/icons/download-icon'
import buildQuery from '../../utils/query-builder'
import { isMobile } from '../../globalStyles/mobile'
import { changeDateFormat } from '../../utils/utility'
import { useTheme } from '@mui/styles'

const initialState = {
  columns: [
    {
      key: 'order_id',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        orderService.fetchOrderId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'shipping_address.name',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        orderService.fetchCustomerName(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'shipping_address.province',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        orderService.fetchCustomerState(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: '',
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'order_date',
      sort: 0,
      id: '_id',
      filter: {
        startDate: changeDateFormat(new Date(new Date().setMonth(new Date().getMonth() - 2))),
        endDate: changeDateFormat(new Date(new Date().setDate(new Date().getDate() + 2))),
        key: 'selection'
      },
      loadMoreButton: false,
      type: 'date-range'
    },
    {
      key: 'shipments.shipment',
      filter: [],
      sort: 0,
      id: '_id',
      displayKey: '_id',
      fetchApi: (searchKey, client_id, limit = 15, skip = 0) =>
        orderService.fetchShipmentId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'status',
      filter: [],
      options: [
        { name: 'Ordered' },
        { name: 'Accepted' },
        { name: 'Fulfilled' },
        { name: 'Shipped' },
        { name: 'In Transit' },
        { name: 'Out for Delivery' },
        { name: 'On Hold' },
        { name: 'Delivered' },
        { name: 'Cancelled' }
      ],
      sort: 0,
      id: 'name',
      displayKey: 'name',
      excludeValues: ['all'],
      loadMoreButton: false,
      searchKey: ''
    }
  ],
  limit: 15,
  populate: ['client_id']
}
function filterReducer(state, action) {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        columns: state.columns.map((x) => (x.key === action.payload.key ? { ...x, ...action.payload } : x))
      }
    case 'UPDATE_LIMIT':
      return { ...state, limit: action.payload }

    default:
      return state
  }
}
const OrderManagement = () => {
  const [filterData, dispatch] = useReducer(filterReducer, initialState)
  const [clientFilter, setClientFilter] = useState(null)

  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const clientId = localStorage.getItem('client_id')
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const fetchOrderData = async () => {
    setLoading(true)
    try {
      const response = await orderService.fetch(
        clientFilter?._id,
        searchValue,
        buildQuery(filterData, page),
        clientId
        // startDate,
        // endDate
        // { signal } // Pass the signal to the API call
      )
      setDataCount(response.total)
      setOrderData(response.data)
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Fetch error:', error)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    fetchOrderData()
    return () => {
      controller.abort()
    }
  }, [filterData, searchValue, page, clientFilter])

  const handleExport = async () => {
    setLoading(true)
    try {
      const response = await orderService.exportOrders(clientId || '')
      let sliceSize = 1024
      let byteCharacters = atob(response.file)
      let bytesLength = byteCharacters.length
      let slicesCount = Math.ceil(bytesLength / sliceSize)
      let byteArrays = new Array(slicesCount)
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize
        let end = Math.min(begin + sliceSize, bytesLength)
        let bytes = new Array(end - begin)
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0)
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes)
      }
      let blob = new Blob(byteArrays, { type: response.contentType })
      FileSaver.saveAs(new Blob([blob], {}), response.filename)
      // handleDownload(response)

      // Assuming 'response' contains an array of objects
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Fetch error:', error)
      }
    } finally {
      setLoading(false)
    }
  }

  // Convert data to CSV format
  const handleFilterChange = (newFilter) => {
    dispatch({ type: 'SET_FILTER', payload: newFilter })
    setPage(0)
  }
  return (
    <DashboardContainer
      headerButtons={
        isMobile() ? (
          <IconButton onClick={handleExport}>
            <DownloadIcon fill={primary_color} />
          </IconButton>
        ) : (
          <Button
            size="small"
            style={{
              textTransform: 'none'
            }}
            variant="contained"
            color="primary"
            onClick={handleExport}
          >
            <DownloadIcon fill={'#ffff'} />
            <Typography
              fontSize="14px"
              fontWeight={500}
              sx={{
                color: (theme) => theme.palette.text.main,
                marginLeft: 1
              }}
              fontFamily={'poppins'}
            >
              Download
            </Typography>
          </Button>
        )
      }
      hideUploadButton={true}
      // hideFilterClient={true}
      hideAddButton={true}
      order
      header={'Order Management System'}
      onSearchData={setSearchValue}
      onClickExport={handleExport}
      onClickRefresh={fetchOrderData}
      onSelectClient={(e) => {
        setClientFilter(e)
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <OrderList
          data={orderData}
          onStatusFilterChange={setStatusFilter}
          fetchOrderData={fetchOrderData}
          page={page}
          rowsPerPage={filterData.limit}
          setRowsPerPage={(e) =>
            dispatch({
              type: 'UPDATE_LIMIT',
              payload: e
            })
          }
          setPage={setPage}
          onChangeFilter={handleFilterChange}
          filterData={filterData}
        />
      )}

      {/* <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  )
}

export default OrderManagement
