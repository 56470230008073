import React, { useEffect, useReducer, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import EventModal from "../../components/eventDetailsModal";
import { eventService } from "../../utils/services/eventService";
import { Box, CircularProgress } from "@mui/material";
import EventList from "../../components/eventList";
import TrackingEventModal from "../../components/tracking-event-modal";
import buildQuery from "../../utils/query-builder";

const initialState = {
  columns: [
    {
      key: "leg",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        eventService.fetchLeg(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "code",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        eventService.fetchCode(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
  ],
  limit: 15,
  //  populate: ["client_id"],
};
function filterReducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state.columns.map((x) =>
          x.key === action.payload.key ? { ...x, ...action.payload } : x
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };

    default:
      return state;
  }
}

const TrackingEvents = () => {
  const [filterData, dispatch] = useReducer(filterReducer, initialState);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const client_id = localStorage.getItem("client_id");

  console.log(selectedEvent);

  const fetchEvent = async () => {
    setLoading(true);
    try {
      const response = await eventService.fetch(
        searchValue,
        buildQuery(filterData, page)
      );
      if (response.total) {
        fetchClientEvent(response.data);
      }
      setDataCount(response?.total);
      setLoading(false);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };
  const fetchClientEvent = async (events) => {
    setLoading(true);
    try {
      let query = [];
      events
        .map((x) => x.code)
        .forEach((x) => {
          query.push(`code[$in][]=${x}`);
        });
      const response = await eventService.clientFetch(query);
      setEventList(
        events.map((x) => {
          let ce = response.data.find((xx) => xx.code == x.code);
          return {
            ...x,
            client_event_id: ce?._id,
            client_description: ce?.client_description,
          };
        })
      );

      setLoading(false);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, [page, filterData, searchValue]);

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };

  return (
    <>
      <DashboardContainer
        header={"Tracking Event List"}
        hideUploadButton
        title={"+ Add Tracking Event"}
        onClickAddButton={() => setOpenModal(true)}
        setViewMode={setViewMode}
        onSearchData={(x) => {
          if (x.trim()) {
            setSearchValue(x);
            setPage(0);
          } else {
            setSearchValue("");
          }
        }}
        hideFilterClient
        hideAddButton={client_id}
      >
        {/* <EventModal
          selectedEvent={selectedEvent}
          onClose={() => {
            setOpenModal(false);
            setSelectedEvent(null);
            console.log("Asasas");
          }}
          open={openModal}
          onAddData={fetchEvent}
        ></EventModal> */}
        <TrackingEventModal
          selectedTrackingEvent={selectedEvent}
          handleClose={() => {
            setOpenModal(false);
            setSelectedEvent(null);
          }}
          open={openModal}
          onAddData={fetchEvent}
        />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <EventList
            dataCount={dataCount}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            onDeleteData={fetchEvent}
            onEditData={(e) => {
              setSelectedEvent(e);
              setOpenModal(true);
            }}
            data={eventList}
            viewMode={viewMode}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            onChangeFilter={handleFilterChange}
            filterData={filterData}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default TrackingEvents;
