import * as React from "react";
import { useSpring, animated } from "react-spring";
import CustomTypography from "../../globalStyles/fonts";
import AppContext from "../../components/context/context";
import { useNavigate, useParams } from "react-router-dom";
import { orderService } from "../../utils/services/orderService";
import { isMobile } from "../../globalStyles/mobile";

export default function CustomizedTimeline() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dummyTrackingDetails = [
    { name: "Ordered", sl: 1, reached: false },
    { name: "Accepted", sl: 2, reached: false },
    { name: "Confirmed", sl: 3, reached: false },
    { name: "Fulfilled", sl: 4, reached: false },
    { name: "Shipped", sl: 5, reached: false },
    { name: "In Transit", sl: 6, reached: false },
    { name: "Out for Delivery", sl: 7, reached: false },
    { name: "Delivered", sl: 8, reached: false },
    { name: "Cancelled", sl: 9, reached: false },
  ];
  const [selectedOrderData, setSelectedOrderData] = React.useState(null);
  const [trackData, setTrackData] = React.useState(dummyTrackingDetails);

  const setTrackingdata = (order) => {
    const isCancelled = trackData.find((x) => x.name === "Cancelled")?.reached;
    const isConfirmed = trackData.find((x) => x.name === "Confirmed")?.reached;
    const orderTrack = isCancelled
      ? dummyTrackingDetails
      : isConfirmed
      ? dummyTrackingDetails.slice(0, -1)
      : dummyTrackingDetails;
    let progress = orderTrack.find((x) => x.name === selectedOrderData.status);
    setTrackData(
      orderTrack.map((item) => ({
        ...item,
        reached: item.sl <= progress?.sl ? true : false,
      }))
    );
  };
  const fetchOrder = async () => {
    try {
      const order = await orderService.get(id);
      setSelectedOrderData(order);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  React.useEffect(() => {
    if (id) {
      fetchOrder();
    }
  }, [id]);
  React.useEffect(() => {
    if (selectedOrderData) {
      setTrackingdata();
    }
  }, [selectedOrderData]);

  const firstUnreachedIndex = trackData.findIndex((step) => !step.reached);
  // Calculate the position for the animated element
  const targetPosition =
    firstUnreachedIndex === 0
      ? 0
      : ((firstUnreachedIndex - 1) / trackData.length < 0
          ? 0.85
          : (firstUnreachedIndex - 1) / trackData.length) * 75; // Assuming 70vh is the height of the container
  // Create a spring animation
  const props = useSpring({
    top: `${targetPosition}vh`,
    from: { top: "0vh" },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        width: "100vw",
        height: "100%",
        paddingBottom: 10,
      }}
    >
      <CustomTypography
        style={{ textAlign: "start", marginTop: 1, minHeight: 40 }}
        fontWeight={"500"}
        fontSize={22}
      >
        Order Tracking Details
      </CustomTypography>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile() ? "column" : "row",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            height: 300,
            width: 300,
            backgroundColor: "#FFF",
            borderRadius: 10,
            boxShadow: "0px 0px 4px 0px #00000040",
            padding: isMobile() ? 10 : 0,
          }}
        >
          <CustomTypography
            fontWeight={"600"}
            fontSize={20}
            style={{ marginTop: 3 }}
          >
            Tracking Number
          </CustomTypography>
          <CustomTypography
            fontWeight={"500"}
            fontSize={15}
            style={{ marginTop: 3 }}
          >
            {
              new Set(
                selectedOrderData?.shipments?.map((x) => x.tracking_number)
              )
            }
          </CustomTypography>
        </div>
        <div
          style={{
            display: "flex",
            width: "40%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "start",
            marginTop: isMobile() ? 10 : 0,
          }}
        >
          <div
            style={{
              width: 10,
              height: "70vh",
              background:
                `linear-gradient(to bottom, ${
                  selectedOrderData?.status === "Cancelled" ? "red" : "#3D1880"
                } 0%, ${
                  selectedOrderData?.status === "Cancelled" ? "red" : "#3D1880"
                } ` +
                (firstUnreachedIndex / trackData.length < 0
                  ? 1.5
                  : firstUnreachedIndex / trackData.length) *
                  92 +
                "%, #C8C8C8 " +
                (firstUnreachedIndex / trackData.length < 0
                  ? 1.5
                  : firstUnreachedIndex / trackData.length) *
                  95 +
                "%, #C8C8C8 100%)",
              display: "flex",
              borderRadius: 10,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            {trackData?.map((item, index) => (
              <div style={{ display: "flex", alignItems: "start" }} key={index}>
                <div
                  style={{
                    height: 5,
                    width: 5,
                    backgroundColor: "#FFF",
                    borderRadius: 100,
                    marginTop: index === 0 ? 20 : 0,
                    marginBottom: index === trackData.length - 1 ? 20 : 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "500px",
                      //   backgroundColor: firstUnreachedIndex - 1 === index ? '#E9E9EA' : '',
                      position: "absolute",
                      marginLeft: 35,
                      minHeight: 70,
                      padding: 5,
                      borderRadius: 10,
                      marginTop: -15,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <CustomTypography
                      fontSize={14}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "600",
                        marginLeft: firstUnreachedIndex - 1 === index ? 1 : 0,
                        textAlign: "start",
                      }}
                    >
                      {item.name}
                    </CustomTypography>
                    {/* <CustomTypography
                      fontSize={12}
                      style={{
                        fontWeight: '600',
                        marginLeft: firstUnreachedIndex - 1 === index ? 1 : 0,
                        textAlign: 'start'
                      }}
                    >
                      {item.createdAt}
                    </CustomTypography> */}
                    {firstUnreachedIndex - 1 === index ? (
                      <CustomTypography
                        fontSize={14}
                        style={{
                          marginLeft: firstUnreachedIndex - 1 === index ? 1 : 0,
                          textAlign: "start",
                        }}
                      >
                        {item.details}
                      </CustomTypography>
                    ) : (
                      <></>
                    )}
                    {firstUnreachedIndex - 1 === index ? (
                      <CustomTypography
                        fontSize={14}
                        style={{
                          marginLeft: firstUnreachedIndex - 1 === index ? 1 : 0,
                          textAlign: "start",
                        }}
                      >
                        {item.time}
                      </CustomTypography>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <animated.div
              style={{
                ...props,
                width: 50,
                height: 50,
                backgroundColor:
                  selectedOrderData?.status === "Cancelled" ? "red" : "#3D1880",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <img
                style={{ height: 30, width: 30 }}
                src="/right-round.png"
                alt="icon"
              />
            </animated.div>
          </div>
          <CustomTypography
            onClick={() => {
              // localStorage.setItem('path', `/order-tracking-history/${selectedOrderData?._id}`)
              navigate(`/order-tracking-history/${selectedOrderData?._id}`);
            }}
            style={{ marginTop: 5, color: "#368DE3", cursor: "pointer" }}
          >
            View all history
          </CustomTypography>
        </div>
      </div>
    </div>
  );
}
