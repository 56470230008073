import React from "react";
import styled from "styled-components";
import { Data } from "./sidebarData";
import SubMenu from "./subMenu";
import { isMobile } from "../../globalStyles/mobile";

const SidebarWrap = styled.div`
  width: 100%;
  height: calc(
    100vh - ${isMobile() ? 115 : 60}px
  ); /* Adjust height to be the total height minus the AppBar height */
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: ${isMobile() ? "4rem" : "1rem"};
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;

const Sidebar = ({ onClickSubMenu, onClickMenu, hideSideBar }) => {
  return (
    <div
      style={{
        background: "#ffffff",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "fixed",
        left: 0,
        width: hideSideBar ? 79 : 230,
        transition: "350ms",
        zIndex: 10,
        color: "#000",
      }}
    >
      <SidebarWrap>
        {Data.map((item, index) => (
          <SubMenu
            hideSideBar={hideSideBar}
            onClickMenu={onClickMenu}
            onClickSubMenu={onClickSubMenu}
            item={item}
            key={index}
          />
        ))}
      </SidebarWrap>
    </div>
  );
};

export default Sidebar;
