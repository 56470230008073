import { SvgIcon } from "@mui/material";
import React from "react";

const HomeIcon = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2569_4022"
          style={{ maskType: "alpha" }} 
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill={fill} />
        </mask>
        <g mask="url(#mask0_2569_4022)">
          <path
            d="M5.00016 15.8333H7.50016V10.8333H12.5002V15.8333H15.0002V8.33333L10.0002 4.58333L5.00016 8.33333V15.8333ZM3.3335 17.5V7.5L10.0002 2.5L16.6668 7.5V17.5H10.8335V12.5H9.16683V17.5H3.3335Z"
            fill={fill} 
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default HomeIcon;
