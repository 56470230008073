import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { eventService } from "../../utils/services/eventService";

const TrackingEventModal = ({
  open,
  handleClose,
  onAddData,
  selectedTrackingEvent,
}) => {
  const [trackingDetails, setTrackingDetails] = useState({
    leg: "",
    code: "",
    description: "",
    client_description: "",
    client_event_id: ''
  });
  const [optional, setOptional] = useState("");
  const [systemGenerated, setSystemGenerated] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const clientId = localStorage.getItem("client_id");

  useEffect(() => {
    if (selectedTrackingEvent) {
      setTrackingDetails({
        leg: selectedTrackingEvent.leg || "",
        code: selectedTrackingEvent.code || "",
        description: selectedTrackingEvent.description || "",
        client_description: selectedTrackingEvent.client_description || "",
        client_event_id: selectedTrackingEvent.client_event_id || ""
      });

      setOptional(selectedTrackingEvent.optional ? "Yes" : "No");
      setSystemGenerated(selectedTrackingEvent.system_generated ? "Yes" : "No");
    } else {
      initialData();
    }
  }, [selectedTrackingEvent]);

  const initialData = () => {
    setTrackingDetails({
      leg: "",
      code: "",
      description: "",
      client_description: "",
      client_event_id: ""
    });
    setOptional("");
    setSystemGenerated("");
    setFormErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!trackingDetails.leg) {
      errors.leg = "Leg is required";
    }
    if (!trackingDetails.code) {
      errors.code = "Code is required";
    }
    if (!trackingDetails.description) {
      errors.description = "Description is required";
    }
    if (!optional) {
      errors.optional = "Select Optional";
    }
    if (!systemGenerated) {
      errors.systemGenerated = "Select System Generated";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      if (clientId) {
        if (trackingDetails.client_event_id) {
          console.log("edit");
          await eventService.clientEventEdit(trackingDetails.client_event_id, {
            leg: trackingDetails.leg,
            code: trackingDetails.code,
            description: trackingDetails.description,
            optional: optional === "Yes",
            system_generated: systemGenerated === "Yes",
            client_id: clientId,
            client_description: trackingDetails.client_description
          });
        } else {
          console.log("create");
          await eventService.clientEventCreate({
            leg: trackingDetails.leg,
            code: trackingDetails.code,
            description: trackingDetails.description,
            optional: optional === "Yes",
            system_generated: systemGenerated === "Yes",
            client_id: clientId,
            client_description: trackingDetails.client_description
          });
        }
      } else {
        await eventService.edit(selectedTrackingEvent._id, {
          leg: trackingDetails.leg,
          code: trackingDetails.code,
          description: trackingDetails.description,
          optional: optional === "Yes",
          system_generated: systemGenerated === "Yes",
        });
      }
      onAddData && onAddData();
      toast.success("Tracking Event edited successfully!");
      initialData();
    } catch (e) {
      toast.error("Failed to edit Tracking Event.");
      console.error(e);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await eventService.create({
        leg: trackingDetails.leg,
        code: trackingDetails.code,
        description: trackingDetails.description,
        optional: optional === "Yes",
        system_generated: systemGenerated === "Yes",
      });
      onAddData && onAddData();
      toast.success("Tracking Event created successfully!");
      handleClose();
      initialData();
    } catch (e) {
      toast.error("Failed to create Tracking Event.");
      console.error(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedTrackingEvent ? "Edit" : "Create"} Tracking Event
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Leg <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="leg"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={trackingDetails.leg}
              onChange={(e) =>
                setTrackingDetails({ ...trackingDetails, leg: e.target.value })
              }
              fullWidth
              placeholder="Enter Leg"
              margin="dense"
              error={!!formErrors.leg}
              disabled={!!selectedTrackingEvent}
            />
            {!!formErrors.leg && (
              <FormHelperText error>{formErrors.leg}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Code <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              placeholder="Enter Code"
              name="code"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={trackingDetails.code}
              onChange={(e) =>
                setTrackingDetails({
                  ...trackingDetails,
                  code: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.code}
              disabled={!!selectedTrackingEvent}
            />
            {!!formErrors.code && (
              <FormHelperText error>{formErrors.code}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Optional <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <FormControl fullWidth size="small" margin="dense">
              <Select
                value={optional}
                onChange={(e) => setOptional(e.target.value)}
                size="small"
                disabled={!!selectedTrackingEvent}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              System Generated <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <FormControl fullWidth size="small" margin="dense">
              <Select
                value={systemGenerated}
                onChange={(e) => setSystemGenerated(e.target.value)}
                size="small"
                disabled={!!selectedTrackingEvent}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Description <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              rows={4}
              multiline
              placeholder="Enter Description"
              name="description"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={trackingDetails.description}
              onChange={(e) =>
                setTrackingDetails({
                  ...trackingDetails,
                  description: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.description}
              disabled={clientId}
            />
            {!!formErrors.description && (
              <FormHelperText error>{formErrors.description}</FormHelperText>
            )}
          </Grid>
          {clientId && <Grid item xs={12} sm={12}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Client Description <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              rows={4}
              multiline
              placeholder="Enter Description"
              name="description"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={trackingDetails.client_description}
              onChange={(e) =>
                setTrackingDetails({
                  ...trackingDetails,
                  client_description: e.target.value,
                })
              }
              fullWidth
              margin="dense"
            />
          </Grid>}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedTrackingEvent ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedTrackingEvent ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrackingEventModal;
