import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import AddModal from "../../components/add-modal";
import { portService } from "../../utils/services/portService";
import PortListContainer from "../../components/zone-port-list-container";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Container, Typography } from "@mui/material";
import { clientService } from "../../utils/services/clientService";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AppContext from "../../components/context/context";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField/TextField";
import { toast } from "react-toastify";
import CustomPagination from "../../components/pagination";

export default function Port() {
  const initialData = {
    name: "",
    country: "",
    location: "",
    postal_code: "",
    type: "",
  };
  const [showAddModal, setShowAddModal] = useState(false);
  const [portDetails, setPortDetails] = useState(initialData);
  const [portData, setPortData] = useState([]);
  const [selectedPort, setSelectedPort] = useState(null);
  const [editedData, setEditedData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [formErrors, setFormErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [searchValue, setSearchValue] = useState("");
  const [clientData, setClientData] = useState([]);
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const [viewMode, setViewMode] = useState("list");
  const { user } = useContext(AppContext);

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);
  const handleClientFilterChange = (event, newValue) => {
    setClientFilter(newValue);
    setPage(0);
  };

  const validateForm = () => {
    const errors = {};
    if (!portDetails.name) {
      errors.name = "Port name is required";
    }
    if (!portDetails.type) {
      errors.type = "Port type is required";
    }
    if (portDetails.postal_code < 0) {
      errors.postal_code = "Postal code should not be negative";
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = "Please select Client";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleEditClick = (e) => {
    setSelectedClient({ name: e?.client_id?.name, _id: e?.client_id?._id });
    setSelectedPort(e);
    setEditedData({
      country: e.country,
      name: e.name,
      location: e.location,
      type: e.type,
      postal_code: e.postal_code,
    });
    setFormErrors({});
  };

  const fetchPortData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await portService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setPortData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  console.log(portDetails);
  useEffect(() => {
    fetchPortData();
  }, [page, rowsPerPage, clientFilter, searchValue]);

  const createPort = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      const response = await portService.create({
        ...portDetails,
        client_id: client_id,
      });

      toast.success("Port Created successfully!");

      fetchPortData();
      setShowAddModal(false);
      setPortDetails(initialData);
    } catch (error) {
      console.error("Create failed:", error);
      // Handle create failure
    } finally {
      setLoading(false);
    }
  };

  const onDeleteSuccess = async (e) => {
    e.preventDefault();
    try {
      if (!selectedPort._id) {
        console.log("Please choose an item to delete");
        return;
      }
      setLoading(true);
      const response = await portService.delete(selectedPort._id);
      fetchPortData();
      toast.success("Port Deleted successfully!");
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateEditForm = () => {
    const errors = {};
    if (!editedData.name) {
      errors.name = "Port name is required";
    }
    if (!editedData.type) {
      errors.type = "Port type is required";
    }
    if (editedData.postal_code < 0) {
      errors.postal_code = "Postal code should not be  negative";
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.client = "Please select Client";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateEditForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient._id;
      const response = await portService.edit(
        {
          ...editedData,
          client_id: client_id,
        },
        selectedPort._id
      );
      toast.success("Port Edited successfully!");
      fetchPortData();
      setFormErrors({});
      setOpenModal(false);
    } catch (error) {
      console.error("Edit failed:", error);
      // Handle edit failure
    } finally {
      setLoading(false);
    }
  };

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );

  return (
    <DashboardContainer
      hideFilterClient
      onSearchData={(x) => {
        setSearchValue(x);
        setPage(0);
      }}
      onClickAddButton={() => {
        setShowAddModal(true);
        setFormErrors({});
        setSelectedClient({});
      }}
      hideUploadButton={true}
      header={"Port List"}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
      selectedClient={selectedClient?.name}
      title={"+ Add Port"}
      setViewMode={setViewMode}
    >
      <AddModal
        onSelectClient={(e) => {
          setSelectedClient(e);
        }}
        title={"Port"}
        port
        onClickAdd={(e) => {
          createPort(e);
        }}
        onChangePostalCode={(e) =>
          setPortDetails({ ...portDetails, postal_code: e })
        }
        onChangeName={(e) => setPortDetails({ ...portDetails, name: e })}
        onChangeCountry={(e) => setPortDetails({ ...portDetails, country: e })}
        onChangeLocation={(e) =>
          setPortDetails({ ...portDetails, location: e })
        }
        handleChangeType={(e) => setPortDetails({ ...portDetails, type: e })}
        handleClose={() => {
          setShowAddModal(false);
          setSelectedClient({ name: "" });
        }}
        open={showAddModal}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      ></AddModal>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      {portData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <PortListContainer
          onSelectClient={(e) => {
            setEditedData({ ...editedData, client_id: e?._id });
            setSelectedClient(e);
          }}
          selectedClient={selectedClient?.name}
          setPostalCode={(e) =>
            setEditedData({ ...editedData, postal_code: e })
          }
          setTypeValue={(e) => setEditedData({ ...editedData, type: e })}
          setLocationValue={(e) =>
            setEditedData({ ...editedData, location: e })
          }
          setCountryValue={(e) => setEditedData({ ...editedData, country: e })}
          postalCode={editedData.postal_code}
          countryValue={editedData.country}
          locationValue={editedData.location}
          typeValue={editedData.type}
          port={true}
          handleAddData={(e) => handleEditData(e)}
          handleDeleteClick={(e) => setSelectedPort(e)}
          onDeleteData={(e) => onDeleteSuccess(e)}
          selectedData={selectedPort}
          listName={"Port"}
          setValue={(e) => setEditedData({ ...editedData, name: e })}
          value={editedData.name}
          handleEditClick={(e) => handleEditClick(e)}
          data={portData}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          openModal={openModal}
          setOpenModal={setOpenModal}
          viewMode={viewMode}
          deleteTitle={`Are you sure you want to delete ${selectedPort?.name} ?`}
        ></PortListContainer>
      )}

      {/* <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false,
            },
          },
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  );
}
