// api.js

import axiosInstance from "../axiosInstance";

export const eventService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/rl_event", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (searchValue, query) => {
    try {
      let apiQuery = `/rl_event?`;

      // if (clientId) {
      //   apiQuery += `?client_id=${clientId}`;
      // }
      if (searchValue) apiQuery += `&code[$search]=${searchValue}`;
      if (query.length) apiQuery += `&${query}`;
      const response = await axiosInstance.get(apiQuery);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/rl_event/${id}`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  clientFetch: async (query) => {
    try {
      let apiQuery = `/rl_client_event?`;
      if (query.length) apiQuery += `&${query.join('&')}`;
      let response = await axiosInstance.get(apiQuery);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  clientEventEdit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/rl_client_event/${id}`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  clientEventCreate: async (obj) => {
    try {
      let response = await axiosInstance.post("/rl_client_event", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/rl_event/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getEventLogs: async (orderId) => {
    try {
      let response = await axiosInstance.get(`/event-log?event_id=${orderId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  retrigger: async (obj) => {
    try {
      let response = await axiosInstance.post(`/trigger-event`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchLeg: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(`/fetch-rl-event-leg?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCode: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(`/fetch-rl-event-code?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
