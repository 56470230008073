import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import GradientCard from "../../components/gradient-card";
import OrderTable from "../../components/order-table";
import OverpackGroupModal from "../../components/overpack-group-modal";
import ShipmentGroupModal from "../../components/shipment-group-modal";
import CustomTypography from "../../globalStyles/fonts";
import FilterIcon from "../../globalStyles/icons/filter-icon";
import { orderService } from "../../utils/services/orderService";
import { overPackService } from "../../utils/services/overPackService";
import { setEditObjectForOrders } from "../../utils/setEditDataForOrder";
import config from "../../config";
export default function WarehouseManagement() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [showShipmentChooseModal, setShowShipmentChooseModal] = useState(false);
  const [allShipments, setAllShipments] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOverpackGroupModal, setShowOverpackGroupModal] = useState(false);
  const [allOverpacks, setAllOverpacks] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const tabs = ["Orders", "Shipments", "Over-Packs", "Manifests"];
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await orderService.fetch();
      setAllOrders(response.data);
      setLoading(false);
    } catch (error) {
      console.error("fetch orders failed:", error);
      setLoading(false);
      throw error;
    }
  };
  const fetchOverpacks = async () => {
    setLoading(true);
    try {
      const response = await overPackService.fetch();
      setAllOverpacks(response);
      setLoading(false);
    } catch (error) {
      console.error("fetch orders failed:", error);
      setLoading(false);
      throw error;
    }
  };
  const fetchShipments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.tmsBaseURL}/shipments`);
      setAllShipments(response.data);
      setLoading(false);
      return response.data;
    } catch (err) {
      console.error("Failed to fetch orders:", err);
      setLoading(false);
      throw err;
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const onClickUpdateOrder = async (data, status) => {
    setLoading(true);
    try {
      const response = await orderService.edit(
        setEditObjectForOrders(data, status),
        data?._id
      );
      setLoading(false);
      if (response) {
        fetchOrders();
      }
    } catch (error) {
      console.error("fetch orders failed:", error);
      setLoading(false);
      throw error;
    }
  };
  const createShipment = async (order) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.tmsBaseURL}/shipments`,
        order
      );
      if (response.data.shipment?.id) {
        onClickUpdateOrder(
          {
            ...order,
            shipment_id: response.data.shipment?.id,
          },
          ["Shipment created"]
        );
      }
      fetchShipments();
      setLoading(false);
      return response.data;
    } catch (err) {
      console.error("Failed to fetch orders:", err);

      setLoading(false);
      throw err;
    }
  };
  const onOverpackCreation = async (id) => {
    setLoading(true);
    try {
      const response = await orderService.edit(
        {
          overpack_id: id.toString(),
          shipment_status: [
            ...selectedOrder.shipment_status,
            "Overpack created",
          ],
        },
        selectedOrder?._id
      );
      setLoading(false);
      if (response) {
        fetchOrders();
        fetchOverpacks();
      }
    } catch (error) {
      console.error("update  failed:", error);
      setLoading(false);
      throw error;
    }
  };
  const onManifestCreation = async (id) => {
    setLoading(true);
    try {
      const response = await orderService.edit(
        {
          manifest_id: id.toString(),
          shipment_status: [
            ...selectedOrder.shipment_status,
            "Manifest created",
          ],
        },
        selectedOrder?._id
      );
      setLoading(false);
      if (response) {
        fetchOrders();
      }
    } catch (error) {
      console.error("update  failed:", error);
      setLoading(false);
      throw error;
    }
  };
  useEffect(() => {
    fetchOrders();
    fetchShipments();
    fetchOverpacks();
  }, []);
  const hideScrollbarStyle = {
    "&::WebkitScrollbar": {
      display: "none",
    },
    msOverflowStyle: "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  };
  return (
    <div
      style={{
        display: "flex",
        overflow: "auto",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          overflowY: "scroll",
          flexDirection: "column",
          marginTop: 10,
          width: "100%",
          ...hideScrollbarStyle,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            overflow: "auto",
            minHeight: 200,
            ...hideScrollbarStyle,
          }}
        >
          <GradientCard></GradientCard>
          <GradientCard
            smallRoundBoxBg={"#FF95A0"}
            backgroundColor={"#FFE2E5"}
          ></GradientCard>
          <GradientCard
            smallRoundBoxBg={"#BF82FF"}
            backgroundColor={"#F3E8FF"}
          ></GradientCard>
          <GradientCard
            smallRoundBoxBg={"#4BFF69"}
            backgroundColor={"#DCFCE7"}
          ></GradientCard>
        </div>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
          mt={2}
          width="100%"
        >
          <CustomTypography style={{ marginLeft: 1 }}>
            Warehouse Management
          </CustomTypography>
          <Box
            alignSelf={"center"}
            mt={1}
            width="99%"
            height="2px"
            bgcolor="#e0e0e0"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#EEEEEE",
            marginInline: 1,
            marginTop: 1,
            position: "sticky",
            top: 0,
            "& .tab": {
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: "#CCCCCC",
              },
            },
            "& .selected-tab": {
              animation: "slideIn 0.3s forwards",
            },
            "@keyframes slideIn": {
              "0%": {
                transform: "translateX(-100%)",
              },
              "100%": {
                transform: "translateX(0)",
              },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Box
              key={index}
              onClick={() => handleTabChange(index)}
              className={`tab ${selectedTab === index ? "selected-tab" : ""}`}
              sx={{
                flex: 1,
                textAlign: "center",
                padding: 2,
                borderRight: index !== tabs.length - 1 ? 1 : 0,
                fontWeight: "bold",
                borderColor: "divider",
                backgroundColor: selectedTab === index ? "#151D48" : "#EEEEEE",
                color: selectedTab === index ? "#FFF" : "#000",
                cursor: "pointer",
                "&:lastChild": {
                  borderRight: 0,
                },
              }}
            >
              <CustomTypography>{tab}</CustomTypography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 2,
            padding: "0 10px",
          }}
        >
          <TextField label="Search here ..." variant="outlined" size="small" />
          <Select
            value={filter}
            onChange={handleFilterChange}
            displayEmpty
            variant="outlined"
            size="small"
            sx={{ minWidth: 120, marginRight: 2 }}
          >
            <MenuItem value="">
              <Box display="flex" alignItems="center">
                <FilterIcon stroke={"grey"} />
                <Typography sx={{ marginLeft: 1 }}>All</Typography>
              </Box>
            </MenuItem>
            <MenuItem value="Option1">New Order</MenuItem>
            <MenuItem value="Option2">Pending Shipments</MenuItem>
            <MenuItem value="Option3">Shipment Created</MenuItem>
          </Select>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <div style={{ marginInline: 1 }}>
            <OrderTable
              onCreateManifest={(row) => {
                setSelectedOrder(row);
                fetchOverpacks();
                setShowOverpackGroupModal(true);
              }}
              onCreateOverpack={(row) => {
                setSelectedOrder(row);
                setShowShipmentChooseModal(true);
              }}
              onCreateShipment={createShipment}
              onAcceptOrder={onClickUpdateOrder}
              allOrders={[
                ...allOrders,
                ...allOrders,
                ...allOrders,
                ...allOrders,
                ...allOrders,
              ]}
            />
          </div>
        )}

        <ShipmentGroupModal
          onSuccessOverpackCreation={(id) => onOverpackCreation(id)}
          allShipments={allShipments}
          setOpen={setShowShipmentChooseModal}
          open={showShipmentChooseModal}
        ></ShipmentGroupModal>
        <OverpackGroupModal
          onSuccessManifestCreation={(id) => onManifestCreation(id)}
          allOverpacks={allOverpacks}
          setOpen={setShowOverpackGroupModal}
          open={showOverpackGroupModal}
        ></OverpackGroupModal>
      </div>
    </div>
  );
}
