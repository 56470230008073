// AppContext.js
import React, { createContext, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AppContext = createContext({})

export const AppProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [currentMenu, setCurrentMenu] = useState({ title: 'Dashboard' })
  const [currentSubMenu, setCurrentSubMenu] = useState({ title: '' })
  const [user, setUser] = useState(null)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        setShowSideBar,
        showSideBar,
        currentMenu,
        setCurrentMenu,
        currentSubMenu,
        setCurrentSubMenu,
        user,
        setUser,
        setIsSidebarOpen,
        isSidebarOpen
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        pauseOnHover={false}
        closeButton={false}
      />
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
