// components/Breadcrumbs.js
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link, Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import CustomTypography from '../../globalStyles/fonts'
import { getObjectByPath } from '../../utils/getObjectByPath'
import { isMobile } from '../../globalStyles/mobile'

const Breadcrumbs = () => {
  const location = useLocation()
  const path = location.pathname
  const breadcrumbData = path.toString().includes('/account-setting')
    ? { title: 'Account Setting', subNav: null }
    : path.toString().includes('/manage-product')
    ? { title: 'Manage Product', subNav: null }
    : path.toString().includes('/order-tracking-history')
    ? { title: 'Order Tracking History', subNav: null }
    : path.toString().includes('/track-order')
    ? { title: 'Order Tracking', subNav: null }
    : getObjectByPath(path)
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" color="#0E1434" />}
    >
      <Link component={RouterLink} color="inherit" to="/">
        <img src="/home.png" alt="home" />
      </Link>
      {breadcrumbData && breadcrumbData.title && (
        <CustomTypography
          color="#007DFF"
          fontSize={isMobile() ? 12 : "14px"}
          fontWeight={500}
        >
          {breadcrumbData.title}
        </CustomTypography>
      )}
      {breadcrumbData && breadcrumbData.subNav && (
        <CustomTypography
          color="#A0AEC0"
          fontSize={isMobile() ? 12 : "14px"}
          fontWeight={600}
        >
          {breadcrumbData.subNav.title}
        </CustomTypography>
      )}
    </MUIBreadcrumbs>
  );
}

export default Breadcrumbs
