import { SvgIcon } from "@mui/material";
import React, { useState } from "react";

const DashboardIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.765625 0.028357C0.484375 0.0971074 0.228125 0.309607 0.09375 0.578357L0.015625 0.734607V2.65648V4.57836L0.090625 4.74398C0.18125 4.94398 0.440625 5.19086 0.646875 5.26898C0.7875 5.32523 0.95625 5.32836 3.59063 5.33773C5.99062 5.34398 6.40938 5.33773 6.56563 5.30023C6.89375 5.21586 7.09375 5.04398 7.24688 4.71898L7.32812 4.54711V2.68773C7.32812 0.965857 7.325 0.815857 7.27187 0.662732C7.19375 0.443982 6.95625 0.187732 6.74375 0.090857L6.57812 0.0158572L3.71875 0.00960732C2.14688 0.00960732 0.81875 0.0158572 0.765625 0.028357ZM6.45 0.700232C6.66563 0.790857 6.65625 0.693982 6.65625 2.68148V4.47523L6.56563 4.56586L6.475 4.65648H3.68125C1.15 4.65648 0.88125 4.65023 0.815625 4.60648C0.659375 4.49398 0.65625 4.47211 0.65625 2.65336C0.65625 1.18148 0.6625 0.950232 0.70625 0.859607C0.80625 0.647107 0.6625 0.656482 3.65 0.656482C5.88438 0.656482 6.3625 0.662732 6.45 0.700232Z"
          fill={fill}
        />
        <path
          d="M9.39063 0.0437508C9.32188 0.0656252 9.22188 0.106251 9.16876 0.134376C9.05938 0.190625 8.83751 0.421876 8.77188 0.55C8.65626 0.771875 8.65313 0.928125 8.66251 4.78125L8.67188 8.54688L8.75313 8.71875C8.86876 8.96875 9.00626 9.10938 9.24376 9.225L9.45313 9.32812H12.3125C15.0063 9.32812 15.1813 9.325 15.3375 9.27188C15.5563 9.19375 15.8125 8.95625 15.9094 8.74375L15.9844 8.57813V4.65625V0.734376L15.8781 0.5375C15.7594 0.31875 15.6625 0.225 15.425 0.1L15.2656 0.015626L12.3906 0.00937557C10.1281 0.00312519 9.48751 0.0125008 9.39063 0.0437508ZM15.1406 0.70625C15.3563 0.806251 15.3438 0.562501 15.3438 4.65313C15.3438 8.63125 15.35 8.49063 15.1844 8.60625C15.1188 8.65 14.85 8.65625 12.3188 8.65625H9.52501L9.43438 8.56563L9.34376 8.475V4.68125C9.34376 1.22813 9.34688 0.88125 9.39376 0.815625C9.50626 0.656251 9.44376 0.659375 12.3375 0.656251C14.7344 0.656251 15.05 0.6625 15.1406 0.70625Z"
          fill={fill}
        />
        <path
          d="M0.7625 6.7C0.4625 6.77187 0.184375 7.01562 0.075 7.3C0.01875 7.44687 0.015625 7.59375 0.015625 11.3594V15.2656L0.121875 15.4625C0.240625 15.6813 0.3375 15.775 0.575 15.9L0.734375 15.9844H3.65625H6.57812L6.74375 15.9094C6.95625 15.8125 7.19375 15.5563 7.27187 15.3375C7.325 15.1812 7.32812 14.975 7.32812 11.3125V7.45312L7.24688 7.28125C7.09375 6.95937 6.89375 6.7875 6.57188 6.70312C6.41875 6.6625 5.98438 6.65625 3.65625 6.65938C1.6 6.65938 0.88125 6.67188 0.7625 6.7ZM6.56563 7.43437L6.65625 7.525V11.3187C6.65625 14.775 6.65313 15.1187 6.60625 15.1844C6.49062 15.3469 6.55 15.3438 3.65313 15.3438C1.26875 15.3438 0.95 15.3375 0.859375 15.2937C0.64375 15.1937 0.65625 15.4375 0.65625 11.3469C0.65625 7.36875 0.65 7.50937 0.815625 7.39375C0.88125 7.35 1.15 7.34375 3.68125 7.34375H6.475L6.56563 7.43437Z"
          fill={fill}
        />
        <path
          d="M9.43418 10.7C9.11543 10.7844 8.8998 10.9719 8.75293 11.2813L8.67168 11.4531L8.6623 13.2188C8.65293 15.0969 8.6623 15.25 8.79668 15.4875C8.8998 15.6656 9.11855 15.8563 9.2998 15.925C9.44668 15.9813 9.5748 15.9844 12.3592 15.9844H15.2654L15.4248 15.9C15.6623 15.775 15.7592 15.6813 15.8779 15.4625L15.9842 15.2656V13.3438V11.4219L15.9092 11.2563C15.8186 11.0563 15.5592 10.8094 15.3529 10.7313C15.2123 10.675 15.0467 10.6719 12.3904 10.6656C10.2279 10.6625 9.54355 10.6688 9.43418 10.7ZM15.1842 11.3938C15.3404 11.5063 15.3436 11.5281 15.3436 13.3469C15.3436 14.8219 15.3373 15.05 15.2936 15.1406C15.1936 15.3531 15.3311 15.3438 12.3467 15.3438C9.4498 15.3438 9.50918 15.3469 9.39355 15.1844C9.3498 15.1188 9.34355 14.925 9.34355 13.3188V11.525L9.43418 11.4344L9.5248 11.3438H12.3186C14.8498 11.3438 15.1186 11.35 15.1842 11.3938Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default DashboardIcon;
