import React, { useState, useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { clientService } from '../../utils/services/clientService'

const ThemeWrapper = ({ children }) => {
  const [clientDetails, setClientDetails] = useState(null)
  const clientId = localStorage.getItem('client_id')
  const user = localStorage.getItem('user')

  const fetchClientById = async () => {
    try {
      const client = await clientService.get(clientId)
      setClientDetails(client)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (clientId) {
      fetchClientById(user)
    }
  }, [user])

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              color: "black",
              backgroundColor: "transparent",
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px transparent inset",
                WebkitTextFillColor: "black",
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px transparent inset",
              WebkitTextFillColor: "black",
              caretColor: "black",
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: clientDetails?.primary_color || "#DC585C",
      },
      secondary: {
        main: clientDetails?.secondary_color || "#7C7C7C",
      },
      // background: {
      //   default: clientDetails?.background_primary || '#f0f0f0',
      //   paper: clientDetails?.background_secondary || '#ffffff'
      // },
      text: {
        main: clientDetails?.text_color || "#000",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#000",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeWrapper
