import React, { useState, useEffect, useRef, useContext } from 'react'
import { Box, Button, Divider, Grid, IconButton, Popover, Typography } from '@mui/material'
import dayjs from 'dayjs'
import SearchBar from '../headerSearchbar'
import GridIcon from '../../globalStyles/icons/grid-icon'
import ListIcon from '../../globalStyles/icons/list-icon'
import ExportIcon from '../../globalStyles/icons/export-icon'
import AppContext from '../context/context'
import { useLocation } from 'react-router-dom'
import { clientService } from '../../utils/services/clientService'
import CustomTypography from '../../globalStyles/fonts'
import DateRangePickerComponent from '../date-range-picker'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import SyncIcon from '@mui/icons-material/Sync'
import { isMobile } from '../../globalStyles/mobile'
import ClientAutocomplete from '../clientAutocomplete'
import AddIcon from '@mui/icons-material/Add'
import FilterIcon from '@mui/icons-material/FilterAlt'
import FilterComponent from '../grid-filter-modal'
import { useTheme } from '@mui/styles'

function Header({
  onClickUpload,
  hideUploadButton,
  hideAddButton,
  onClickAddButton,
  onHandleUploadButton,
  zipZone,
  simulation,
  onUploadSimulationData,
  rateCard,
  searchBarPlaceholder,
  onSearchData,
  title,
  client,
  product,
  setViewMode,
  order,
  header,
  onSelectDate,
  showDownloadIcon,
  onClickRefresh,
  customClerance,
  importProduct,
  eventMapping,
  headerButtons,
  dedicatedPage,
  onClickExport,
  hideFilterClient,
  onSelectClient,
  onChangeFilter,
  fields,
  filterData
}) {
  const currentDate = new Date()
  const [selectedClientData, setSelectedClientData] = useState({ name: '' })
  const location = useLocation()
  const fileUploadRef = useRef()
  const { user } = useContext(AppContext)
  const [viewMode, setMode] = useState('list')
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs().add(7, 'day'))
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(currentDate.setMonth(currentDate.getMonth() - 2)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
    key: 'selection'
  })
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: '',
    name: '',
    filter: [],
    sort: 0,
    id: '_id',
    displayKey: 'name',
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: ''
  })

  const handleFilterSave = (selectedValues, filterKey, sortOrder, searchKey) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedValues,
      sort: sortOrder,
      searchKey: searchKey
    })

    const updatedColumns = filterData.columns.map((column) =>
      column.key === filterKey
        ? {
            ...column,
            filter: selectedValues,
            sort: sortOrder,
            searchKey: searchKey
          }
        : column
    )

    setSelectedFilterData({
      ...selectedfilterData,
      filter: selectedValues,
      searchKey
    })
    setOpenFilterModal(false)
  }

  const handleOpenModal = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget)
    setOpenFilterModal(true)
    const fieldData = filterData.columns.find((x) => x.key === filterKey)
    if (fieldData) {
      setSelectedFilterData({
        ...fieldData,
        filter: fieldData.filter || [],
        searchKey: fieldData.searchKey || '',
        key: fieldData.key, // Ensure key is passed
        name: fieldData.name,
        id: fieldData.id || '_id',
        displayKey: fieldData.displayKey || 'name',
        options: fieldData.options || [],
        sort: fieldData.sort || 0,
        loadMoreButton: fieldData.loadMoreButton || false
      })
    }
  }

  const handleCloseFilter = () => {
    setAnchorElFilter(null)
    setOpenFilterModal(false)
  }
  const handleFieldSelection = (field) => {
    setSelectedFilterData((prevState) => ({
      ...prevState,
      key: field.key,
      name: field.name,
      filter: field.filter || [],
      sort: field.sort || 0,
      id: field.id || '_id',
      displayKey: field.displayKey || 'name',
      fetchApi: field.fetchApi || (() => {}),
      loadMoreButton: field.loadMoreButton || false,
      options: field.options || [],
      searchKey: field.searchKey || ''
    }))
  }

  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const [selectedClient, setSelectedClient] = useState(null)

  const handleFileUpload = (event) => {
    event.preventDefault()
    fileUploadRef.current.click()
  }

  const uploadFile = () => {
    const uploadedFile = fileUploadRef.current.files[0]
    if (simulation) {
      onUploadSimulationData && onUploadSimulationData(uploadedFile)
    }
    const formData = new FormData()
    formData.append('file', uploadedFile)
    onClickUpload && onClickUpload(formData)
    fileUploadRef.current.value = null
  }

  const capitalize = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase())

  const handleGridViewClick = () => {
    setViewMode('grid')
    setMode('grid')
  }

  const handleListViewClick = () => {
    setViewMode('list')
    setMode('list')
  }

  const handleClearStartDate = () => {
    setStartDate(null)
    setEndDate(null)
    onSelectDate(null)
  }
  // useEffect(() => {
  //   onSelectDate && onSelectDate(selectionRange);
  // }, [selectionRange]);

  return (
    <Box sx={{ marginTop: isMobile() ? 0 : 2, width: '100%' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={isMobile() ? 6 : 12} md={3} sm={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomTypography
              style={{ color: (theme) => theme.palette.text.main }}
              fontSize={isMobile() ? 14 : '20px'}
              fontWeight={500}
            >
              {header}
            </CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={isMobile() ? 6 : 12} md={9} sm={10}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1
            }}
          >
            {(order || product || client) &&
              (isMobile() ? (
                <IconButton style={{ backgroundColor: 'primary.default' }}>
                  <SyncIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  style={{
                    // margin: 3,
                    textTransform: 'none',
                    fontSize: 14,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  variant="contained"
                  onClick={onClickRefresh}
                  color="primary"
                >
                  <IconButton style={{ color: '#FFFFFF' }}>
                    <SyncIcon />
                  </IconButton>
                </Button>
              ))}
            <input
              onChange={uploadFile}
              type="file"
              id="excelFile"
              accept=".xls,.xlsx"
              ref={fileUploadRef}
              hidden
            />
            <div>{headerButtons}</div>

            {!hideUploadButton &&
              (isMobile() ? (
                <IconButton
                  style={{
                    color: primary_color
                  }}
                  onClick={(e) => (zipZone || rateCard ? onHandleUploadButton(e) : handleFileUpload(e))}
                >
                  <UploadFileIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  style={{
                    textTransform: 'none'
                  }}
                  color="primary"
                  variant="contained"
                  onClick={(e) => (zipZone || rateCard ? onHandleUploadButton(e) : handleFileUpload(e))}
                >
                  <UploadFileIcon style={{ marginRight: 5, height: 20 }} />
                  <CustomTypography fontSize="14px" fontWeight={500} color="#FFFFFF">
                    Upload
                  </CustomTypography>
                </Button>
              ))}

            {!hideAddButton &&
              (isMobile() ? (
                <IconButton
                  style={{
                    color: primary_color
                  }}
                  onClick={onClickAddButton}
                >
                  {showDownloadIcon ? <ExportIcon /> : <AddIcon />}
                </IconButton>
              ) : (
                <Button
                  size="small"
                  style={{
                    textTransform: 'none'
                  }}
                  color="primary"
                  variant="contained"
                  onClick={onClickAddButton}
                >
                  {showDownloadIcon && (
                    <IconButton sx={{ height: 30 }}>
                      <ExportIcon />
                    </IconButton>
                  )}
                  <CustomTypography fontSize="14px" fontWeight={500} color="#FFFFFF">
                    {title}
                  </CustomTypography>
                </Button>
              ))}
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1, color: '#C0C3D7' }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginInline: 0.2,
          flexDirection: isMobile() ? 'column' : 'row'
        }}
      >
        {!dedicatedPage && !eventMapping ? (
          <SearchBar onSearchData={onSearchData} placeholder={searchBarPlaceholder} />
        ) : (
          <></>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: isMobile() ? 'space-between' : 'end',
            width: '100%',
            overflowX: isMobile() ? 'auto' : 'visible',
            overflowY: 'visible',
            position: 'relative'
          }}
        >
          {/* {order && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: isMobile() ? "fit-content" : "auto",
                marginTop: 1,
                overflow: "visible",
                marginRight: 1,
              }}
            >
              <DateRangePickerComponent
                setSelectionRange={setSelectionRange}
                selectionRange={selectionRange}
              />
            </Box>
          )} */}

          {!dedicatedPage && !client && user?.role === 'admin' && !hideFilterClient && viewMode == 'list' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: isMobile() ? 'fit-content' : 'auto',
                overflow: 'visible' // Ensure overflow for the autocomplete as well
              }}
            >
              <ClientAutocomplete
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client)
                  onSelectClient && onSelectClient(client)
                }}
                width={order ? 200 : 250}
              />
            </Box>
          )}
          {viewMode === 'grid' ? (
            <Button
              variant="outlined"
              style={{
                border: '1px solid #D9D9D9',
                backgroundColor: '#FFFFFF',
                color: '#000000',
                height: 30,
                justifyContent: 'center',
                display: 'flex',
                textTransform: 'none',
                alignItems: 'center'
              }}
              onClick={handleOpenModal}
            >
              <IconButton>
                <FilterIcon sx={{ color: '#000' }} />
              </IconButton>
              <Typography fontFamily={'poppins'} fontSize={'14px'} fontWeight={400}>
                Filter
              </Typography>
            </Button>
          ) : (
            <></>
          )}

          {!dedicatedPage &&
          !order &&
          !zipZone &&
          !rateCard &&
          !simulation &&
          !customClerance &&
          !isMobile() &&
          !eventMapping &&
          !importProduct ? (
            <>
              <IconButton
                aria-label="grid-view"
                onClick={handleGridViewClick}
                style={{
                  color: viewMode === 'grid' ? primary_color || '#DC585C' : ''
                }}
              >
                <GridIcon fill={viewMode === 'grid' ? primary_color || '#DC585C' : 'black'} />
              </IconButton>
              <IconButton
                aria-label="list-view"
                onClick={handleListViewClick}
                style={{
                  color: viewMode === 'list' ? primary_color || '#DC585C' : 'grey',
                  marginRight: 10
                }}
              >
                <ListIcon fill={viewMode === 'list' ? primary_color || '#DC585C' : 'grey'} />
              </IconButton>
            </>
          ) : null}
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableScrollLock={true}
      >
        <FilterComponent
          open={openFilterModal}
          onClose={handleCloseFilter}
          fields={fields}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleFilterSave}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedfilterData?.key}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
          onFieldSelection={handleFieldSelection}
        />
      </Popover>
    </Box>
  )
}

export default Header
