// api.js

import axiosInstance from "../axiosInstance";
export const inventoryService = {
  get: async (id, sku) => {
    try {
      let response = await axiosInstance.get(
        `/inventory?sku=${sku}&product=${id}&$populate=product&$populate=provider`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
