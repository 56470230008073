import React, { useState, useEffect } from 'react'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Box
} from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { emailTemplateService } from '../../utils/services/email-templateService'
import DashboardContainer from '../../components/dashboard-container'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CustomTypography from '../../globalStyles/fonts'
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify'
import { isMobile } from '../../globalStyles/mobile'
import CustomPagination from '../../components/pagination'

const EmailTemplateScreen = () => {
  const [templates, setTemplates] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [templateSubject, setTemplateSubject] = useState('')
  const [templateContent, setTemplateContent] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openViewDialog, setOpenViewDialog] = useState(false)
  const [total, setTotal] = useState(0)
  const[viewMode,setViewMode]=useState('list')
  // Pagination states

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [formErrors, setFormErrors] = useState({});
  const handleOpenDeleteDialog = (template) => {
    setSelectedTemplate(template)
    setOpenDeleteDialog(true)
  }
  const fetchTemplates = () => {
    emailTemplateService
      .fetch(rowsPerPage, page * rowsPerPage)
      .then((response) => {
        setTemplates(response.data)
        setDataCount(response.total)
        setTotal(response.total)
      })
      .catch((e) => {
        throw e
      })
  }
  useEffect(() => {
    // Fetch templates from the API
    fetchTemplates()
  }, [page, rowsPerPage])

const handleSaveTemplate = () => {
  const isValid = validateForm();
  if (!isValid) {
    return;
  }

  if (selectedTemplate) {
    // Edit existing template
    emailTemplateService
      .edit(selectedTemplate._id, {
        name: templateName,
        content: templateContent,
        subject: templateSubject,
      })
      .then(() => {
        setTemplates((prev) =>
          prev.map((template) =>
            template.id === selectedTemplate._id
              ? {
                  ...template,
                  name: templateName,
                  content: templateContent,
                  subject: templateSubject,
                }
              : template
          )
        );
        setFormErrors({});
        toast.success("Template updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update template.");
      });
  } else {
    // Add new template
    emailTemplateService
      .create({
        name: templateName,
        content: templateContent,
        subject: templateSubject,
      })
      .then((response) => {
        setTemplates([...templates, response.data]);
        setFormErrors({});
        toast.success("Template created successfully!");
      })
      .catch((error) => {
        toast.error("Failed to create template.");
      });
  }

  fetchTemplates();
  setOpenDialog(false);
};


    const validateForm = () => {
      const errors = {};

      if (!templateName) {
        errors.templateName = "Template Name is required.";
      }

      if (!templateSubject) {
        errors.templateSubject = "Template Subject is required.";
      }

      if (!templateContent || templateContent === "<p><br></p>") {
        errors.templateContent = "Template Content is required.";
      }
      setFormErrors(errors);
      console.log("Form errors:", errors);
      return Object.keys(errors).length === 0;
    };


  const handleEditTemplate = (template) => {
    setOpenDialog(true)
    setTemplateName(template.name)
    setTemplateContent(template.content)
    setTemplateSubject(template.subject)
    setSelectedTemplate(template)
  }

  const handleDeleteTemplate = () => {
    emailTemplateService.delete(selectedTemplate?._id).then(() => {
      setTemplates(templates.filter((template) => template._id !== selectedTemplate?._id))
      toast.success("Template deleted successfully!")
      setOpenDeleteDialog(false)
    })
  }
  const handleViewTemplate = (template) => {
    setSelectedTemplate(template)
    setOpenViewDialog(true)
  }



  const handleClose=()=>{
    setOpenDialog(false)
    setFormErrors({})
  }

  return (
    <DashboardContainer
      hideFilterClient
      onSearchData={(x) => {
        console.log(x);
      }}
      onClickAddButton={() => {
        setOpenDialog(true);
        setTemplateName("");
        setTemplateContent("");
        setTemplateSubject("");
        setSelectedTemplate(null);
      }}
      hideUploadButton={true}
      header={"Template List"}
      title={"+ Add template"}
      setViewMode={setViewMode}
    >
      {isMobile() ? (
        <Box sx={{ width: "100%", padding: 0.5 }}>
          {templates?.map((template, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Template Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          : {template?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    mt={2}
                    justifyContent={"flex-end"}
                    display="flex"
                    gap={2}
                  >
                    <IconButton
                      style={{
                        color: "#007DFF",
                        padding: 0,
                        background: "#C3E1FF",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      aria-label="view"
                      onClick={() => {
                        handleViewTemplate(template);
                      }}
                    >
                      <img
                        style={{ height: 14, width: 20 }}
                        alt="view"
                        src="/eye.png"
                      />
                    </IconButton>
                    <IconButton
                      style={{
                        color: "#FEC50F",
                        padding: 0,
                        background: "#FEC50F33",
                        borderRadius: 5,
                        height: 30,
                        width: 30,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      aria-label="edit"
                      onClick={() => handleEditTemplate(template)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: "#D13438",
                        padding: 0,
                        background: "#D1343833",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      aria-label="delete"
                      onClick={() => handleOpenDeleteDialog(template)}
                    >
                      <img
                        style={{ height: 15, width: 15 }}
                        alt="delete"
                        src="/delete.png"
                      />
                    </IconButton>
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <TableContainer
          sx={{
            marginTop: 1,
            maxHeight: "70vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
          //component={Paper}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 5px",
              paddingBottom: 7,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  marginBottom: 10,
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: 8,
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    color="#000"
                    fontWeight={600}
                  >
                    Template Name
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 4,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    alignItems: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    color="#000"
                    fontWeight={600}
                  >
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates.map((template, index) => (
                <TableRow
                  key={index}
                  style={{
                    marginBottom: 10,
                    borderRadius: 8,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                >
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      borderTopLeftRadius: 8,
                      borderBottomLeftRadius: 8,
                      padding: 4,
                      backgroundColor: "#FFF",
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {template?.name}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      padding: 4,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      alignItems: "center",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <IconButton
                      style={{
                        color: "#007DFF",
                        padding: 0,
                        background: "#C3E1FF",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      aria-label="view"
                      onClick={() => {
                        handleViewTemplate(template);
                      }}
                    >
                      <img
                        style={{ height: 14, width: 20 }}
                        alt="view"
                        src="/eye.png"
                      />
                    </IconButton>

                    <IconButton
                      style={{
                        color: "#FEC50F",
                        padding: 0,
                        background: "#FEC50F33",
                        borderRadius: 5,
                        marginLeft: 10,
                        height: 30,
                        width: 30,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      aria-label="edit"
                      onClick={() => handleEditTemplate(template)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: "#D13438",
                        padding: 0,
                        background: "#D1343833",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: "0px 0px 4px 0px #00000040",
                        marginTop: 5,
                      }}
                      aria-label="delete"
                      onClick={() => handleOpenDeleteDialog(template)}
                    >
                      <img
                        style={{ height: 15, width: 15 }}
                        alt="delete"
                        src="/delete.png"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal for adding/editing template */}
      <Dialog
        open={openDialog}
        onClose={() => handleClose()}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle
            id="form-dialog-title"
            style={{
              fontFamily: "Poppins",
              fontSize: 24,
              fontWeight: 500,
              color: "#000000",
            }}
          >
            {selectedTemplate ? "Edit" : "Create"} Template
          </DialogTitle>
          <IconButton onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginBottom: 10 }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#11193F",
                }}
              >
                Template Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                size="small"
                required
                placeholder="Enter Template Name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                fullWidth
                margin="dense"
                error={!!formErrors.templateName}
                helperText={formErrors.templateName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#11193F",
                }}
              >
                Template Subject <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                required
                size="small"
                placeholder="Enter Template Subject"
                value={templateSubject}
                onChange={(e) => setTemplateSubject(e.target.value)}
                fullWidth
                margin="dense"
                error={!!formErrors.templateSubject}
                helperText={formErrors.templateSubject}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#11193F",
                }}
              >
                Template Content <span style={{ color: "red" }}>*</span>
              </Typography>
              <ReactQuill
                style={{ height: 150, marginTop: 10 }}
                value={templateContent}
                onChange={setTemplateContent}
                theme="snow"
              />
              {formErrors.templateContent && (
                <Typography color="error" variant="body2" sx={{ mt: 7 }}>
                  {formErrors.templateContent}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
          <Button
            onClick={handleClose}
            style={{
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: 600,
              color: "#007DFF",
              textTransform: "none",
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveTemplate}
            color="primary"
            variant="contained"
            style={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: 600,
              width: 100,
              textTransform: "none",
            }}
          >
            {selectedTemplate ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <CustomTypography>
            Are you sure you want to delete this template?
          </CustomTypography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "red" }}
            onClick={handleDeleteTemplate}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Template Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={() => setOpenViewDialog(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          {selectedTemplate && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <CustomTypography
                    color={"#11193F"}
                    fontSize={14}
                    fontWeight={400}
                  >
                    Template Name
                  </CustomTypography>
                  <TextField
                    size="small"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    type="text"
                    fullWidth
                    value={selectedTemplate?.name || ""}
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <CustomTypography
                    color={"#11193F"}
                    fontSize={14}
                    fontWeight={400}
                  >
                    Template Subject
                  </CustomTypography>
                  <TextField
                    size="small"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    type="text"
                    fullWidth
                    value={selectedTemplate?.subject || ""}
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomTypography
                    color={"#11193F"}
                    fontSize={14}
                    fontWeight={400}
                  >
                    Template Content
                  </CustomTypography>
                  <ReactQuill
                    value={selectedTemplate?.content}
                    readOnly={true}
                    theme="snow"
                    style={{ marginTop: 10, backgroundColor: "#f0f0f0" }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => setOpenViewDialog(false)}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  );
}

export default EmailTemplateScreen
