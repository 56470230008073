import React, { useEffect, useReducer, useState } from 'react'
import { Grid, Box } from '@mui/material'
import DataCard from '../../components/data-card'
import GraphCard from '../../components/graph-card'
import { orderService } from '../../utils/services/orderService'
import { isMobile } from '../../globalStyles/mobile'
import buildQuery from '../../utils/query-builder'
import { changeDateFormat } from '../../utils/utility'
import InventoryCard from '../../components/dashboard/inventory-chart'
import MonthlyOrderComparision from '../../components/dashboard/month-comparision'

const initialState = {
  columns: [
    {
      key: 'order_id',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        orderService.fetchOrderId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'shipping_address.name',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        orderService.fetchCustomerName(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'shipping_address.province',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        orderService.fetchCustomerState(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: '',
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'order_date',
      sort: 0,
      id: '_id',
      filter: {
        startDate: changeDateFormat(new Date(new Date().setMonth(new Date().getMonth() - 2))),
        endDate: changeDateFormat(new Date(new Date().setDate(new Date().getDate() + 2))),
        key: 'selection'
      },
      loadMoreButton: false,
      type: 'date-range'
    },
    {
      key: 'shipments.shipment',
      filter: [],
      sort: 0,
      id: '_id',
      displayKey: '_id',
      fetchApi: (searchKey, client_id, limit = 15, skip = 0) =>
        orderService.fetchShipmentId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'status',
      filter: [],
      options: [
        { name: 'Ordered' },
        { name: 'Accepted' },
        { name: 'Fulfilled' },
        { name: 'Shipped' },
        { name: 'In Transit' },
        { name: 'Out for Delivery' },
        { name: 'On Hold' },
        { name: 'Delivered' },
        { name: 'Cancelled' }
      ],
      sort: 0,
      id: 'name',
      displayKey: 'name',
      excludeValues: ['all'],
      loadMoreButton: false,
      searchKey: ''
    }
  ],
  limit: 15,
  populate: ['client_id']
}

function filterReducer(state, action) {
  console.log(action)

  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        columns: state.columns.map((x) => (x.key === action.payload.key ? { ...x, ...action.payload } : x))
      }
    case 'UPDATE_LIMIT':
      return { ...state, limit: action.payload }

    default:
      return state
  }
}

const DashboardScreen = () => {
  const [filterData, dispatch] = useReducer(filterReducer, initialState)

  const [orderData, setOrderData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [statusFilter, setStatusFilter] = useState('')
  const [clientFilter, setClientFilter] = useState(null)
  const clientId = localStorage.getItem('client_id')
  const [stats, setStats] = useState({})
  const [dataCount, setDataCount] = useState(rowsPerPage)

  const fetchOrderData = async () => {
    try {
      const response = await orderService.fetch(
        clientId ? clientId : clientFilter?._id,
        '',
        buildQuery(filterData, page),
        clientId
      )
      setDataCount(response.total)
      setOrderData(response.data)
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Fetch error:', error)
      }
    }
  }

  // const fetchOrderData = async () => {
  //   try {
  //     const response = await orderService.fetch(
  //       clientId ? clientId : clientFilter?._id,
  //       "",
  //       buildQuery({ skip: page * rowsPerPage }, page)
  //       // statusFilter
  //     );
  //     setOrderData(response);
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //   }
  // };

  const fetchStats = async () => {
    try {
      const response = await orderService.fetchStats({ client: clientId })
      setStats(response)
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleFilterChange = (newFilter) => {
    dispatch({ type: 'SET_FILTER', payload: newFilter })
    setPage(0)
  }

  useEffect(() => {
    fetchOrderData()
    fetchStats()
  }, [filterData, statusFilter, clientFilter])

  const handleClientFilterChange = (client) => {
    setClientFilter(client)
  }
  const customScrollbarStyle = {
    height: 'calc(100vh - 100px)',
    overflowX: 'auto',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#273894 #D9D9D9',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: '14px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#273894',
      borderRadius: '14px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#273894'
    }
  }

  return (
    <Box sx={customScrollbarStyle}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: 0,
          height: '100%',
          flexWrap: 'wrap'
        }}
      >
        {/* First row - Responsive grid with specific wrapping behavior */}
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          {stats && stats.weekComparision && (
            <Box
              sx={{
                flex: { xs: '1 1 100%', sm: '1 1 50%', lg: '1 1 33.33%' }, // 100% on XS, 50% on SM, 33.33% on LG
                minWidth: '300px', // Ensures a consistent minimum size
                boxSizing: 'border-box' // Includes padding and borders in the width
              }}
            >
              <GraphCard
                data_obj={stats?.weekComparision || []}
                type={'bar'}
                title={'Current Week vs Last Week'}
              />
            </Box>
          )}

          {stats && stats.currentWeekStatusCounts && (
            <Box
              sx={{
                flex: { xs: '1 1 100%', sm: '1 1 50%', lg: '1 1 33.33%' }, // Matches the first card's behavior
                minWidth: '300px',
                boxSizing: 'border-box'
              }}
            >
              <GraphCard
                data_obj={stats?.currentWeekStatusCounts || []}
                type={'pie'}
                title={'Current Week Orders'}
              />
            </Box>
          )}

          <Box
            sx={{
              flex: { xs: '1 1 100%', sm: '1 1 100%', lg: '1 1 33.33%' }, // Full width on XS and SM, 33.33% on LG
              minWidth: '300px',
              boxSizing: 'border-box'
            }}
          >
            <DataCard
              cardTitle={'Orders'}
              cardCount={`${isNaN(stats.deliveredOrders) ? '__' : stats.deliveredOrders} /${
                stats.totalOrders || '__'
              }`}
            />
          </Box>
        </Grid>

        {/* Second row - Full width cards */}
        <Grid item xs={12}>
          <MonthlyOrderComparision data={stats?.monthComparision} />
        </Grid>

        <Grid item xs={12}>
          <InventoryCard />
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardScreen
