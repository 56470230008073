import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button/Button'
import TextField from '@mui/material/TextField/TextField'
import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import Typography from '@mui/material/Typography/Typography'
import { clientService } from '../../utils/services/clientService'
import { statusTypes } from '../../utils/services/statusTypes'
import AppContext from '../../components/context/context'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { imageService } from '../../utils/services/imageService'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  DialogTitle,
  Divider,
  FormControl,
  Grid
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import { currencyTypes } from '../../utils/services/currencyTypes'
import CustomTypography from '../../globalStyles/fonts'
import { countryService } from '../../utils/services/allCountry'
import SupplierAutocomplete from '../../components/supplierAutocomplete'
import { isMobile } from '../../globalStyles/mobile'
import InputWithUnit from '../../components/Input-with-unit'
import { toast } from 'react-toastify'
import { productService } from '../../utils/services/productService'
import ClientAutocomplete from '../../components/clientAutocomplete'
import config from '../../config'
import { useNavigate } from 'react-router-dom'
import DimensionsAndWeightInputWithUnit from '../../components/dimension-input'
const defaultImage = '/product.png'
const ManageProduct = ({ onClickAdd }) => {
  const initialData = {
    name: '',
    description: '',
    images: [],
    status: '',
    product_type: '',
    variants: [],
    currency: ''
    //vendor: null,
  }
  const [productDetails, setProductDetails] = useState(initialData)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const [selectedImages, setSelectedImages] = useState([])
  const [coverImageIndex, setCoverImageIndex] = useState(-1)
  const [imageUrls, setImageUrls] = useState([])
  const [editingImageIndex, setEditingImageIndex] = useState(null)
  const [variants, setVariants] = useState([])
  const [clientData, setClientData] = useState([])
  const [productId, setProductId] = useState('')
  const { user } = useContext(AppContext)
  const fileInputRef = useRef(null)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState([])
  const [vendor, setVendor] = useState(null)
  const [validated, setValidated] = useState(false)
  const [featureImages, setFeatureImages] = useState([])
  const clientId = localStorage.getItem('client_id')
  const navigate = useNavigate()
  useEffect(() => {
    if (variants?.length === 0) {
      setVariants([
        {
          option: '',
          price: '',
          weight: '',
          weight_unit: '',
          height: '',
          height_unit: '',
          width: '',
          width_unit: '',
          length: '',
          length_unit: '',
          sku: '',
          inventory: 0,
          hs_code: ''
        }
      ])
    }
  }, [setVariants])
  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`
    }
    return defaultImage
  }
  const fetchProductId = async (id) => {
    setLoading(true)
    try {
      const response = await productService.fetchById(id)
      setSelectedProduct(response)
      setLoading(false)
    } catch (error) {
      console.error('Fetch failed:', error)
      toast.error(error)
    }
  }
  useEffect(() => {
    const url = window.location.href
    const parts = url.split('/')
    const lastPart = parts[parts.length - 1]
    setProductId(lastPart)
    if (lastPart === 'create') {
      return
    } else {
      fetchProductId(lastPart)
    }
  }, [productId])
  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    const plainText = tempDiv.textContent || tempDiv.innerText || ''
    return plainText.replace(/\s\s+/g, ' ').trim()
  }
  useEffect(() => {
    if (selectedProduct) {
      setProductDetails({
        name: selectedProduct.name,
        currency: selectedProduct.currency,
        description: selectedProduct.description,
        product_type: selectedProduct.product_type,
        status: selectedProduct.status
      })
      setVariants(selectedProduct.variants)
      setVendor(selectedProduct.vendor)
      setSelectedClient({
        name: selectedProduct?.client_id?.business_name || selectedProduct?.client_id?.name || '',
        _id: selectedProduct.client_id?._id
      })

      const fetchedImages = selectedProduct.images.map(fetchImage)
      setSelectedImages(
        selectedProduct.images.map((img) => ({
          image: img.image,
          feature_image: img.feature_image
        }))
      )
      setImageUrls(fetchedImages)
      const coverIndex = selectedProduct.images.findIndex((img) => img.feature_image)
      setCoverImageIndex(coverIndex)
      setFeatureImages(selectedProduct.images.map((img) => img.feature_image))
    }
  }, [selectedProduct])
  const handleChooseImage = (index = null) => {
    setEditingImageIndex(index)
    fileInputRef.current.click()
  }

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files)
    if (files.length > 0) {
      const file = files[0]
      try {
        const imageData = await imageService.upload(file)
        const imageUrl = URL.createObjectURL(file)
        setSelectedImages((prevImages) => {
          const newImages = [...prevImages]
          if (editingImageIndex !== null) {
            newImages[editingImageIndex] = {
              image: imageData.key,
              feature_image: editingImageIndex === coverImageIndex
            }
          } else {
            newImages.push({
              image: imageData.key,
              feature_image: false
            })
          }
          setImageUrl(newImages)
          return newImages
        })
        setImageUrls((prevUrls) => {
          const newUrls = [...prevUrls]
          if (editingImageIndex !== null) {
            newUrls[editingImageIndex] = imageUrl
          } else {
            newUrls.push(imageUrl)
          }
          return newUrls
        })
        setEditingImageIndex(null)
      } catch (error) {
        console.error('Image upload failed:', error)
      }
    }
  }

  const handleCheckCoverImage = (index) => {
    setCoverImageIndex(index === coverImageIndex ? -1 : index)
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages]
      updatedImages.forEach((image, i) => {
        image.feature_image = i === index
      })
      return updatedImages
    })
  }

  const removeSelectedImage = (indexToRemove) => {
    setSelectedImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove))
    setImageUrls((prevUrls) => prevUrls.filter((_, index) => index !== indexToRemove))

    const updatedImages = [...selectedProduct.images]
    updatedImages.splice(indexToRemove, 1)
    const updatedSelectedProduct = {
      ...selectedProduct,
      images: updatedImages
    }
    setSelectedProduct(updatedSelectedProduct)
    if (coverImageIndex === indexToRemove) {
      setCoverImageIndex(-1)
      setFeatureImages((prevFeatureImages) => {
        const updatedFeatureImages = [...prevFeatureImages]
        updatedFeatureImages[indexToRemove] = false
        return updatedFeatureImages
      })
    }
  }
  const addVariant = () => {
    setVariants((prevState) => [
      ...prevState,
      {
        option: '',
        price: '',
        weight: '',
        weight_unit: '',
        height: '',
        height_unit: '',
        width: '',
        width_unit: '',
        length: '',
        length_unit: '',
        sku: '',
        inventory: 0,
        hs_code: ''
      }
    ])
  }

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants]
    if (updatedVariants[index]) {
      updatedVariants[index][field] = value
      setVariants(updatedVariants)
    }
  }

  const resetSelectedImage = () => {
    setSelectedImages([])
    setImageUrls([])
    setEditingImageIndex()
  }

  const removeVariant = (indexToRemove) => {
    setVariants((prevVariants) => prevVariants.filter((_, index) => index !== indexToRemove))
  }
  const handleChangeDescription = (description) => {
    setDescription(description)
  }
  const fetchClientData = async (name = '') => {
    try {
      const response = await clientService.fetch(null, null, name)
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }
  useEffect(() => {
    fetchClientData()
  }, [])

  const handleVendorChange = (e) => {
    // console.log(e,"directly");
    setVendor(e)
  }
  const validateForm = () => {
    let hasValidVariant = false
    // Check product details
    if (!productDetails.name || !productDetails.currency || !productDetails.status) {
      return false
    }

    // Check variants
    for (let index = 0; index < variants.length; index++) {
      const variant = variants[index]

      const isVariantValid =
        variant.option &&
        variant.price >= 0 &&
        variant.weight_unit &&
        variant.weight >= 0 &&
        variant.height_unit &&
        variant.height >= 0 &&
        variant.width_unit &&
        variant.width >= 0 &&
        variant.length_unit &&
        variant.length >= 0 &&
        variant.sku &&
        variant.hs_code &&
        typeof variant.hs_code === 'number' &&
        variant.hs_code.toString().length >= 8 &&
        variant.hs_code.toString().length <= 10

      if (isVariantValid) {
        hasValidVariant = true
        break // Exit loop if a valid variant is found
      }
    }

    // Return false if no valid variant is found
    if (!hasValidVariant) {
      return false
    }
    if (clientId) {
      if (!vendor?._id) {
        return false
      }
    } else {
      if (!selectedClient?.name || !vendor?._id) {
        // Check client and vendor
        return false
      }
    }

    // If all checks passed, return true
    return true
  }
  useEffect(() => {
    setValidated(validateForm())
  }, [productDetails, variants])
  const createProduct = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      setLoading(true)
      const client_id = clientId ? clientId : selectedClient?._id
      await productService.create({
        ...productDetails,
        client_id: client_id,
        variants: variants,
        images: imageUrl,
        vendor: vendor._id
      })
      toast.success('Product Created successfully!')
      setImageUrl([])
      setVariants([])
      setProductDetails(initialData)
      setVendor(null)
      setSelectedClient({ name: '' })
      localStorage.setItem('path', `/product`)
      navigate('/product')
    } catch (error) {
      console.error('Create failed:', error)
    } finally {
      setLoading(false)
    }
  }
  const handleEditProduct = async (e) => {
    e.preventDefault()
    if (!validateForm()) {
      toast.warning('Please fill all the fields')
      return
    }

    try {
      setLoading(true)
      const client_id = clientId ? clientId : selectedClient?._id

      const imageField =
        selectedImages.length > 0
          ? selectedImages.map((image) => ({
              image: image.image,
              feature_image: image.feature_image
            }))
          : selectedProduct.images

      const response = await productService.edit(
        {
          ...productDetails,
          images: imageField,
          variants: variants,
          vendor: vendor?._id,
          client_id: client_id
        },
        selectedProduct._id
      )

      toast.success('Product Edited successfully!')
      setImageUrl([])
      setVariants([])
      setProductDetails(initialData)
      setVendor(null)
      setSelectedClient({ name: '' })
      localStorage.setItem('path', `/product`)
      navigate('/product')
    } catch (error) {
      console.error('Edit failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const onChangeDimensionAndWight = (index, field, value) => {
    if (['weight', 'height', 'width', 'length', 'weight_unit'].includes(field)) {
      handleVariantChange(index, field, value)
    } else if (field === 'dimension_unit') {
      const updatedVariants = [...variants]
      if (updatedVariants[index]) {
        updatedVariants[index]['height_unit'] = value
        updatedVariants[index]['width_unit'] = value
        updatedVariants[index]['length_unit'] = value

        setVariants(updatedVariants)
      }
    }
  }
  console.log(selectedProduct)
  console.log(productDetails)
  return (
    <Box>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#000000'
          }}
        >
          <CustomTypography fontWeight={500} fontSize={20}>
            {`${productId === 'create' ? 'Create' : 'Edit'} Product`}
          </CustomTypography>
        </DialogTitle>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <div
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
          padding: 5
        }}
        sx={{
          overflowY: 'auto',
          '&::WebkitScrollbar': {
            width: '7px'
          },
          '&::WebkitScrollbar-track': {
            backgroundColor: '#D9D9D9',
            borderRadius: 14
          },
          '&::WebkitScrollbar-thumb': {
            backgroundColor: '#273894',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: '#555'
            }
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Name <span style={{ color: 'red' }}>*</span>
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1, fontSize: 13, fontStyle: 'Poppins' }}
              size="small"
              placeholder={`Enter Product Name`}
              //label="Name"
              variant="outlined"
              value={productDetails.name}
              onChange={(e) => setProductDetails({ ...productDetails, name: e.target.value })}
              fullWidth
              autoFocus
              //   error={!!formErrors.name}
              //   helperText={formErrors.name}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Category
            </CustomTypography>
            <TextField
              sx={{
                input: {
                  color: 'black', // Default text color
                  backgroundColor: 'transparent', // Default background color
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px transparent inset', // Removes yellow autofill background
                    WebkitTextFillColor: 'black' // Autofill text color
                  }
                }
              }}
              InputProps={{
                sx: {
                  color: 'black', // Default input text color when autofill is not active
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px transparent inset', // Same here for InputProps if needed
                    WebkitTextFillColor: 'black', // Text color for autofilled content
                    caretColor: 'black' // Caret color
                  }
                }
              }}
              value={productDetails.product_type}
              size="small"
              margin="dense"
              placeholder=" Enter Type"
              type="text"
              fullWidth
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  product_type: e.target.value
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Description
            </CustomTypography>
            <ReactQuill
              theme="snow"
              value={productDetails.description}
              onChange={(e) => setProductDetails({ ...productDetails, description: e })}
              placeholder="Enter product description"
              style={{
                height: isMobile() ? 70 : '100px',
                marginBottom: '50px',
                marginTop: 5,
                borderRadius: 8
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: isMobile() ? 5 : 0 }}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Media
            </CustomTypography>
            <Box
              sx={{
                width: '100%',
                border: 1,
                borderColor: '#D1D1D1',
                borderRadius: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 100,
                mt: { xs: 2, sm: 1 },
                p: 2
              }}
            >
              <input
                id="productImageInput"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                multiple
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />

              {selectedImages.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      gap: 2,
                      mt: 2,
                      maxHeight: 250,
                      overflowY: 'auto',
                      border: '1px solid #000',
                      borderRadius: 1,
                      p: 2
                    }}
                  >
                    {selectedImages?.map((image, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          p: 1,
                          border: 1,
                          borderRadius: 1,
                          maxWidth: { xs: '100%', sm: 'auto' }
                        }}
                      >
                        <img
                          src={imageUrls[index]}
                          alt={`Selected image ${index}`}
                          style={{
                            maxWidth: 100,
                            maxHeight: 100
                          }}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          <IconButton onClick={() => handleChooseImage(index)}>
                            <EditIcon color="primary" />
                          </IconButton>
                          <input
                            type="checkbox"
                            checked={index === coverImageIndex}
                            onChange={() => handleCheckCoverImage(index)}
                          />
                          <IconButton onClick={() => removeSelectedImage(index)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <IconButton onClick={() => handleChooseImage(null)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  sx={{
                    minWidth: { xs: 150, sm: 200 },
                    color: '#707070',
                    textTransform: 'none'
                  }}
                  color="primary"
                  onClick={() => handleChooseImage(null)}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <IconButton aria-label="upload" sx={{ mb: 1, p: 0 }}>
                      <InsertPhotoIcon />
                    </IconButton>
                    <Typography variant="body2">Upload</Typography>
                  </Box>
                </Button>
              )}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Currency <span style={{ color: 'red' }}>*</span>
            </CustomTypography>
            <Autocomplete
              value={productDetails.currency}
              // sx={{ marginTop: 0.5 }}
              onChange={(e, newValue) => setProductDetails({ ...productDetails, currency: newValue })}
              size="small"
              options={currencyTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //   label="$ 00.00"
                  variant="outlined"
                  // error={formErrors?.currency}
                  // helperText={formErrors?.currency}
                  margin="dense"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Status <span style={{ color: 'red' }}>*</span>
            </CustomTypography>
            <Autocomplete
              value={productDetails.status}
              // sx={{ marginTop: 0.5 }}
              onChange={(e, newValue) => setProductDetails({ ...productDetails, status: newValue })}
              size="small"
              options={statusTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  //   label="Status"
                  variant="outlined"
                  // error={formErrors?.status}
                  // helperText={formErrors?.status}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
              Supplier <span style={{ color: 'red' }}>*</span>
            </CustomTypography>
            <SupplierAutocomplete
              hideLabel
              selectedSupplier={vendor}
              onSupplierChange={handleVendorChange}
              width="100%"
              label="Select Supplier"
            />
            {/* {formErrors.vendor && <FormHelperText error>{formErrors.vendor}</FormHelperText>} */}
          </Grid>
          {clientId ? (
            <></>
          ) : (
            <Grid item xs={6} sm={6}>
              <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={15}>
                Select Client <span style={{ color: 'red' }}>*</span>
              </CustomTypography>
              <ClientAutocomplete
                hideLabel
                selectedClient={selectedClient}
                onClientChange={(e) => {
                  setSelectedClient(e)
                }}
                width={'100%'}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <CustomTypography fontWeight={500} fontSize={16}>
              Variants
            </CustomTypography>
            {variants?.map((variant, index) => (
              <Card
                key={index}
                style={{
                  marginBottom: 10,
                  backgroundColor: '#EEEEEEEE',
                  marginTop: 10
                }}
              >
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="delete"
                      onClick={() => removeVariant(index)}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton>
                  </div>
                  <Grid container spacing={2.5}>
                    <Grid item xs={12} sm={3}>
                      <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={14}>
                        Option
                      </CustomTypography>
                      <FormControl fullWidth>
                        <TextField
                          sx={{
                            marginTop: 0.5,
                            backgroundColor: '#FFFFFF'
                          }}
                          type="size"
                          size="small"
                          placeholder="Option"
                          variant="outlined"
                          value={variant.option}
                          onChange={(e) => handleVariantChange(index, 'option', e.target.value)}
                          fullWidth
                          // autoFocus
                          // error={!!formErrors[`option-${index}`]}
                        />
                        {/* {formErrors[`option-${index}`] && (
                              <FormHelperText error>{formErrors[`option-${index}`]}</FormHelperText>
                            )} */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={14}>
                        {'Price (USD)'} <span style={{ color: 'red' }}>*</span>
                      </CustomTypography>
                      <FormControl fullWidth>
                        <TextField
                          sx={{
                            marginTop: 0.5,
                            backgroundColor: '#FFFFFF'
                          }}
                          type="number"
                          size="small"
                          placeholder="Enter Price"
                          value={variant.price}
                          onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                          inputProps={{ min: 0 }}
                          fullWidth
                          // error={!!formErrors[`price-${index}`]}
                        />
                        {/* {formErrors[`price-${index}`] && (
                              <FormHelperText error>{formErrors[`price-${index}`]}</FormHelperText>
                            )} */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={14}>
                        SKU <span style={{ color: 'red' }}>*</span>
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: '#FFFFFF' }}
                        type="text"
                        size="small"
                        variant="outlined"
                        placeholder="Enter SKU"
                        value={variant.sku}
                        onChange={(e) => handleVariantChange(index, 'sku', e.target.value)}
                        fullWidth
                        // error={!!formErrors[`sku-${index}`]}
                      />
                      {/* {formErrors[`sku-${index}`] && (
                            <FormHelperText error>{formErrors[`sku-${index}`]}</FormHelperText>
                          )} */}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={14}>
                        HS Code
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: '#FFFFFF' }}
                        // type="number"
                        size="small"
                        variant="outlined"
                        placeholder="Enter HS Code"
                        value={variant.hs_code}
                        onChange={(e) => handleVariantChange(index, 'hs_code', e.target.value)}
                        fullWidth
                        // error={!!formErrors[`inventory-${index}`]}
                      />
                      {/* {formErrors[`inventory-${index}`] && (
                        <FormHelperText error>{formErrors[`inventory-${index}`]}</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <CustomTypography style={{ textAlign: 'left', fontWeight: '500' }} fontSize={14}>
                        Inventory
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: '#FFFFFF' }}
                        type="number"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Inventory"
                        value={variant.inventory}
                        onChange={(e) => handleVariantChange(index, 'inventory', e.target.value)}
                        fullWidth
                        // error={!!formErrors[`inventory-${index}`]}
                      />
                      {/* {formErrors[`inventory-${index}`] && (
                        <FormHelperText error>{formErrors[`inventory-${index}`]}</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <DimensionsAndWeightInputWithUnit
                        dimensions={{
                          height: variant.height,
                          length: variant.length,
                          width: variant.width
                        }}
                        weight={variant.weight}
                        onChange={(e) => onChangeDimensionAndWight(index, e.field, e.value)}
                        dimensionUnitValue={variant.height_unit}
                        weightUnitValue={variant.weight_unit}
                        // weightUnitError={formErrors[`weight-${index}`]}
                        // dimensionUnitError={formErrors[`dimension-${index}`]}
                        requiredField={true}
                      ></DimensionsAndWeightInputWithUnit>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        numberType
                        unitValue={variant.weight_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "weight_unit", e)
                        }
                        inputValue={variant.weight}
                        onChangeText={(e) =>
                          handleVariantChange(index, "weight", e)
                        }
                        unitType={"weight"}
                        fieldName={"Weight"}
                        // errorMessage={formErrors[`weight-${index}`]}
                        // unitError={formErrors[`weight_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid>  <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        unitValue={variant.height_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "height_unit", e)
                        }
                        inputValue={variant.height}
                        onChangeText={(e) =>
                          handleVariantChange(index, "height", e)
                        }
                        unitType={"distance"}
                        fieldName={"Height"}
                        numberType
                        // errorMessage={formErrors[`height-${index}`]}
                        // unitError={formErrors[`height_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        unitValue={variant.width_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "width_unit", e)
                        }
                        inputValue={variant.width}
                        onChangeText={(e) =>
                          handleVariantChange(index, "width", e)
                        }
                        unitType={"distance"}
                        fieldName={"Width"}
                        numberType
                        // errorMessage={formErrors[`width-${index}`]}
                        // unitError={formErrors[`width_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        unitValue={variant.length_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "length_unit", e)
                        }
                        inputValue={variant.length}
                        onChangeText={(e) =>
                          handleVariantChange(index, "length", e)
                        }
                        unitType={"distance"}
                        fieldName={"Length"}
                        numberType
                        // errorMessage={formErrors[`length-${index}`]}
                        // unitError={formErrors[`length_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            ))}
            <Button onClick={addVariant} style={{ textTransform: 'none', color: '#0038FF' }}>
              <AddIcon fontSize="small" />
              <Typography style={{ marginLeft: 8 }}>Add more Variants</Typography>
            </Button>
          </Grid>
        </Grid>
        {isMobile() ? (
          <Box
            style={{
              padding: 5,
              display: 'flex',
              justifyContent: 'end',
              // marginRight: 15,
              boxShadow: '0px -4px 6px -2px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedClient({})
                resetSelectedImage()
                navigate('/product')
              }}
              className="cancel-button-style"
              style={{ textTransform: 'none' }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color={validated ? 'primary' : 'grey'} // Changes color based on validation
              onClick={productId === 'create' ? createProduct : handleEditProduct}
              className="add-button-style"
              style={{ textTransform: 'none', marginLeft: 10 }}
              disabled={!validated} // Disables the button if not validated
            >
              {selectedProduct ? 'Save' : 'Add'}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </div>
      {!isMobile() ? (
        <Box
          style={{
            padding: 5,
            display: 'flex',
            justifyContent: 'end',
            marginRight: 15,
            boxShadow: '0px -4px 6px -2px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedClient({})
              resetSelectedImage()
              navigate('/product')
            }}
            className="cancel-button-style"
            style={{ textTransform: 'none' }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color={validated ? 'primary' : 'grey'} // Changes color based on validation
            onClick={productId === 'create' ? createProduct : handleEditProduct}
            className="add-button-style"
            style={{ textTransform: 'none', marginLeft: 10 }}
            disabled={!validated} // Disables the button if not validated
          >
            {selectedProduct ? 'Save' : 'Add'}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default ManageProduct
