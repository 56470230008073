import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AppContext from "../context/context";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import Pagination from "../pagination";
import { providerService } from "../../utils/services/providerService";
import ProviderDetailsModal from "../providerDetailsModal";

const ProviderList = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedProvider,
  setSelectedProvider,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [providerData, setProviderData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { user } = useContext(AppContext);
  const [openViewModal, setOpenViewModal] = useState(false);

  const deleteData = async () => {
    try {
      await providerService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Provider deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete");
      console.error("Delete failed:", error);
    }
  };
  const handleViewClick = (provider) => {
    setSelectedProvider(provider);
    setOpenViewModal(true);
  };

  useEffect(() => {
    setProviderData(
      data?.map((item) => ({
        ...item,
        action: (
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <IconButton
              style={{
                color: "#007DFF",
                padding: 0,
                background: "#C3E1FF",
                height: 30,
                width: 30,
                borderRadius: 5,
                // marginLeft: 10,
                boxShadow: "0px 0px 4px 0px #00000040",
              }}
              aria-label="view"
              onClick={() => {
                handleViewClick(item);
              }}
            >
              <img
                style={{ height: 14, width: 20 }}
                alt="view"
                src="/eye.png"
              ></img>
            </IconButton>

            {user.role == "admin" ? (
              <>
                <IconButton
                  onClick={() => onEditData && onEditData(item)}
                  style={{
                    color: "#FEC50F",
                    padding: 0,
                    background: "#FEC50F33",
                    height: 30,
                    width: 30,
                    borderRadius: 5,
                    // marginLeft: 15,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                >
                  <BorderColorIcon sx={{ height: 20, width: 20 }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSelectedProvider(item);
                    setDeletingItemId(item._id);
                    setOpenConfirmation(true);
                  }}
                  style={{
                    color: "#D13438",
                    padding: 0,
                    background: "#D1343833",
                    height: 30,
                    width: 30,
                    borderRadius: 5,
                    // marginLeft: 10,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                >
                  <img
                    style={{ height: 15, width: 15 }}
                    alt="delete"
                    src="/delete.png"
                  ></img>
                </IconButton>
              </>
            ) : (
              <></>
            )}
          </Box>
        ),
      }))
    );
  }, [data]);

  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {providerData?.map((x) => (
        <Grid item xs={12} sm={6} md={3} key={x._id}>
          <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    // marginLeft: 15,
                  }}
                >
                  <Tooltip title={x?.name} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={x?.type} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.type}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {x.action}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {providerData.length === 0 ? (
          <NoDataImage />
        ) : (
          providerData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.type}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            marginTop: 1,
            maxHeight: "70vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 5px",
              paddingBottom: 7,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: 8,
                  boxShadow: "0px 0px 4px 0px #00000040",
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: 8,
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Name
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: 8,
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Type
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerData?.map((x, index) => (
                <React.Fragment>
                  <TableRow
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "center",
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 4,
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.name}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "center",
                        padding: 4,
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.type}
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "center",
                        padding: 4,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                      }}
                    >
                      {x.action}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>

        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedProvider?.name} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <ProviderDetailsModal
          provider={selectedProvider}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
};

export default ProviderList;
