import React, { useEffect, useReducer, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { supplierService } from "../../utils/services/supplierService";
import { CircularProgress, Box } from "@mui/material";
import SupplierList from "../../components/supplier-list";
import CustomPagination from "../../components/pagination";
import SupplierModal from "../../components/supplierModal";
import buildQuery from "../../utils/query-builder";

const initialState = {
  columns: [
    {
      key: "name",
      name: "Name",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetchName(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "email",
      name: "Email",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetchEmail(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "address.city",
      name: "City",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetchCity(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "address.province",
      name: "Province",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetchProvince(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "address.country",
      name: "Country",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetchCountry(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
  ],
  limit: 15,
  populate: ["client"],
};
function filterReducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state?.columns.map((x) =>
          x?.key === action?.payload?.key ? { ...x, ...action.payload } : x
        ),
      };
    case "SET_OPTIONS":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key === action.payload.key
            ? { ...col, options: action.payload.options }
            : col
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };
    default:
      return state;
  }
}
export default function Supplier() {
  const [filterData, dispatch] = useReducer(filterReducer, initialState);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const [viewMode, setViewMode] = useState("list");

  const handleClientFilterChange = (event, newValue) => {
    setClientFilter(newValue);
    setPage(0);
  };

  const fetchSupplierData = async () => {
    setLoading(true);
    try {
      const response = await supplierService.fetch(
        searchValue,
        clientId ? clientId : clientFilter?._id,
        buildQuery(filterData, page)
      );
      setSuppliers(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSupplierData();
  }, [page, filterData, searchValue, clientFilter]);

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_carrier.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );
  return (
    <>
      <DashboardContainer
        onChangeFilter={handleFilterChange}
        filterData={filterData}
        fields={filterData.columns}
        hideFilterClient
        onSearchData={(x) => {
          setSearchValue(x);
          setPage(0);
        }}
        onClickAddButton={() => setOpenModal(true)}
        hideUploadButton={true}
        header={"Supplier List"}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        setViewMode={setViewMode}
        title={"+ Add Supplier"}
      >
        <SupplierModal
          selectedSupplier={selectedSupplier}
          handleClose={() => {
            setOpenModal(false);
            setSelectedSupplier(null);
          }}
          open={openModal}
          onAddData={fetchSupplierData}
        />
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit"></CircularProgress>
          </Box>
        )}
        {suppliers?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoDataImage />
          </div>
        ) : (
          <>
            <SupplierList
              onDeleteData={() => fetchSupplierData()}
              onEditData={(e) => {
                setSelectedSupplier(e);
                setOpenModal(true);
              }}
              data={suppliers}
              viewMode={viewMode}
              deleteTitle={"Delete Supplier"}
              selectedSupplier={selectedSupplier}
              setSelectedSupplier={setSelectedSupplier}
              onChangeFilter={handleFilterChange}
              filterData={filterData}
              currentPage={page}
              itemsPerPage={rowsPerPage}
            ></SupplierList>
          </>
        )}

        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={filterData.limit}
          page={page}
          setPage={setPage}
          setRowsPerPage={(e) =>
            dispatch({
              type: "UPDATE_LIMIT",
              payload: e,
            })
          }
        />
      </DashboardContainer>
    </>
  );
}
