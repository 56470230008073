import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Autocomplete,
  InputLabel,
  Tooltip,
  InputBase,
  Box
} from '@mui/material'
import { Close as CloseIcon, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import debounce from 'lodash.debounce'
import { supplierService } from '../../utils/services/supplierService'
import { wmsService } from '../../utils/services/wmsProvider'
import { productService } from '../../utils/services/productService'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CustomTypography from '../../globalStyles/fonts'
import config from '../../config'
import axios from 'axios'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

const CreateASN = ({ openDialog, handleCloseDialog, stockData }) => {
  const clientId = localStorage.getItem('client_id')

  const [wmsProviders, setWMSProviders] = useState([])
  const [selectedWMSProvider, setSelectedWMSProvider] = useState(null)
  const [stockinNumber, setStockInNumber] = useState('')
  const [expectedDate, setExpectedDate] = useState(null)

  useEffect(() => {
    if (clientId) fetchWMSProviders()
  }, [clientId])

  const fetchWMSProviders = async () => {
    try {
      const res = await wmsService.fetch(clientId)
      setWMSProviders(res.data)
    } catch (e) {
      console.error(e)
    }
  }

  const initalState = [
    {
      ...stockData,
      variant: null,
      PKG: '',
      InnerPKG: '',
      Unit: '',
      InnerUnit: ''
    }
  ]
  const [rows, setRows] = useState(initalState)

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        ...stockData,
        variant: null,
        PKG: '',
        InnerPKG: '',
        Unit: '',
        InnerUnit: ''
      }
    ])
  }

  const handleAddOrUpdateStock = async () => {
    try {
      await axios.post(`${config.warehouseBaseURL}/stock-in`, {
        client_id: clientId,
        stockData: {
          stockInData: rows.map((x) => ({
            PKG: x.PKG,
            InnerPKG: x.InnerPKG,
            Unit: x.Unit,
            InnerUnit: x.InnerUnit,
            ItemNumber: x.variant.variants.sku,
            product: x.variant._id,
            Description: x.variant.description,
            UnitCurrency: x.variant.currency || 'USD',
            UnitPrice: x.variant.variants.price,
            client_id: clientId
          })),
          stockInNo: stockinNumber,
          expectedDeliveryDate: expectedDate
        },
        provider: selectedWMSProvider.provider
      })
      toast.success('ASN created successfully')
      handleClose()
    } catch (e) {
      console.error(e)
      toast.error('Failed to add stock')
    }
  }

  const handleRowChange = (index, name, value) => {
    const newRows = [...rows]
    newRows[index][name] = value
    setRows(newRows)
  }

  const handleRemoveRow = (index) => {
    const newRows = [...rows]
    newRows.splice(index, 1)
    setRows(newRows)
  }
  const datePickerRef = useRef(null)
  const [skuList, setSKUList] = useState([])
  const [skuSearchValue, setskuSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)

  const calendarRef = useRef(null)

  const fetchSKU = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true)
      try {
        const response = await productService.fetchSKU(clientId, searchQuery, 10, pageNumber)
        if (pageNumber === 0) {
          setSKUList(response.data)
        } else {
          setSKUList((prevSuppliers) => [...prevSuppliers, ...response.data])
        }
        setTotal(response.data.length || 0)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [clientId]
  )
  const toggleCalendar = () => setShowCalendar((prev) => !prev)

  useEffect(() => {
    fetchSKU(skuSearchValue, page)
  }, [skuSearchValue, page, fetchSKU])
  useEffect(() => {
    if (skuSearchValue === '') {
      fetchSKU('', 0)
    }
  }, [skuSearchValue, fetchSKU])

  const handleSupplierChange = (index, value) => {
    handleRowChange(index, 'variant', value)
    setskuSearchValue('')
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setskuSearchValue(value)
    setPage(0)
    setSKUList([])
  }
  const autocompleteRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false)
      }
    }

    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [showCalendar])
  const handleClose = () => {
    setSelectedWMSProvider(null)
    setStockInNumber('')
    setExpectedDate(null)
    setRows(initalState)
    handleCloseDialog && handleCloseDialog()
  }
  const handleLoadMoreClick = (event, value, index) => {
    if (value?.loadMore) {
      if (total == 10 && !loading) {
        setPage((prevPage) => prevPage + 1)
        autocompleteRef.current.focus()
      }
    } else {
      return handleSupplierChange(index, value)
    }
  }

  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        Create ASN
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="flex-end">
          <Grid item xs={4} sm={3} md={2}>
            <TextField
              style={{ maxWidth: 200 }}
              size="small"
              name="StockIn Number"
              label="StockIn Number"
              value={stockinNumber}
              onChange={(e) => setStockInNumber(e.target.value)}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid style={{ marginLeft: 5, marginRight: 5 }} item xs={4} sm={3} md={3}>
            <Autocomplete
              style={{ maxWidth: 300 }}
              size="small"
              options={wmsProviders}
              getOptionLabel={(option) => option.provider || ''}
              value={selectedWMSProvider}
              onChange={(event, newValue) => setSelectedWMSProvider(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select WMS Provider"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <TextField
              style={{ maxWidth: 200 }}
              size="small"
              name="Expected Arrival Date"
              label="Expected Arrival Date"
              value={
                expectedDate
                  ? dayjs(expectedDate).format('YYYY-MM-DD')
                  : new Date().toISOString().split('T')[0]
              }
              onFocus={toggleCalendar}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
            />
          </Grid>

          {showCalendar && (
            <div
              ref={calendarRef}
              style={{
                position: 'fixed', // change from 'absolute' to 'fixed' for a more stable position
                top: '50%', // adjust to position relative to the viewport center
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 3000, // increased z-index to ensure it displays above the modal
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: '8px',
                padding: '0.5rem'
              }}
            >
              <Calendar
                value={new Date(expectedDate)}
                onChange={(e) => {
                  setExpectedDate(dayjs(e).format('YYYY-MM-DD'))
                  setShowCalendar(false)
                }}
                minDate={new Date()}
                tileClassName="custom-calendar-tile"
                className="custom-calendar"
              />
            </div>
          )}
        </Grid>
        {rows.map((row, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{
              marginTop: 1,
              border: '1px solid #d9dbdc',
              padding: 1,
              borderRadius: 2,
              paddingLeft: 0,
              paddingRight: 2,
              paddingTop: 0,
              paddingBottom: 2
            }}
            key={`${row.variant}+ ${index}`}
          >
            <Grid item xs={5.5} sx={{ maxWidth: 280 }}>
              <Autocomplete
                ref={autocompleteRef}
                disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                size="small"
                options={[...skuList, { label: 'Load More...', loadMore: true }]}
                getOptionLabel={(option) =>
                  option.loadMore
                    ? ''
                    : `${option?.variants?.sku || ''} ${option?.name ? `(${option.name})` : ''}`
                }
                value={row.variant || null}
                onChange={(event, value) => {
                  handleLoadMoreClick(event, value, index)

                  // If Load More... is clicked, reopen the dropdown
                  if (value && value.loadMore) {
                    setTimeout(() => {
                      autocompleteRef.current.focus() // Refocus to open dropdown
                    }, 0)
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.loadMore && total === 10 ? (
                      <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Load More...</span>
                    ) : loading ? (
                      <CircularProgress sx={{ color: '#151D48' }} size={30} />
                    ) : (
                      <Tooltip title={option.name || ''}>
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'
                          }}
                        >
                          {`${option?.variants?.sku || ''} ${option?.name ? `(${option.name})` : ''}`}
                        </span>
                      </Tooltip>
                    )}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Item Number"
                    variant="outlined"
                    onChange={handleSearchChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    margin="dense"
                  />
                )}
              />
            </Grid>

            <Grid item xs={1} sx={{ minWidth: 130 }}>
              <TextField
                disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                size="small"
                name="PKG"
                label="PKG"
                value={row.PKG}
                onChange={(e) => handleRowChange(index, 'PKG', e.target.value)}
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={1} sx={{ minWidth: 130 }}>
              <FormControl
                disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                size="small"
                fullWidth
                margin="dense"
              >
                <InputLabel>Unit</InputLabel>
                <Select
                  label="Unit"
                  value={row.Unit}
                  onChange={(e) => handleRowChange(index, 'Unit', e.target.value)}
                >
                  {['CTN', 'PKG'].map((x) => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sx={{ minWidth: 120 }}>
              <TextField
                disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                size="small"
                name="InnerPKG"
                label="Inner PKG"
                value={row.InnerPKG}
                onChange={(e) => handleRowChange(index, 'InnerPKG', e.target.value)}
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={1} sx={{ minWidth: 130 }}>
              <FormControl
                disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                size="small"
                fullWidth
                margin="dense"
              >
                <InputLabel>Inner Unit</InputLabel>
                <Select
                  label="Inner Unit"
                  value={row.InnerUnit}
                  onChange={(e) => handleRowChange(index, 'InnerUnit', e.target.value)}
                  size="small"
                >
                  {['PCS', 'BOX'].map((x) => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', maxWidth: 200 }}>
              {rows?.length > 1 ? (
                <IconButton color="secondary" onClick={() => handleRemoveRow(index)} size="large">
                  <DeleteIcon sx={{ color: 'red' }} />
                </IconButton>
              ) : null}
              {index === rows.length - 1 && (
                <IconButton color="primary" onClick={handleAddRow} size="large">
                  <AddIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleAddOrUpdateStock(rows)} variant="contained" color="primary">
          {'Add Stock'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateASN
