export default function createLastSevenDaysLabels() {
  const labels = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(today.getDate() - i);

    // Format the date as "YYYY-MM-DD"
    const label = date.toISOString().split("T")[0];
    labels.push(label);
  }

  return labels.reverse(); // Reverse to get the order from oldest to newest
}

export const changeDateFormat = (data) => {
  const dateStr = data;
  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export function fetchColorByStatus(status, key) {
  let status_color = [
    { status: "Ordered", background_color: "#007bff", borderColor: "#0056b3" },
    { status: "Accepted", background_color: "#6f42c1", borderColor: "#5a31a1" },
    {
      status: "Fulfilled",
      background_color: "#28a745",
      borderColor: "#1e7e34",
    },
    { status: "Shipped", background_color: "#17a2b8", borderColor: "#117a8b" },
    {
      status: "In Transit",
      background_color: "#ffc107",
      borderColor: "#d39e00",
    },
    {
      status: "Out for Delivery",
      background_color: "#fd7e14",
      borderColor: "#e46014",
    },
    { status: "On Hold", background_color: "#dc3545", borderColor: "#c82333" },
    {
      status: "Delivered",
      background_color: "#20c997",
      borderColor: "#198754",
    },
    {
      status: "Cancelled",
      background_color: "#6c757d",
      borderColor: "#5a6268",
    },
  ];
  return status_color.find((x) => x.status === status)[key];
}

export const generateColor = () => {
  let color = "#";
  let digits = "0123456789ABCDEF";
  for (let i = 0; i < 6; i++) {
    let randomDigit = Math.floor(Math.random() * 16);
    color += digits[randomDigit];
  }

  return color;
};

export const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};
