import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  Box
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { QueryBuilder, defaultOperators, defaultCombinators } from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.css'
import './styles.css'
import { fields } from './fields'

const AddEditRules = ({ open, onClose, initialData }) => {
  const [event, setEvent] = useState('') // Single event
  const [rules, setRules] = useState([]) // Array of rules

  const eventsList = ['Create Order', 'Update Order', 'Delete Order'] // Main events
  const subEventsList = ['Event 1', 'Event 2', 'Event 3', 'Event 4'] // Sub-events for rules

  // Load initial data for editing
  useEffect(() => {
    if (initialData) {
      setEvent(initialData.event?.name || '')
      setRules(initialData.event?.rules || [])
    }
  }, [initialData])

  const handleEventChange = (e) => {
    setEvent(e.target.value)
    setRules([]) // Clear rules when the main event changes
  }

  const handleAddRule = () => {
    setRules([
      ...rules,
      {
        id: Date.now(),
        name: `Rule ${rules.length + 1}`,
        query: { combinator: 'and', rules: [] }, // Default query structure
        events: []
      }
    ])
  }

  const handleRemoveRule = (id) => {
    setRules(rules.filter((rule) => rule.id !== id))
  }

  const handleQueryChange = (id, query) => {
    setRules(
      rules.map((rule) =>
        rule.id === id
          ? {
              ...rule,
              query
            }
          : rule
      )
    )
  }

  const handleSubEventsChange = (id, selectedSubEvents) => {
    setRules(
      rules.map((rule) =>
        rule.id === id ? { ...rule, events: selectedSubEvents.map((subEvent) => ({ name: subEvent })) } : rule
      )
    )
  }

  const handleSave = () => {
    const data = {
      event: {
        name: event,
        rules: rules.map(({ id, ...rest }) => rest) // Exclude the internal ID
      }
    }
    console.log('Saved Data:', data)
    onClose(data) // Pass the updated data back
  }

  const handleClose = () => {
    setEvent('') // Reset event
    setRules([]) // Reset rules
    onClose(null)
  }

  return (
    <Dialog sx={{ minWidth: 500 }} maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{initialData ? 'Edit Event Rules' : 'Add Event Rules'}</DialogTitle>
      <DialogContent sx={{ minWidth: 500 }}>
        {/* Event Selector */}
        <TextField
          size="small"
          select
          fullWidth
          margin="normal"
          label="Select Event"
          value={event}
          onChange={handleEventChange}
        >
          {eventsList.map((ev) => (
            <MenuItem key={ev} value={ev}>
              {ev}
            </MenuItem>
          ))}
        </TextField>

        {/* Rules */}
        {event && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Rules
            </Typography>
            {rules.map((rule) => (
              <Box
                sx={{ minWidth: '50vw' }}
                key={rule.id}
                marginBottom={3}
                padding={2}
                border="1px solid #C8C8C8	"
                borderRadius={2}
              >
                {/* Query Builder for Rule */}
                <Typography variant="subtitle1">{rule.name}</Typography>
                <QueryBuilder
                  fields={fields}
                  query={rule.query}
                  onQueryChange={(query) => handleQueryChange(rule.id, query)}
                  combinators={defaultCombinators}
                  operators={defaultOperators}
                  controlClassnames={{
                    queryBuilder: 'custom-querybuilder',
                    ruleGroup: 'custom-rulegroup',
                    combinators: 'custom-combinators',
                    addRule: 'custom-addRule',
                    addGroup: 'custom-addGroup',
                    removeGroup: 'custom-removeGroup',
                    rule: 'custom-rule',
                    fields: 'custom-fields',
                    operators: 'custom-operators',
                    value: 'custom-value',
                    removeRule: 'custom-removeRule'
                  }}
                />
                <Box display="flex" gap={2} marginTop={2}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    multiple
                    label={`Sub-events for ${rule.name}`}
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(', ')
                    }}
                    value={rule.events.map((subEvent) => subEvent.name)}
                    onChange={(e) => handleSubEventsChange(rule.id, e.target.value)}
                  >
                    {subEventsList.map((subEvent) => (
                      <MenuItem key={subEvent} value={subEvent}>
                        {subEvent}
                      </MenuItem>
                    ))}
                  </TextField>
                  <IconButton color="error" onClick={() => handleRemoveRule(rule.id)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Button startIcon={<AddCircleIcon />} onClick={handleAddRule} variant="outlined">
              Add Rule
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: 'red' }} onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditRules
