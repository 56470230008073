import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";

const MonthlyOrderComparision = ({ data }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOption, setChartOption] = useState(null);

  useEffect(() => {
    if (data && data.length) {
      setChartOption({
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            title: {
              display: false,
              text: "Count",
            },
          },
          x: {
            grid: {
              display: false,
            },
            title: {
              display: false,
              text: "Status",
            },
          },
        },
      });
      setChartData({
        labels: data.map((x) => x.status),
        datasets: [
          {
            label: "Last Month",
            data: data.map((x) => x.lastMonth),
            backgroundColor: "#156082",
            borderColor: "#156082",
            borderWidth: 1,
          },
          {
            label: "Current Month",
            data: data.map((x) => x.currentMonth),
            backgroundColor: "#E97132",
            borderColor: "#E97132",
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data]);

  return (
    <Card
      sx={{
        minWidth: "100%",
        // minHeight: height || 250,
        padding: 1,
        backgroundColor: "#c9d6e4",
        borderRadius: "16px",
        position: "relative",
        // marginRight: 3,
        minHeight: 350,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -30,
          right: -10,
          width: "80px",
          height: "80px",
          backgroundColor: "#4976a9",
          borderRadius: "100%",
        }}
      />
      <CardContent>
        <div
          style={{
            width: "95%",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={25}
              sx={{ fontWeight: "600", color: "#151D48", textAlign: "left" }}
            >
              {`Order Processed(Last Month vs Current Month)`}
            </Typography>
          </div>
          {chartData && chartOption ? (
            <Bar options={chartOption} data={chartData}></Bar>
          ) : (
            <></>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MonthlyOrderComparision;
