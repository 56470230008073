import { SvgIcon } from "@mui/material";
import React from "react";

const UploadIcon = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.7666 5.41678L9.89994 3.28345L12.0333 5.41678"
          stroke={fill}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.8999 11.8166V3.34155"
          stroke={fill}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.3335 10C3.3335 13.6833 5.8335 16.6667 10.0002 16.6667C14.1668 16.6667 16.6668 13.6833 16.6668 10"
          stroke={fill}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default UploadIcon;
