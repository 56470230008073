import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  debounce,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { productService } from '../../utils/services/productService'
import { inventoryService } from '../../utils/services/inventory.service'
import { Bar } from 'react-chartjs-2'
import { generateColor, shadeColor } from '../../utils/utility'

const InventoryCard = ({}) => {
  const [skuList, setSKUList] = useState([])
  const [inventory, setInventory] = useState([])
  const clientId = localStorage.getItem('client_id')
  const autocompleteRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [skuSearchValue, setskuSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [selectedSKU, setSelectedSKU] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [chartOption, setChartOption] = useState(null)

  const fetchSKU = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true)
      try {
        const response = await productService.fetchSKU(clientId, searchQuery, 10, pageNumber)
        if (pageNumber === 0) {
          setSKUList(response.data)
        } else {
          setSKUList((prevSuppliers) => [...prevSuppliers, ...response.data])
        }
        setTotal(response.data.length || 0)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [clientId]
  )

  const fetchInventory = async (data) => {
    try {
      let inventory = await inventoryService.get(data._id, data.sku)
      setInventory(inventory.data)
      setChartOption({
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          },
          title: {
            display: true,
            text: ''
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            // ticks: {
            //   stepSize: 1,
            // },
            title: {
              display: false,
              text: 'Count'
            }
          },
          x: {
            grid: {
              display: false
            },
            title: {
              display: false,
              text: 'Status'
            }
          }
        }
      })
      setChartData({
        labels: ['On Hand', 'Available', 'Inbound to warehouse'],
        datasets: inventory.data.map((x) => {
          let color = generateColor()
          return {
            label: x.provider.name,
            data: [x.in_hand, x.quantity, x.in_transit],
            backgroundColor: color,
            borderColor: shadeColor(color, 40),
            borderWidth: 1
          }
        })
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchInventory()
  }, [])

  useEffect(() => {
    fetchSKU(skuSearchValue, page)
  }, [skuSearchValue, page, fetchSKU])

  const handleSearchChange = (event) => {
    const value = event.target.value
    setskuSearchValue(value)
    setPage(0)
    setSKUList([])
  }

  const handleLoadMoreClick = (event, value) => {
    if (value?.loadMore) {
      if (total == 10 && !loading) {
        setPage((prevPage) => prevPage + 1)
        autocompleteRef.current.focus()
      }
    } else {
      setSelectedSKU(value)
      fetchInventory(value)
    }
  }

  useEffect(() => {
    if (selectedSKU) {
      fetchInventory({ sku: selectedSKU.variants.sku, _id: selectedSKU._id })
    }
  }, [selectedSKU])
  return (
    <Card
      sx={{
        minWidth: '100%',
        // minHeight: height || 250,
        padding: 1,
        backgroundColor: '#c9d6e4',
        borderRadius: '16px',
        position: 'relative',
        // marginRight: 3,
        minHeight: 350,
        marginBottom: 5
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: -30,
          right: -10,
          width: '80px',
          height: '80px',
          backgroundColor: '#4976a9',
          borderRadius: '100%'
        }}
      />
      <CardContent>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography fontSize={25} sx={{ fontWeight: '600', color: '#151D48', textAlign: 'left' }}>
              {`Inventory`}
            </Typography>
            <Autocomplete
              style={{
                minWidth: 400
              }}
              ref={autocompleteRef}
              size="small"
              options={[...skuList, { label: 'Load More...', loadMore: true }]}
              getOptionLabel={(option) =>
                option.loadMore
                  ? ''
                  : `${option?.variants?.sku || ''} ${option?.name ? `(${option.name})` : ''}`
              }
              value={selectedSKU}
              onChange={(event, value) => {
                handleLoadMoreClick(event, value)
                if (value && value.loadMore) {
                  setTimeout(() => {
                    autocompleteRef.current.focus() // Refocus to open dropdown
                  }, 0)
                }
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  {option.loadMore && total === 10 ? (
                    <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Load More...</span>
                  ) : loading ? (
                    <CircularProgress sx={{ color: '#151D48' }} size={30} />
                  ) : (
                    <Tooltip title={option.name || ''}>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '100%'
                        }}
                      >
                        {`${option?.variants?.sku || ''} ${option?.name ? `(${option.name})` : ''}`}
                      </span>
                    </Tooltip>
                  )}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Item Number"
                  variant="outlined"
                  onChange={handleSearchChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  style={{ backgroundColor: '#abcff8' }}
                  margin="dense"
                />
              )}
            />
          </div>
          {chartData && chartOption ? <Bar options={chartOption} data={chartData}></Bar> : <></>}
        </div>
      </CardContent>
    </Card>
  )
}

export default InventoryCard
