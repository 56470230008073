import React, { useEffect, useState } from 'react'
import ShipmentCostModal from '../../components/shimentCostModal'
import DashboardContainer from '../../components/dashboard-container'
import { shippingPriceService } from '../../utils/services/shippingPriceService'
import { Box, Button, CircularProgress } from '@mui/material'
import ShipmentCostListView from '../../components/shipment-cost-list'
import { shippingCostService } from '../../utils/services/shippingCostService'
import EstimateModal from '../../components/estimate-modal'
import CustomTypography from '../../globalStyles/fonts'

const ShipmentCost = () => {
  const [selectedShipmentCost, setSelectedShipmentCost] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [shipmentCostList, setShipmentCostList] = useState([])
  const [viewMode, setViewMode] = useState('list')
  const [searchValue, setSearchValue] = useState('')
  const [clientFilter, setClientFilter] = useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const [loading, setLoading] = useState(false)
  const [openEstimateModal, setOpenEstimateModal] = useState(false)
  const clientId = localStorage.getItem('client_id')

  const fetchShipmentCost = async () => {
    setLoading(true)
    try {
      const shipmentCost = await shippingCostService.fetch(
        rowsPerPage,
        page * rowsPerPage,
        clientId ? clientId : clientFilter?._id,
        searchValue
      )
      setShipmentCostList(shipmentCost?.data)
      setLoading(false)
      setDataCount(shipmentCost?.total)
    } catch (error) {
      console.error('Fetch failed:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchShipmentCost()
  }, [page, rowsPerPage, clientFilter, searchValue])

  return (
    <DashboardContainer
      headerButtons={
        <div>
          <Button
            size="small"
            style={{
              backgroundColor: '#007DFF',
              margin: 3,
              minWidth: 50
            }}
            variant="contained"
            onClick={() => setOpenEstimateModal(true)}
          >
            <CustomTypography
              style={{ textTransform: 'none' }}
              fontSize="14px"
              fontWeight={500}
              color="#FFFFFF"
            >
              Estimate
            </CustomTypography>
          </Button>
        </div>
      }
      hideUploadButton
      header={'Shipment Cost List'}
      title={'+ Add Shipment Cost'}
      onClickAddButton={() => setOpenModal(true)}
      shipmentPrice
      setViewMode={setViewMode}
      onSearchData={(x) => {
        if (x.trim()) {
          setSearchValue(x)
          setPage(0)
        } else {
          setSearchValue('')
        }
      }}
      onSelectClient={(e) => setClientFilter(e)}
      shipmentCost
    >
      <EstimateModal
        open={openEstimateModal}
        handleClose={() => {
          setOpenEstimateModal(false)
          setSelectedShipmentCost(null)
        }}
      />
      <ShipmentCostModal
        selectedShipmentCost={selectedShipmentCost}
        handleClose={() => {
          setOpenModal(false)
          setSelectedShipmentCost(null)
        }}
        open={openModal}
        onAddData={fetchShipmentCost}
      />
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <ShipmentCostListView
        dataCount={dataCount}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        onDeleteData={fetchShipmentCost}
        onEditData={(e) => {
          setSelectedShipmentCost(e)
          setOpenModal(true)
        }}
        data={shipmentCostList}
        viewMode={viewMode}
        selectedShipmentCost={selectedShipmentCost}
        setSelectedShipmentCost={setSelectedShipmentCost}
      />
    </DashboardContainer>
  )
}

export default ShipmentCost
