import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import TextField from '@mui/material/TextField/TextField'
import { carrierService } from '../../utils/services/carrierService'
import BorderColorIcon from '@mui/icons-material/BorderColor'

import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider
} from '@mui/material'
import { clientService } from '../../utils/services/clientService'
import AppContext from '../context/context'
import CustomTypography from '../../globalStyles/fonts'
import CloseIcon from '@mui/icons-material/Close'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import AssignClient from '../client-assign'
import CarrierDetailsModal from '../carrierViewDetailsModal'
import { isMobile } from '../../globalStyles/mobile'

const CarrierList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleEditClient,
  handleDeleteClient,
  onSelectClient,
  viewMode,
  deleteTitle
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [carrierName, setCarrierName] = useState('')
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [clientData, setClientData] = useState([])
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const clientId = localStorage.getItem('client_id')
  const { user } = useContext(AppContext)
  const [openRow, setOpenRow] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index)
  }
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }

  useEffect(() => {
    fetchClientData()
  }, [])

  const handleEditClick = (carrier) => {
    setSelectedCarrier(carrier)
    setCarrierName(carrier.name)
    setSelectedClient({
      name: carrier?.client_id?.name,
      _id: carrier.client_id?._id
    })
    setOpenModal(true)
  }

  const handleDeleteClick = (carrier) => {
    setSelectedCarrier(carrier)
    setOpenConfirmation(true)
  }

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await carrierService.delete(selectedCarrier._id)
      onDeleteData && onDeleteData()
      setAnchorEl(null)
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const validateForm = () => {
    const errors = {}
    if (!carrierName) {
      errors.name = 'Carrier name is required'
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = 'Please select Client'
    // }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleEditCarrier = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      setLoading(true)
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await carrierService.edit(carrierName, selectedCarrier._id, client_id)
      onEditData && onEditData()
      setFormErrors({})
    } catch (error) {
      console.error('Edit failed:', error)
    } finally {
      setOpenModal(false)
      setAnchorEl(null)
      setLoading(false)
    }
  }
  const handleClientAssignClick = () => {
    setModalOpen(true)
  }
  const handleViewClick = (carrier) => {
    setSelectedCarrier(carrier)
    setOpenViewModal(true)
  }
  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {data?.map((carrier) => (
        <Grid item xs={12} sm={6} md={3} key={carrier.id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={carrier?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {carrier?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                {user.role == 'admin' ? (
                  <div style={{ display: 'flex' }}>
                    <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 15,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="edit"
                      onClick={() => handleEditClick(carrier)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>

                    <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="delete"
                      onClick={() => handleDeleteClick(carrier)}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data?.map((carrier, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffff",
              borderRadius: 4,
            }}
          >
            <CardContent>
              <div style={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                 <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Carrier Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {carrier?.name}
                        </Typography>
                      </Grid>
                      </Grid>
                </Box>

                <Box mt={2} justifyContent={"flex-end"} display="flex"gap={2}>
                  <IconButton
                    style={{
                      color: "#007DFF",
                      padding: 0,
                      background: "#C3E1FF",
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      // marginLeft: 10,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                    aria-label="view"
                    onClick={() => {
                      handleViewClick(carrier);
                    }}
                  >
                    <img
                      style={{ height: 14, width: 20 }}
                      alt="view"
                      src="/eye.png"
                    ></img>
                  </IconButton>
                  {user.role == "admin" ? (
                    <>
                      <IconButton
                        style={{
                          color: "#007613",
                          padding: 0,
                          background: "#00761333",
                          borderRadius: 5,
                          // marginLeft: 10,
                          height: 30,
                          width: 30,
                          padding: 0,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleClientAssignClick();
                          setSelectedCarrier(carrier);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="client"
                          src="/client-assign.png"
                        ></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#FEC50F",
                          padding: 0,
                          background: "#FEC50F33",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          // marginLeft: 15,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          // marginTop: 5,
                        }}
                        aria-label="edit"
                        onClick={() => handleEditClick(carrier)}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#D13438",
                          padding: 0,
                          background: "#D1343833",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          // marginLeft: 10,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          // marginTop: 5,
                        }}
                        aria-label="delete"
                        onClick={() => handleDeleteClick(carrier)}
                      >
                        <img
                          style={{ height: 15, width: 15 }}
                          alt="delete"
                          src="/delete.png"
                        ></img>
                      </IconButton>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  }

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 100
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            marginTop: 1,
            maxHeight: '70vh',
            overflowY: 'auto',
            paddingInline: 0.4
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0 5px',
              paddingBottom: 7
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: 8,
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 10
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Carrier Name
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 4,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    alignItems: 'center'
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((carrier, index) => (
                <React.Fragment>
                  <TableRow
                    onClick={() => handleRowClick(index)}
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#fff',
                      borderRadius: 8,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 4
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {carrier.name}
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 4,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        alignItems: 'center'
                      }}
                    >
                      {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > */}
                      <IconButton
                        style={{
                          color: '#007DFF',
                          padding: 0,
                          background: '#C3E1FF',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleViewClick(carrier)
                        }}
                      >
                        <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                      </IconButton>
                      {user.role == 'admin' ? (
                        <>
                          <IconButton
                            style={{
                              color: '#007613',
                              padding: 0,
                              background: '#00761333',
                              borderRadius: 5,
                              marginLeft: 10,
                              height: 30,
                              width: 30,
                              padding: 0,
                              boxShadow: '0px 0px 4px 0px #00000040'
                            }}
                            aria-label="view"
                            onClick={() => {
                              handleClientAssignClick()
                              setSelectedCarrier(carrier)
                            }}
                          >
                            <img
                              style={{ height: 14, width: 20 }}
                              alt="client"
                              src="/client-assign.png"
                            ></img>
                          </IconButton>
                          <IconButton
                            style={{
                              color: '#FEC50F',
                              padding: 0,
                              background: '#FEC50F33',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 15,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="edit"
                            onClick={() => handleEditClick(carrier)}
                          >
                            <BorderColorIcon sx={{ height: 20, width: 20 }} />
                          </IconButton>
                          <IconButton
                            style={{
                              color: '#D13438',
                              padding: 0,
                              background: '#D1343833',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 10,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="delete"
                            onClick={() => handleDeleteClick(carrier)}
                          >
                            <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )

  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
      )}
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setFormErrors({});
        }}
        maxWidth="md"
        // fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{"Edit Carrier"}</DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
             Carrier Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={{ minWidth: 300 }}
                autoFocus
                margin="dense"
                //label="Carrier Name"
                type="text"
                fullWidth
                value={carrierName}
                onChange={(e) => setCarrierName(e.target.value)}
                error={formErrors?.name}
                helperText={formErrors?.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => {
              setOpenModal(false);
              setFormErrors({});
            }}
            className="cancel-button-style"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className="add-button-style"
            variant="contained"
            color="primary"
            onClick={(e) => handleEditCarrier(e)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedCarrier?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            handleDeleteClient && handleDeleteClient(selectedCarrier);
            setOpenConfirmation(false);
          } else {
            confirmDelete(e);
          }
        }}
      />
      <AssignClient
        selectedData={selectedCarrier}
        mapKey="carrier"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <CarrierDetailsModal
        carrier={selectedCarrier}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  );
}

export default CarrierList
