import { Backdrop, CircularProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import AppContext from './components/context/context'
import Layout from './components/layout'
const ProtectedRoute = () => {
  const { isAuthenticated, setCurrentMenu, setCurrentSubMenu } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  // Create a URLSearchParams object based on the current URL's query string

  useEffect(() => {
    // Simulate async authentication check
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, []) // Run only once on component mount
  if (loading) {
    // Show loading spinner or splash screen while checking authentication status
    return (
      <Backdrop
        open={true}
        style={{
          zIndex: 999,
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            flexDirection: 'column'
          }}
        >
          <CircularProgress color="primary" />
          <p
            style={{
              marginTop: 10,
              animation: 'loadingAnim 1s infinite alternate'
            }}
          >
            Loading...
          </p>
          {/* Added animation */}
        </div>
      </Backdrop>
    )
  }

  return isAuthenticated ? (
    <div>
      <Layout>
        <Outlet />
      </Layout>
    </div>
  ) : (
    <Navigate replace={true} to="/login" />
  )
}

export default ProtectedRoute
