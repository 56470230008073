import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Box,
  Tooltip,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { weightPackageService } from "../../utils/services/weightPackageService";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import CustomTypography from "../../globalStyles/fonts";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import CustomPagination from "../pagination";

const WeightPackageListView = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedWeightPackage,
  setSelectedWeightPackage,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const deleteData = async () => {
    try {
      await weightPackageService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Package mapping deleted successfully!");
    } catch (error) {
      console.error("Delete failed:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Weight
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          :{" "}
                          {`${x?.min_weight}/${x?.min_weight_unit} - ${x?.max_weight}/${x?.max_weight_unit}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Dimension
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          :{" "}
                          {`${x?.dimension.length} * ${x?.dimension.width} * ${x?.dimension.height}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Package Weight
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          : {`${x?.pkg_weight} ${x?.pkg_weight_unit}`}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    mt={1}
                    justifyContent={"flex-end"}
                    display="flex"
                    gap={2}
                  >
                    <IconButton
                      style={{
                        color: "#FEC50F",
                        padding: 0,
                        background: "#FEC50F33",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      onClick={() => onEditData(x)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: "#D13438",
                        padding: 0,
                        background: "#D1343833",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      onClick={() => {
                        setSelectedWeightPackage(x);
                        setDeletingItemId(x._id);
                        setOpenConfirmation(true);
                      }}
                    >
                      <img
                        style={{ height: 15, width: 15 }}
                        alt="delete"
                        src="/delete.png"
                      ></img>
                    </IconButton>
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        height: window.innerHeight < 650 ? "80vh" : "auto",
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            marginTop: 1,
            maxHeight: "70vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 5px",
              paddingBottom: 7,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: 8,
                  boxShadow: "0px 0px 4px 0px #00000040",
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: 8,
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Client
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Warehouse
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Weight
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Dimension
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Package Weight
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((x, index) => (
                <TableRow
                  key={index}
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                >
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      borderTopLeftRadius: 8,
                      borderBottomLeftRadius: 8,
                      padding: 4,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {x?.client_id?.business_name || x?.client_id?.name}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      padding: 4,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {x?.warehouse.provider}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      padding: 4,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {`${x?.min_weight}/${x?.min_weight_unit} - ${x?.max_weight}/${x?.max_weight_unit}`}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: "none",
                      padding: 4,
                      textAlign: "center",
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {`${x?.dimension?.length} * ${x?.dimension?.width} * ${x?.dimension.height}`}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: "none",
                      padding: 4,
                      textAlign: "center",
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {`${x.pkg_weight} ${x.pkg_weight_unit}`}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      padding: 4,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    <IconButton
                      style={{
                        color: "#FEC50F",
                        padding: 0,
                        background: "#FEC50F33",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 15,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      onClick={() => onEditData(x)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: "#D13438",
                        padding: 0,
                        background: "#D1343833",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                      onClick={() => {
                        setSelectedWeightPackage(x);
                        setDeletingItemId(x._id);
                        setOpenConfirmation(true);
                      }}
                    >
                      <img
                        style={{ height: 15, width: 15 }}
                        alt="delete"
                        src="/delete.png"
                      ></img>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "50vh" : "65vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {renderListView()}
        </div>
        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          title={`Are you sure you want to delete this item?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
      </div>
    </>
  );
};

export default WeightPackageListView;
