import React, { useState, useEffect, useContext } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  TablePagination,
  Tooltip,
  Box
} from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'
import { shippingPriceService } from '../../utils/services/shippingPriceService'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import AppContext from '../context/context'
import CustomTypography from '../../globalStyles/fonts'
import ShippingPriceDetailsModal from '../shipping-option-details-modal'
import { isMobile } from '../../globalStyles/mobile'
import { toast } from 'react-toastify'
import CustomPagination from '../pagination'

const ShipmentOptionListView = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedShipmentPrice,
  setSelectedShipmentPrice
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null)
  const [shippigPriceData, setShippigPriceData] = useState([])
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const { user } = useContext(AppContext)
  const [openViewModal, setOpenViewModal] = useState(false)

  const deleteData = async () => {
    try {
      await shippingPriceService.delete(deletingItemId)
      setOpenConfirmation(false)
      onDeleteData && onDeleteData()
      toast.success("Shipment Options deleted successfully!")
    } catch (error) {
      toast.error("Failed to delete Shipment Options")
      console.error('Delete failed:', error)
    }
  }
  const handleViewClick = (shipping) => {
    setSelectedShipmentPrice(shipping)
    setOpenViewModal(true)
  }
  useEffect(() => {
    setShippigPriceData(
      data?.map((item) => ({
        ...item,
        action: (
          <div>
            {viewMode == 'list' && (
              <IconButton
                style={{
                  color: '#007DFF',
                  padding: 0,
                  background: '#C3E1FF',
                  height: 30,
                  width: 30,
                  borderRadius: 5,
                  marginLeft: 10,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
                aria-label="view"
                onClick={() => {
                  handleViewClick(item)
                }}
              >
                <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
              </IconButton>
            )}

            <IconButton
              onClick={() => onEditData && onEditData(item)}
              style={{
                color: '#FEC50F',
                padding: 0,
                background: '#FEC50F33',
                height: 30,
                width: 30,
                borderRadius: 5,
                marginLeft: 15,
                boxShadow: '0px 0px 4px 0px #00000040'
              }}
            >
              <BorderColorIcon sx={{ height: 20, width: 20 }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedShipmentPrice(item)
                setDeletingItemId(item._id)
                setOpenConfirmation(true)
              }}
              style={{
                color: '#D13438',
                padding: 0,
                background: '#D1343833',
                height: 30,
                width: 30,
                borderRadius: 5,
                marginLeft: 10,
                boxShadow: '0px 0px 4px 0px #00000040'
              }}
            >
              <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
            </IconButton>
          </div>
        )
      }))
    )
  }, [data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: 'fill', height: '300px' }}
      />
    </div>
  )

  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {shippigPriceData?.map((x) => (
        <Grid item xs={12} sm={6} md={3} key={x._id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '30%',
                    display: 'flex',
                    flexDirection: 'column'
                    // marginLeft: 15,
                  }}
                >
                  <Tooltip title={x?.type} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '50%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {x?.type}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '60%',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {' '}
                  {x.action}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {shippigPriceData.length === 0 ? (
          <NoDataImage />
        ) : (
          shippigPriceData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Min weight(kg)
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.min_weight}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Max weight(kg)
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.max_weight}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Min Days
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.min_days}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Max Days
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.max_days}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Price(USD)
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.price}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }} 
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={1} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  }

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        height: isMobile() ? "50vh" : "70vh",
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          className="custom-container"
          sx={{
            marginTop: 1,
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 5px",
              paddingBottom: 7,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: 8,
                  boxShadow: "0px 0px 4px 0px #00000040",
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: 8,
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Type
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    {"Min Weight (Kg)"}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    {" Max Weight (Kg)"}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Min Days
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Max Days
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    {"Price (USD)"}
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    padding: 10,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shippigPriceData?.map((x, index) => (
                <React.Fragment>
                  <TableRow
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "center",
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 4,
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.type}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        padding: 4,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.pricing?.slice(0, 2).map((pricing, index) => (
                          <React.Fragment key={index}>
                            {pricing.min_weight}
                            {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                          </React.Fragment>
                        ))}
                        {x?.pricing.length > 2 && (
                          <span
                            onClick={() => handleViewClick(x)}
                            style={{ cursor: "pointer", color: "#0073e6" }}
                          >
                            {` ... (${x?.pricing.length - 2} more)`}
                          </span>
                        )}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        padding: 4,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.pricing?.slice(0, 2).map((pricing, index) => (
                          <React.Fragment key={index}>
                            {pricing.max_weight}
                            {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                          </React.Fragment>
                        ))}
                        {x?.pricing.length > 2 && (
                          <span
                            onClick={() => handleViewClick(x)}
                            style={{ cursor: "pointer", color: "#0073e6" }}
                          >
                            {` ... (${x?.pricing.length - 2} more)`}
                          </span>
                        )}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        padding: 4,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.pricing?.slice(0, 2).map((pricing, index) => (
                          <React.Fragment key={index}>
                            {pricing.max_days}
                            {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                          </React.Fragment>
                        ))}
                        {x?.pricing.length > 2 && (
                          <span
                            onClick={() => handleViewClick(x)}
                            style={{ cursor: "pointer", color: "#0073e6" }}
                          >
                            {` ... (${x?.pricing.length - 2} more)`}
                          </span>
                        )}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        padding: 4,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.pricing?.slice(0, 2).map((pricing, index) => (
                          <React.Fragment key={index}>
                            {pricing.max_days}
                            {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                          </React.Fragment>
                        ))}
                        {x?.pricing.length > 2 && (
                          <span
                            onClick={() => handleViewClick(x)}
                            style={{ cursor: "pointer", color: "#0073e6" }}
                          >
                            {` ... (${x?.pricing.length - 2} more)`}
                          </span>
                        )}
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        padding: 4,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {x?.pricing?.slice(0, 2).map((pricing, index) => (
                          <React.Fragment key={index}>
                            {pricing.price}
                            {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                          </React.Fragment>
                        ))}
                        {x?.pricing.length > 2 && (
                          <span
                            onClick={() => handleViewClick(x)}
                            style={{ cursor: "pointer", color: "#0073e6" }}
                          >
                            {` ... (${x?.pricing.length - 2} more)`}
                          </span>
                        )}
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "center",
                        padding: 4,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                      }}
                    >
                      {x.action}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
        {/* <TablePagination
          slotProps={{
            actions: {
              nextButton: {
                disabled: dataCount <= rowsPerPage * (page + 1)
              }
            }
          }}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#ffffff',
            padding: '2px',
            borderTop: '1px solid #dddddd'
          }}
        /> */}
        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedShipmentPrice?.type} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <ShippingPriceDetailsModal
          shippingPrice={selectedShipmentPrice}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
}

export default ShipmentOptionListView;
