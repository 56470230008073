import React, { useEffect, useState, useContext, useReducer } from 'react'
import DashboardContainer from '../../components/dashboard-container'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Button, IconButton } from '@mui/material'
import { productService } from '../../utils/services/productService'
import ProductList from '../../components/product-list'
import AppContext from '../../components/context/context'
import { toast } from 'react-toastify'
import ProductUploadModal from '../../components/file-upload-modal'
import UploadStatusChartDialog from '../../components/upload-status'
import { useNavigate } from 'react-router-dom'
import CreateASN from '../../components/create-asn'
import CustomTypography from '../../globalStyles/fonts'
import { isMobile } from '../../globalStyles/mobile'
import ImportIcon from '../../globalStyles/icons/importIcon'
import UploadIcon from '../../globalStyles/icons/upload-icon'
import { supplierService } from '../../utils/services/supplierService'
import buildQuery from '../../utils/query-builder'
import ASNIcon from '@mui/icons-material/InventoryOutlined'
import { useTheme } from '@mui/styles'

const initialState = {
  columns: [
    {
      key: 'product_type',
      name: 'Category',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: '_id',
      fetchApi: (searchKey = '', client_id, limit = 15, skip = 0) =>
        productService.fetchCategory(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'status',
      name: 'Status',
      filter: [],
      options: [{ name: 'active' }, { name: 'draft' }],
      sort: 0,
      id: 'name',
      displayKey: 'name',
      excludeValues: ['all'],
      loadMoreButton: false,
      searchKey: ''
    },
    {
      key: 'vendor',
      name: 'Supplier',
      sort: 0,
      id: '_id',
      filter: [],
      displayKey: 'name',
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetch(searchKey, client_id, buildQuery({ limit, skip })),
      loadMoreButton: true,
      searchKey: ''
    },
    {
      key: 'no_of_sku',
      name: 'No Of SKU',
      filter: [],
      options: Array.from({ length: 100 }).map((x, i) => ({
        name: (i + 1).toString()
      })),
      sort: 0,
      id: 'name',
      displayKey: 'name',
      loadMoreButton: false,
      searchKey: ''
    },
    {
      key: 'compliance_status',
      name: 'Compliance Status',
      filter: [],
      options: [{ name: 'Not Started' }, { name: 'Started' }, { name: 'In Progress' }, { name: 'Compliant' }],
      sort: 0,
      id: 'name',
      displayKey: 'name',
      loadMoreButton: false,
      searchKey: ''
    },
    {
      key: 'name',
      name: 'Name',
      filter: [],
      sort: 0,
      id: '_id',
      displayKey: '_id',
      fetchApi: (searchKey, client_id, limit = 15, skip = 0) =>
        productService.fetchNames(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: ''
    }
  ],
  limit: 15,
  populate: ['client_id', 'vendor']
}

function filterReducer(state, action) {
  console.log(action)

  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        columns: state?.columns.map((x) =>
          x?.key === action?.payload?.key ? { ...x, ...action.payload } : x
        )
      }
    case 'SET_OPTIONS':
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key === action.payload.key ? { ...col, options: action.payload.options } : col
        )
      }
    case 'UPDATE_LIMIT':
      return { ...state, limit: action.payload }

    default:
      return state
  }
}

export default function Product() {
  const [filterData, dispatch] = useReducer(filterReducer, initialState)
  const navigate = useNavigate()
  const [productData, setProductData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = React.useState(0)
  const [dataCount, setDataCount] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [clientFilter, setClientFilter] = useState(null)
  const [viewMode, setViewMode] = useState('list')
  const clientId = localStorage.getItem('client_id')
  const { user } = useContext(AppContext)
  const [showUploadStatus, setShowUploadStatus] = useState(false)
  const [showASNModal, setShowASNModal] = useState(false)
  const [uploadStatusData, setUploadStatusData] = useState([])
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  // const fetchUploadData = async () => {
  //   try {
  //     const response = await productService.fetchBulkUploadData();
  //     setUploadStatusData(response.data);
  //   } catch (error) {
  //     console.error("Fetch failed:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchUploadData();
  // }, []);

  const fetchProductData = async () => {
    setLoading(true)
    try {
      const response = await productService.fetch(
        clientId ? clientId : clientFilter?._id,
        searchValue,
        buildQuery(filterData, page)
      )

      setProductData(response.data)
      setDataCount(response.total)
    } catch (error) {
      console.error('Fetch failed:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProductData()
  }, [filterData, searchValue, page, clientFilter])

  const syncProductData = async () => {
    // fetchImportedCatalog();
    setLoading(true)
    try {
      const response = await productService.syncProduct({
        client_id: clientId,
        core: true
      })
      console.log(response)
      toast.success('Product import has been successfully initiated!')
    } catch (error) {
      console.error('Fetch failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleFilterChange = (newFilter) => {
    dispatch({ type: 'SET_FILTER', payload: newFilter })
    setPage(0)
  }

  return (
    <>
      <DashboardContainer
        onChangeFilter={handleFilterChange}
        filterData={filterData}
        fields={filterData.columns}
        hideUploadButton
        headerButtons={
          <>
            {user?.role === 'client' ? (
              isMobile() ? (
                <Box display="flex" flexWrap="wrap" gap={1} justifyContent="flex-start">
                  <IconButton onClick={syncProductData}>
                    <ImportIcon fill={primary_color} />
                  </IconButton>

                  <IconButton
                    style={{
                      color: primary_color
                    }}
                    onClick={syncProductData}
                  >
                    <ASNIcon />
                  </IconButton>

                  <IconButton onClick={() => setOpenUploadModal(true)}>
                    <UploadIcon fill={primary_color} />
                  </IconButton>
                </Box>
              ) : (
                <Box display="flex" flexWrap="wrap" gap={1} justifyContent="flex-start">
                  <Button
                    size="small"
                    style={{
                      textTransform: 'none'
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={syncProductData}
                  >
                    <ImportIcon fill={'primary.main'} />
                    <CustomTypography fontSize="14px" fontWeight={500} color={'primary'}>
                      Import
                    </CustomTypography>
                  </Button>

                  <Button
                    size="small"
                    style={{
                      textTransform: 'none'
                    }}
                    color="primary"
                    variant="outlined"
                    onClick={() => setShowASNModal(true)}
                  >
                    <CustomTypography fontSize="14px" fontWeight={500} color={'primary'}>
                      + ASN
                    </CustomTypography>
                  </Button>

                  <Button
                    size="small"
                    style={{
                      textTransform: 'none'
                    }}
                    variant="contained"
                    onClick={() => setOpenUploadModal(true)}
                  >
                    <UploadIcon fill="#fff" />
                    <CustomTypography fontSize="14px" fontWeight={500} color="#FFFFFF">
                      Upload
                    </CustomTypography>
                  </Button>
                </Box>
              )
            ) : null}
          </>
        }
        showUploadStatus
        onSearchData={(x) => {
          // Check if there’s a search term
          if (x.trim()) {
            setSearchValue(x)
            setPage(0) // Reset to page 0 when there’s a search term
          } else {
            setSearchValue('') // Clear the search value if empty search term
          }
        }}
        onClickAddButton={() => {
          // localStorage.setItem('path', `/manage-product/create`)
          navigate('/manage-product/create')
        }}
        header={'Product List'}
        onSelectClient={(e) => {
          setClientFilter(e)
        }}
        selectedClient={clientFilter?.name}
        onHandleUploadButton={() => setOpenUploadModal(true)}
        product
        title={'+ Product'}
        setViewMode={setViewMode}
        onClickRefresh={fetchProductData}
        onClickSyncProduct={syncProductData}
        onClickCreateASN={() => {
          setShowASNModal(true)
        }}
      >
        <CreateASN openDialog={showASNModal} handleCloseDialog={() => setShowASNModal(false)}></CreateASN>
        {/* <ImportProductModal
          open={showImportModal}
          setOpen={() => setShowImportModal(!showImportModal)}
          selectedOption={selectedOption}
          setSelectedOption={(e) => setSelectedOption(e)}
          handleConfirm={importProduct}
          handleDiscardAll={discardAll}
        /> */}
        <ProductUploadModal
          onClickStatus={() => setShowUploadStatus(true)}
          showUploadStatus
          onSuccessUpload={() => fetchProductData()}
          product
          onSubmitUploadData={() => setOpenUploadModal(false)}
          onClose={() => setOpenUploadModal(false)}
          open={openUploadModal}
          title={'Product List'}
          selectedData={uploadStatusData}
        ></ProductUploadModal>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
              color: 'white'
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <ProductList
          data={productData}
          onEditData={fetchProductData}
          onDeleteData={fetchProductData}
          client={clientId}
          viewMode={viewMode}
          //setFilter={setFilter}
          deleteTitle={'Delete Product'}
          dataCount={dataCount}
          page={page}
          rowsPerPage={filterData.limit}
          setRowsPerPage={(e) =>
            dispatch({
              type: 'UPDATE_LIMIT',
              payload: e
            })
          }
          setPage={setPage}
          onChangeFilter={handleFilterChange}
          filterData={filterData}
        />
        <UploadStatusChartDialog
          onClose={() => setShowUploadStatus(false)}
          open={showUploadStatus}
        ></UploadStatusChartDialog>
      </DashboardContainer>
    </>
  )
}
