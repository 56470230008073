import React from 'react'
import PropTypes from 'prop-types'
import { Typography as MuiTypography } from '@mui/material'
import { styled } from '@mui/system'

const StyledTypography = styled(MuiTypography)(({ fontSize, color, fontWeight, theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: fontSize,
  color: color || theme.palette.text.main, // Use primary color from the theme
  fontWeight: fontWeight,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

const CustomTypography = ({ fontSize, variant, color, children, fontWeight, style, align, ...props }) => {
  return (
    <StyledTypography
      align={align}
      sx={style}
      variant={variant ? 'h1' : 'body1'}
      fontSize={fontSize}
      color={color || '#33333'}
      fontWeight={fontWeight}
      {...props}
    >
      {children}
    </StyledTypography>
  )
}

CustomTypography.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.bool,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  children: PropTypes.node.isRequired,
  align: PropTypes.string
}

CustomTypography.defaultProps = {
  fontSize: '16px',
  variant: false,
  color: '' // Default to empty string
}

export default CustomTypography
