// api.js

import axiosInstance from "../axiosInstance";

export const userService = {
  fetchUserByEmail: async (email) => {
    try {
      const response = await axiosInstance.get(
        `/generate-token?email=${email}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
