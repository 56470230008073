// components/Breadcrumbs.js
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link, Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import CustomTypography from '../../globalStyles/fonts'
import { getObjectByPath } from '../../utils/getObjectByPath'
import { isMobile } from '../../globalStyles/mobile'
import HomeIcon from '../../globalStyles/icons/home-icon'
import { useTheme } from '@mui/styles'

const Breadcrumbs = () => {
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const secondary_color = theme.palette?.secondary?.main || defaultColor

  const location = useLocation()
  const path = location.pathname
  function isMongoIdInUrl() {
    // Regular expression to match a MongoDB ObjectID
    const objectIdRegex = /\/[a-f\d]{24}$/

    // Test the URL against the regex
    return objectIdRegex.test(path)
  }

  const breadcrumbData = path.toString().includes('/order-flow')
    ? {
        title: 'Order Management',
        subNav: {
          title: 'Order Work flow',
          subNav2: {
            title: ``
          }
        }
      }
    : path.toString().includes('/account-setting')
    ? { title: 'Account Setting', subNav: { title: '' } }
    : path.toString().includes('/manage-product')
    ? {
        title: 'Product',
        subNav: {
          title: 'Catalog',
          subNav2: {
            title: `${isMongoIdInUrl() ? 'Edit' : 'Add'} Product`
          }
        }
      }
    : path.toString().includes('/order-tracking-history')
    ? {
        title: 'Order Management',
        subNav: { title: 'Order Tracking History', subNav2: { title: '' } }
      }
    : path.toString().includes('/track-order')
    ? { title: 'Order Tracking', subNav: { title: '' } }
    : getObjectByPath(path)
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" color="#0E1434" />}>
      <Link component={RouterLink} color={primary_color || '#DC585C'} to="/">
        <HomeIcon fill={primary_color || '#DC585C'} />
      </Link>
      {breadcrumbData && breadcrumbData?.title && (
        <CustomTypography
          color={!breadcrumbData?.subNav?.title ? '#757575' : primary_color || '#DC585C'}
          fontSize={isMobile() ? 12 : '14px'}
          fontWeight={600}
        >
          {breadcrumbData.title}
        </CustomTypography>
      )}
      {breadcrumbData && breadcrumbData?.subNav && (
        <CustomTypography
          color={
            breadcrumbData?.subNav && breadcrumbData.subNav?.subNav2?.title
              ? primary_color
              : secondary_color || '#757575'
          }
          fontSize={isMobile() ? 12 : '14px'}
          fontWeight={600}
        >
          {breadcrumbData.subNav ? breadcrumbData.subNav?.title : ''}
        </CustomTypography>
      )}
      {breadcrumbData && breadcrumbData?.subNav?.subNav2 && (
        <CustomTypography
          color={secondary_color || '#757575'}
          fontSize={isMobile() ? 12 : '14px'}
          fontWeight={600}
        >
          {breadcrumbData.subNav?.subNav2?.title}
        </CustomTypography>
      )}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
