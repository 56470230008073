// api.js

import axiosInstance from "../axiosInstance";

export const providerEventMappingService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/provider_event_mapping", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/provider_event_mapping?&$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}` : ""
        }${
          searchValue ? `&name[$search]=${searchValue}` : ""
        }&$populate=rl_event_code&$populate=provider`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching provider-event mappings:", error);
      throw error;
    }
  },

  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(
        `/provider_event_mapping/${id}`,
        obj
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(
        `/provider_event_mapping/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
