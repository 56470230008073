export const generateUiSchema = (data, options = {}, parentPath = '', selectedFields = null) => {
  const uiSchema = {
    type: 'Group',
    label: options.label || '',
    elements: []
  }
  const SENSITIVE_KEYWORDS = ['id', 'secret', 'ip', 'token', 'created', 'updated', '_v']
  const isFieldSelected = (fieldPath) => {
    if (!selectedFields) return true

    // Check if the full path or any part of the path is in selectedFields
    return selectedFields.some((selectedField) => fieldPath.join('.').includes(selectedField))
  }

  const isIdField = (key) => {
    return SENSITIVE_KEYWORDS.some((keyword) => key.toLowerCase().includes(keyword))
  }

  const processField = (key, value, currentPath, currentPathArray = []) => {
    const newPathArray = [...currentPathArray, key]
    const fullPath = currentPath ? `${currentPath}/properties/${key}` : `#/properties/${key}`

    // Check if the key is an array or specific array-based keys
    const isArrayField = Array.isArray(value) || ['line_items', 'shipping_lines'].includes(key)

    // Determine readonly status
    const isReadonly =
      isIdField(key) || // Automatically readonly if contains 'id'
      (options.readonly && options.readonly.includes(key)) // Existing readonly logic

    const fieldOptions = isReadonly ? { readonly: true } : {}

    if (isArrayField) {
      return {
        type: 'Control',
        label: key,
        scope: fullPath,
        options: {
          multi: true,
          ...fieldOptions
        }
      }
    } else if (typeof value === 'object' && value !== null) {
      const nestedElements = generateNestedUiSchema(value, options, fullPath, newPathArray)

      if (nestedElements.length > 0) {
        return {
          type: 'Group',
          label: key,
          elements: nestedElements
        }
      }
    } else {
      return {
        type: 'Control',
        label: key,
        scope: fullPath,
        options: fieldOptions
      }
    }
  }

  const generateNestedUiSchema = (obj, options, parentPath = '', parentPathArray = []) => {
    const elements = []

    for (const [key, value] of Object.entries(obj)) {
      const newPathArray = [...parentPathArray, key]

      // Check if this field or its parent should be processed
      if (isFieldSelected(newPathArray)) {
        const processedElement = processField(key, value, parentPath, parentPathArray)

        if (processedElement) {
          elements.push(processedElement)
        }
      }
    }

    return elements
  }

  // Generate the initial UI schema elements
  uiSchema.elements = generateNestedUiSchema(data, options, parentPath)

  return uiSchema
}

// Kept the previous generateJsonSchema implementation
export const generateJsonSchema = (data, selectedFields = null) => {
  const schema = {
    type: 'object',
    properties: {}
  }

  const processField = (value, fieldPath = []) => {
    if (Array.isArray(value)) {
      // Array processing
      if (value.length > 0) {
        const firstItem = value[0]

        // If array items are objects, process all their fields
        if (typeof firstItem === 'object' && firstItem !== null) {
          return {
            type: 'array',
            items: generateJsonSchema(firstItem)
          }
        } else {
          // Array of primitives
          return {
            type: 'array',
            items: { type: typeof firstItem }
          }
        }
      } else {
        // Fallback for empty arrays
        return {
          type: 'array',
          items: { type: 'object', properties: {} }
        }
      }
    } else if (typeof value === 'object' && value !== null) {
      // Nested object processing
      return generateJsonSchema(value)
    } else {
      // Primitive value processing
      return { type: typeof value }
    }
  }

  const isFieldSelected = (fieldPath) => {
    if (!selectedFields) return true

    // Check if the full path or any part of the path is in selectedFields
    return selectedFields.some((selectedField) => fieldPath.join('.').includes(selectedField))
  }

  const processObject = (obj, currentPath = []) => {
    const processedProperties = {}

    for (const [key, value] of Object.entries(obj)) {
      const newPath = [...currentPath, key]

      // Check if this field or its parent should be processed
      if (isFieldSelected(newPath)) {
        processedProperties[key] = processField(value, newPath)
      }
    }

    return {
      type: 'object',
      properties: processedProperties
    }
  }

  return processObject(data)
}
