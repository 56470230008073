// api.js

import axiosInstance from '../axiosInstance'

export const omsLocationService = {
  fetch: async (data) => {
    try {
      let response = await axiosInstance.post('/fetch-oms-location', data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetchLocation: async (oms_id) => {
    try {
      let response = await axiosInstance.get(`/warehouse-location?oms_config=${oms_id}&$populate=provider`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/warehouse-location-custom', data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/warehouse-location/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/warehouse-location/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
