import React, { useState } from 'react'
import styles from './form-field.module.css'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
const FormField = ({
  label,
  type,
  placeholder,
  text,
  value,
  disabled,
  onChange,
  textStyle,
  onClickVerify
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const inputId = `${label.toLowerCase().replace(/\s+/g, '-')}-input`

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const renderInputType = () => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password'
    }
    return type
  }

  return (
    <div className={styles.field}>
      <div className={styles.labelIcon}>
        <label htmlFor={inputId} className={styles.label}>
          <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, color: '#000' }}>{label}</span>
          <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, color: '#ff0000' }}>*</span>
        </label>
      </div>
      <div className={styles.contents}>
        <div className={styles.inputWrapper}>
          <input
            style={{ outline: 'none', width: '70%', color: 'black' }}
            onChange={(e) => onChange && onChange(e.target.value)}
            disabled={disabled}
            value={value}
            type={renderInputType()}
            id={inputId}
            placeholder={placeholder}
            aria-label={label}
            className={styles.input}
          />
          {text ? (
            <p onClick={onClickVerify} style={textStyle}>
              {text}
            </p>
          ) : (
            type === 'password' && (
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className={styles.toggleButton}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </button>
            )
          )}
        </div>
        <div className={styles.thinUnderline} />
      </div>
    </div>
  )
}

export default FormField
