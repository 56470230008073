import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button/Button'
import TextField from '@mui/material/TextField/TextField'
import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import Typography from '@mui/material/Typography/Typography'
import { portTypes } from '../../utils/portTypes'
import AppContext from '../context/context'
import IconButton from '@mui/material/IconButton'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  CircularProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import 'react-quill/dist/quill.snow.css'
import CustomTypography from '../../globalStyles/fonts'
import { countryService } from '../../utils/services/allCountry'
import ClientAutocomplete from '../clientAutocomplete'
import { isMobile } from '../../globalStyles/mobile'

const AddModal = ({
  handleClose,
  open,
  onChangeName,
  nameValue,
  emailValue,
  mobileNoValue,
  countryCodeValue,
  onClickAdd,
  port,
  title,
  onChangeCountry,
  onChangeLocation,
  service,
  selectedCarrierService,
  setSelectedCarrierService,
  carrierList,
  zone,
  onChangeZoneNumber,
  handleChangeType,
  formErrors,
  onChangePostalCode,
  onChangeEmail,
  onChangeMobileNo,
  onChangeCountryCode,
  client,
  zoneValue,
  selectedClientData,
  onSelectClient,
  product,
  variants,
  setVariants,
  carrier,
  modalHeader,
  nameHeader,
  onChangeCountryValue,
  country,
  businessName,
  onChangeBusiness,
  setCarrierSearchValue,
  fetchCarrierData,
  setPage,
  setCarrier,
  handleScroll,
  supplier,
  onChangeMinOrdQty,
  onChangeMaxOrdQty,
  companyUrl,
  validated,
  address1,
  address2,
  onChangeAddress1,
  onChangeAddress2,
  city,
  onChangeCity,
  province,
  onChangeProvince,
  zipcode,
  onChangeZipcode
}) => {
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [editingImageIndex, setEditingImageIndex] = useState(null);
  const { user } = useContext(AppContext);
  const modalRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await countryService.fetchAll();
        setCountries(data);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value
    );
    onChangeCountryValue && onChangeCountryValue(selectedCountry.name);
    onChangeCountryCode && onChangeCountryCode(selectedCountry.contactCode);
  };

  useEffect(() => {
    if (variants?.length === 0) {
      setVariants([
        {
          option: "",
          price: "",
          weight: "",
          weight_unit: "",
          height: "",
          height_unit: "",
          width: "",
          width_unit: "",
          length: "",
          length_unit: "",
          sku: "",
          inventory: "",
        },
      ]);
    }
  }, [open, setVariants]);

  const resetSelectedImage = () => {
    setSelectedImages([]);
    setImageUrls([]);
    setEditingImageIndex();
  };



  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose && handleClose();
        setSelectedClient(null);
        setSelectedImages([]);
        setImageUrls([]);
        setEditingImageIndex(null);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      ref={modalRef}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            color: "#000000",
          }}
        >
          <CustomTypography fontWeight={500} fontSize={isMobile() ? 16 : 24}>
            {modalHeader ?? `${nameValue ? "Edit" : "Create"} ${title}`}
          </CustomTypography>
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 20 }} />

      <DialogContent
        ref={modalRef}
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          scrollBehavior: "smooth",
        }}
        sx={{
          overflowY: "auto",
          "&::WebkitScrollbar": {
            width: "7px",
          },
          "&::WebkitScrollbar-track": {
            backgroundColor: "#D9D9D9",
            borderRadius: 14,
          },
          "&::WebkitScrollbar-thumb": {
            backgroundColor: "#273894",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={carrier ? 12 : 6}>
            <CustomTypography fontSize={15}>
              {nameHeader ?? `${title} Name`}&nbsp;
              <span style={{ color: "red" }}>*</span>
            </CustomTypography>

            <TextField
              sx={{ marginTop: 1, fontSize: 13, fontStyle: "Poppins" }}
              size="small"
              placeholder={`Enter ${title} Name`}
              variant="outlined"
              value={nameValue}
              onChange={(e) => onChangeName && onChangeName(e.target.value)}
              fullWidth
              autoFocus
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>

          {supplier ? (
            <>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>
                  Select Client <span style={{ color: "red" }}>*</span>
                </CustomTypography>
                <ClientAutocomplete
                  selectedClient={selectedClientData}
                  onClientChange={(e) => {
                    onSelectClient && onSelectClient(e);
                  }}
                  errorMessage={formErrors.selectedClient}
                  width={"100%"}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>
                  Min Order Quantity <span style={{ color: 'red' }}>*</span>
                </CustomTypography>
                <TextField
                  sx={{ marginTop: 1, fontSize: 13, fontStyle: 'Poppins' }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder={`Enter Min order quantity`}
                  //label="Name"
                  variant="outlined"
                  value={nameValue}
                  onChange={(e) => onChangeMinOrdQty && onChangeMinOrdQty(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.min_order_qty}
                  helperText={formErrors.min_order_qty}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>
                  Max Order Quantity <span style={{ color: 'red' }}>*</span>
                </CustomTypography>
                <TextField
                  sx={{ marginTop: 1, fontSize: 13, fontStyle: 'Poppins' }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder={`Enter Max order quantity`}
                  //label="Name"
                  variant="outlined"
                  value={nameValue}
                  onChange={(e) => onChangeMaxOrdQty && onChangeMaxOrdQty(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.max_order_qty}
                  helperText={formErrors.max_order_qty}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Email</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="email"
                  size="small"
                  placeholder="Enter Email"
                  variant="outlined"
                  value={emailValue}
                  onChange={(e) =>
                    onChangeEmail && onChangeEmail(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Address 1</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="address"
                  size="small"
                  placeholder="Enter Address 1"
                  variant="outlined"
                  value={address1}
                  onChange={(e) =>
                    onChangeAddress1 && onChangeAddress1(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.address1}
                  helperText={formErrors.address1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Address 2</CustomTypography>
                <TextField
                  disabled={selectedClientData?.id ? true : false}
                  sx={{ marginTop: 1 }}
                  type="address"
                  size="small"
                  placeholder="Enter Address 2"
                  variant="outlined"
                  value={address2}
                  onChange={(e) =>
                    onChangeAddress2 && onChangeAddress2(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.address2}
                  helperText={formErrors.address2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>City</CustomTypography>
                <TextField
                  disabled={selectedClientData?.id ? true : false}
                  sx={{ marginTop: 1 }}
                  type="address"
                  size="small"
                  placeholder="Enter City"
                  variant="outlined"
                  value={city}
                  onChange={(e) => onChangeCity && onChangeCity(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.city}
                  helperText={formErrors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Province</CustomTypography>
                <TextField
                  disabled={selectedClientData?.id ? true : false}
                  sx={{ marginTop: 1 }}
                  type="address"
                  size="small"
                  placeholder="Enter Province"
                  variant="outlined"
                  value={province}
                  onChange={(e) =>
                    onChangeProvince && onChangeProvince(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.province}
                  helperText={formErrors.province}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Country</CustomTypography>
                <TextField
                  disabled={selectedClientData?.id ? true : false}
                  sx={{ marginTop: 1 }}
                  type="address"
                  size="small"
                  placeholder="Enter Country"
                  variant="outlined"
                  value={country}
                  onChange={(e) =>
                    onChangeCountry && onChangeCountry(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.country}
                  helperText={formErrors.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Zipcode</CustomTypography>
                <TextField
                  disabled={selectedClientData?.id ? true : false}
                  sx={{ marginTop: 1 }}
                  type="address"
                  size="small"
                  placeholder="Enter Zipcode"
                  variant="outlined"
                  value={zipcode}
                  onChange={(e) =>
                    onChangeZipcode && onChangeZipcode(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.zipcode}
                  helperText={formErrors.zipcode}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}

          {client ? (
            <>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Email</CustomTypography>
                <TextField
                  disabled={selectedClientData?.email ? true : false}
                  sx={{ marginTop: 1 }}
                  type="email"
                  size="small"
                  placeholder="Enter Email"
                  variant="outlined"
                  value={emailValue}
                  onChange={(e) =>
                    onChangeEmail && onChangeEmail(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Country</CustomTypography>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <TextField
                    disabled={country ? true : false}
                    select
                    sx={{ marginTop: 1 }}
                    size="small"
                    placeholder="Enter Country"
                    variant="outlined"
                    value={country}
                    onChange={handleCountryChange}
                    fullWidth
                    error={!!formErrors.country}
                    helperText={formErrors.country}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.name} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomTypography fontSize={15}>Code</CustomTypography>
                <TextField
                  inputMode="numeric"
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  variant="outlined"
                  value={countryCodeValue}
                  onChange={(e) =>
                    onChangeCountryCode && onChangeCountryCode(e.target.value)
                  }
                  fullWidth
                  disabled={countryCodeValue ? true : false}
                  style={{ backgroundColor: "#DEDEDE" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTypography fontSize={15}>
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </CustomTypography>
                <TextField
                  inputMode="numeric"
                  sx={{ marginTop: 1 }}
                  type="mobileNumber"
                  size="small"
                  placeholder="Enter Mobile No."
                  variant="outlined"
                  value={mobileNoValue}
                  onChange={(e) =>
                    onChangeMobileNo && onChangeMobileNo(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.mobileNumber}
                  helperText={formErrors.mobileNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>
                  Business Name <span style={{ color: "red" }}>*</span>
                </CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  placeholder="Enter Business Name"
                  variant="outlined"
                  value={businessName}
                  onChange={(e) =>
                    onChangeBusiness && onChangeBusiness(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.businessName}
                  helperText={formErrors.businessName}
                />
              </Grid>
              {companyUrl ? (
                <Grid item xs={12} sm={6}>
                  <CustomTypography fontSize={15}>
                    Company Website
                  </CustomTypography>
                  <TextField
                    disabled
                    sx={{ marginTop: 1 }}
                    type="text"
                    size="small"
                    placeholder="Enter Company Url"
                    variant="outlined"
                    value={companyUrl}
                    fullWidth
                    autoFocus
                    error={!!formErrors.companyUrl}
                    helperText={formErrors.companyUrl}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {zone ? (
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
                Zone Number <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={{ marginTop: 1 }}
                type="number"
                inputProps={{ min: 0 }}
                size="small"
                placeholder="Enter Zone Number"
                variant="outlined"
                value={zoneValue}
                onChange={(e) =>
                  onChangeZoneNumber && onChangeZoneNumber(e.target.value)
                }
                fullWidth
                autoFocus
                error={!!formErrors.zoneNumber}
                helperText={formErrors.zoneNumber}
              />
            </Grid>
          ) : (
            <></>
          )}
          {port ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">Country</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Enter Country Name "
                  type="text"
                  fullWidth
                  onChange={(e) =>
                    onChangeCountry && onChangeCountry(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">Location</Typography>
                <TextField
                  size="small"
                  placeholder="Enter Location"
                  margin="dense"
                  type="text"
                  fullWidth
                  onChange={(e) =>
                    onChangeLocation && onChangeLocation(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">
                  Postal Code
                </Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Enter Postal Code"
                  type="number"
                  fullWidth
                  onChange={(e) =>
                    onChangePostalCode && onChangePostalCode(e.target.value)
                  }
                  inputProps={{ min: 0 }}
                  error={!!formErrors.postal_code}
                  helperText={formErrors.postal_code}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">
                  Type <span style={{ color: "red" }}>*</span>
                </Typography>
                <Autocomplete
                  onChange={(e, newValue) => handleChangeType(newValue)}
                  sx={{ marginTop: 1 }}
                  size="small"
                  options={portTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Port type"
                      variant="outlined"
                      error={!!formErrors.type}
                      helperText={formErrors.type}
                    />
                  )}
                />
              </Grid>
            </>
          ) : service ? (
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
                Choose Carrier <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                size="small"
                value={selectedCarrierService?.name}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedCarrierService(newValue);
                  } else {
                    setCarrierSearchValue("");
                    fetchCarrierData("", 0); // Fetch all carriers again if necessary
                  }
                }}
                ListboxProps={{
                  onScroll: handleScroll, // Attach scroll handler here
                }}
                options={carrierList}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">{option.name}</Typography>
                  </li>
                )}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "input") {
                    setCarrierSearchValue(newInputValue);
                    setPage(0); // Reset pagination on new search
                    setCarrier([]); // Clear previous carrier list
                  } else if (reason === "clear") {
                    setSelectedCarrierService(null);
                    setPage(0);
                    setCarrierSearchValue("");
                    fetchCarrierData("", 0); // Fetch all carriers again if necessary
                  }
                }}
                onOpen={() => {
                  setPage(0); // Reset the page to 0
                  fetchCarrierData("", 0); // Fetch all data when the dropdown is opened
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Carrier"
                    margin="dense"
                    error={!!formErrors.carrier}
                    helperText={formErrors.carrier}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : (
            <></>
          )}

          {!client && user?.role === "admin" && product ? (
            <Grid item xs={12} sm={product ? 12 : 6}>
              <Typography className="form-lable-style">
                Choose Client
              </Typography>

              <ClientAutocomplete
                selectedClient={selectedClientData}
                onClientChange={(e) => {
                  onSelectClient && onSelectClient(e);
                }}
              />
              {formErrors.vendor && (
                <FormHelperText error>{formErrors.client}</FormHelperText>
              )}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: 15 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClose && handleClose();
            setSelectedClient({});
            resetSelectedImage();
          }}
          // className="cancel-button-style"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            onClickAdd && onClickAdd(e);
            setSelectedClient(null);
            resetSelectedImage();
          }}
          className="add-button-style"
          style={{ textTransform: "none" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal
