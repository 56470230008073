import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Card,
  CardContent,
  Button,
  Grid,
  Popover,
  useTheme,
  Icon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import OrderDetailsModal from "../orderDetailsModal.js";
import DeleteConfirmationModal from "../deleteConfirmationModal/index.js";
import { orderService } from "../../utils/services/orderService.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isMobile } from "../../globalStyles/mobile/index.js";
import WarehouseList from "../warehouse-order-list/index.js";
import SortingIcon from "../../globalStyles/icons/sorting-icon.js";
import FilterModal from "../filterModal/index.js";
import CustomPagination from "../pagination/index.js";
import UpIcon from "@mui/icons-material/ArrowUpwardOutlined";
import DownIcon from "@mui/icons-material/ArrowDownwardOutlined";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    // color: theme.palette.common.white,
    padding: "6px",
    //textAlign: "start",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
    // textAlign: "left",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, isExpandable }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#F6F6F6",
    border: "1px solid #EAECF0",
  },

  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const OrderList = ({
  data,
  onStatusFilterChange,
  style = {},
  fetchOrderData,
  onChangeFilter,
  filterData,
  setPage,
  setRowsPerPage,
  rowsPerPage = 15,
  page = 0,
  dataCount,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [trackingDetails, setTrackingDetails] = useState({});
  const [errorData, setErrorData] = useState({});
  const [edit, setEdit] = useState(false);
  const [showCancelOrderConfirmModal, setShowCancelOrderConfirmModal] =
    useState(false);
  const [orderTrail, setOrderTrail] = useState([]);
  const [showWarehouseDataModal, setShowWarehouseDataModal] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: "",
    filter: [],
    sort: 0,
    id: "_id",
    displayKey: "name",
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: "",
    type: "list",
  });
  const startIndex = page * rowsPerPage;
  const openPopover = Boolean(anchorElPopover);
  const navigate = useNavigate();
  const theme = useTheme();
  const defaultColor = "#D13438";
  const iconColor = theme.palette?.primary?.main || defaultColor;

  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilterModal(true);
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey));
  };
  const handleSaveFilter = (
    selectedOptions,
    filterKey,
    sortOrder,
    searchKey
  ) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey,
    });
    handleCloseFilter();
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilterModal(false);
  };

  const handlePopoverOpen = (event, order) => {
    event.stopPropagation();
    setAnchorElPopover(event.currentTarget);
    setSelectedOrder(order);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const handleViewClick = (order, action) => {
    //setSelectedOrder(order)
    setModalOpen(true);
    if (action == "edit") {
      setEdit(true);
    }
  };
  let dummyTrackingDetails = [
    { label: "Ordered", sl: 1 },
    { label: "Accepted", sl: 2 },
    { label: "Confirmed", sl: 3 },
    { label: "Fulfilled", sl: 4 },
    { label: "Shipped", sl: 5 },
    { label: "In Transit", sl: 6 },
    { label: "Out for Delivery", sl: 7 },
    { label: "Delivered", sl: 8 },
  ];
  const handleRowClick = async (order) => {
    const orderId = order.order_id;
    setErrorData({ error: false });
    if (expandedRow === orderId) {
      setExpandedRow(null);
    } else {
      if (!trackingDetails[orderId]) {
        // Simulate fetching tracking details with dummy data

        if (order.error) {
          setErrorData({
            error: true,
            message: order.message,
          });
        } else {
          setErrorData({ error: false });
          setTrackingDetails((prevDetails) => ({
            ...prevDetails,
            [orderId]: dummyTrackingDetails,
          }));
        }
        // Replace with actual call to orderService.trackOrder(orderId);
      }
      setExpandedRow(orderId);
    }
  };
  const cancelOrder = async () => {
    try {
      const response = await orderService.cancel({
        ...selectedOrder,
      });
      if (response.status === "Cancelled") {
        console.log(response.status);
        toast.success("Order cancelled successfully");
      }
      setShowCancelOrderConfirmModal(false);
      fetchOrderData();
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };

  const fetchOrderTrail = async (id) => {
    const response = await orderService.fetchOrderTrail(id);
    setOrderTrail(response);
  };
  const getFormattedDate = (date) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const time = `${hours}:${minutes}`;
    const dayName = days[d.getDay()];
    const day = d.getDate();
    const monthName = months[d.getMonth()];
    const dateStr = `${dayName} ${day} ${monthName}`;

    return { time, dateStr };
  };
  const handleOrderFLow = (order) => {
    localStorage.setItem("path", `/order-flow/${selectedOrder?.order_id}`);
    navigate(`/order-flow/${selectedOrder?.order_id}`);
  };
  const handleOrderTrack = () => {
    const trackingData = {
      status: `${selectedOrder?.shipments[0]?.tracking_number}`,
    };
    const queryParams = new URLSearchParams(trackingData).toString();
    localStorage.setItem(
      "path",
      `/order-tracking-history/${selectedOrder?._id}?${queryParams}`
    );
    navigate(`/order-tracking-history/${selectedOrder?._id}?${queryParams}`);
  };
  useEffect(() => {
    setOrderData(
      data?.map((order) => ({
        ...order,
        action: (
          <Box sx={{ display: "flex", boxShadow: "none" }}>
            <IconButton
              aria-label="more actions"
              onClick={(e) => handlePopoverOpen(e, order)}
              sx={{
                color: openPopover ? "primary" : "inherit",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorElPopover)}
              anchorEl={anchorElPopover}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: isMobile() ? "bottom" : "top",
                horizontal: isMobile() ? "center" : "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  border: "1px solid #EAECF0",
                  borderRadius: "4px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  boxShadow: "none",
                  gap: 2,
                }}
              >
                {/* Order Flow */}
                <Tooltip title="Order flow">
                  <Button
                    color="primary"
                    onClick={(e) => {
                      handleOrderFLow();
                      e.stopPropagation();
                      // console.log(order)
                      // // e.stopPropagation();
                      // //setSelectedOrder(order)
                      // localStorage.setItem('path', `/order-flow/${order?.order_id}`)
                      // navigate(`/order-flow/${order?.order_id}`)
                    }}
                  >
                    Order Flow
                  </Button>
                </Tooltip>

                {/* Warehouse Order */}
                <Tooltip title="Warehouse Order">
                  <Button
                    color="primary"
                    onClick={(e) => {
                      // e.stopPropagation();
                      //setSelectedOrder(order);
                      setShowWarehouseDataModal(true);
                    }}
                  >
                    Warehouse
                  </Button>
                </Tooltip>

                {/* Track Order */}
                <Tooltip title="Track">
                  <Button
                    color="primary"
                    onClick={(e) => {
                      const trackingData = {
                        status: `${order?.shipments[0]?.tracking_number}`,
                      };
                      const queryParams = new URLSearchParams(
                        trackingData
                      ).toString();
                      localStorage.setItem(
                        "path",
                        `/order-tracking-history/${order?._id}?${queryParams}`
                      );
                      navigate(
                        `/order-tracking-history/${order?._id}?${queryParams}`
                      );
                    }}
                  >
                    Order Track
                  </Button>
                </Tooltip>

                {/* View Order */}
                <Tooltip title="View">
                  <Button
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleViewClick(order);
                    }}
                  >
                    Order Details
                  </Button>
                </Tooltip>

                <Tooltip
                  title={
                    ["Ordered", "Accepted"].includes(order.status) ||
                    (order.error && order.status !== "Cancelled")
                      ? "Cancel"
                      : ""
                  }
                >
                  <Button
                    style={{
                      color:
                        ["Ordered", "Accepted"].includes(order.status) ||
                        (order.error && order.status !== "Cancelled")
                          ? "#D13438"
                          : "grey",
                      padding: 0,
                      background:
                        ["Ordered", "Accepted"].includes(order.status) ||
                        (order.error && order.status !== "Cancelled")
                          ? "#D1343833"
                          : "#FFF",
                      cursor: !(
                        ["Ordered", "Accepted"].includes(order.status) ||
                        (order.error && order.status !== "Cancelled")
                      )
                        ? "not-allowed"
                        : "pointer",
                    }}
                    aria-label="cancel"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        ["Ordered", "Accepted"].includes(order.status) ||
                        (order.error && order.status !== "Cancelled")
                      ) {
                        setSelectedOrder(order);
                        setShowCancelOrderConfirmModal(true);
                      }
                    }}
                  >
                    Order Cancel
                  </Button>
                </Tooltip>
              </Box>
            </Popover>
          </Box>
        ),
      }))
    );
  }, [data, anchorElPopover, navigate]);

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data?.map((row, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffff",
              borderRadius: 4,
              position: "relative",
            }}
          >
            <CardContent>
              <Box position="absolute" top={2} right={5}>
                {row?.action}
              </Box>
              <Box display="flex" flexDirection="column">
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Order Id
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color={row.error ? "#ba090c" : "#333333"}
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {row?.order_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Customer
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      :{" "}
                      {`${row?.customer?.first_name || ""} ${
                        row?.customer?.last_name || ""
                      }`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Order Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {dayjs(row?.order_date).format("YYYY-MM-DD")}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Shipment ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      :{" "}
                      {[
                        ...new Set(row?.shipments?.map((x) => x?.shipment)),
                      ].join(", ")}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {row?.status}
                    </Typography>
                  </Grid>
                  {row?.error ? (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Error
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {row.message}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
              {/* <Box mt={2} justifyContent={"center"} display="flex">
                  <Tooltip title="Order flow">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        localStorage.setItem(
                          "path",
                          `/order-flow/${row?.order_id}`
                        );
                        navigate(`/order-flow/${row?.order_id}`);
                      }}
                      sx={{
                        backgroundColor: "#FFDEC3",
                        width: 32,
                        height: 32,
                        borderRadius: 2,
                        marginRight: 1,
                      }}
                    >
                      <AccountTreeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Warehouse Order">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowWarehouseDataModal(true);
                        setSelectedOrder(row);
                      }}
                      sx={{
                        backgroundColor: "#FFDEC3",
                        width: 32,
                        height: 32,
                        borderRadius: 2,
                        marginRight: 1,
                      }}
                    >
                      <WarehouseIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Track">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        const trackingData = {
                          status: `${row.shipments[0]?.tracking_number}`,
                          timestamp: new Date().toISOString(),
                        };
                        const queryParams = new URLSearchParams(
                          trackingData
                        ).toString();
                        localStorage.setItem(
                          "path",
                          `/order-tracking-history/${row?._id}?${queryParams}`
                        );
                        navigate(
                          `/order-tracking-history/${row?._id}?${queryParams}`
                        );
                      }}
                      sx={{
                        backgroundColor: "#FFDEC3",
                        width: 32,
                        height: 32,
                        borderRadius: 2,
                      }}
                    >
                      <OrderTrackingIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewClick(row, "view");
                      }}
                      sx={{
                        backgroundColor: "#C3E1FF",
                        width: 32,
                        height: 32,
                        borderRadius: 2,
                        marginLeft: 1,
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip> */}
              {/* <Tooltip title="Edit">
                    <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 3,
                        marginLeft: 10
                      }}
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation()

                        handleViewClick(row, 'edit')
                      }}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip> */}

              {/* <Tooltip title="Cancel">
                    <IconButton
                      // disabled={
                      //   !["Ordered", "Accepted"].includes(row.status)
                      // }
                      style={{
                        color:
                          ["Ordered", "Accepted"].includes(row.status) ||
                          (row.error && row.status != "Cancelled")
                            ? "#D13438"
                            : "grey",
                        padding: 0,
                        background:
                          ["Ordered", "Accepted"].includes(row.status) ||
                          (row.error && row.status != "Cancelled")
                            ? "#D1343833"
                            : "#FFF",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: "0px 0px 4px 0px #00000040",
                        marginTop: 3,
                        cursor: !(
                          ["Ordered", "Accepted"].includes(row.status) ||
                          (row.error && row.status != "Cancelled")
                        )
                          ? "not-allowed"
                          : "pointer",
                      }}
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("huhuu");

                        if (
                          ["Ordered", "Accepted"].includes(row.status) ||
                          (row.error && row.status != "Cancelled")
                        ) {
                          setSelectedOrder(row);
                          setShowCancelOrderConfirmModal(true);
                        }
                      }}
                    >
                      <CancelIcon></CancelIcon>
                    </IconButton>
                  </Tooltip> */}
              {/* </Box> */}
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };
  return (
    <>
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: `calc(100vh - 285px)`,
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
        }}
      >
        {isMobile() ? (
          <MobileCardView data={orderData} />
        ) : (
          <TableContainer
            // className="custom-container"
            sx={{
              maxHeight: "100%",
              overflowY: "scroll",
              paddingInline: 0.4,
              // paddingBottom: 10,
              //paddingRight: "16px",
              // paddingBottom: window.innerHeight < 650 ? 8 : 1,
              ...style,
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: "1px solid #EAECF0",
              }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      SL NO
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {filterData.columns.find((x) => x.key === "order_id")
                        ?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === "order_id")
                          ?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        ORDER ID
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, "order_id")}
                      >
                        <SortingIcon fill={iconColor || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {filterData.columns.find(
                        (x) => x.key === "shipping_address.name"
                      )?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find(
                          (x) => x.key === "shipping_address.name"
                        )?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        CUSTOMER NAME
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) =>
                          handleOpenFilter(event, "shipping_address.name")
                        }
                      >
                        <SortingIcon fill={iconColor || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {filterData.columns.find(
                        (x) => x.key === "shipping_address.province"
                      )?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find(
                          (x) => x.key === "shipping_address.province"
                        )?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        CUSTOMER STATE
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) =>
                          handleOpenFilter(event, "shipping_address.province")
                        }
                      >
                        <SortingIcon fill={iconColor || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {filterData.columns.find((x) => x.key === "order_date")
                        ?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === "order_date")
                          ?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        ORDER DATE
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) =>
                          handleOpenFilter(event, "order_date")
                        }
                      >
                        <SortingIcon fill={iconColor || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {filterData.columns.find(
                        (x) => x.key === "shipments.shipment"
                      )?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find(
                          (x) => x.key === "shipments.shipment"
                        )?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        SHIPMENT ID
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) =>
                          handleOpenFilter(event, "shipments.shipment")
                        }
                      >
                        <SortingIcon fill={iconColor || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        STATUS
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, "status")}
                      >
                        <SortingIcon fill={iconColor || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    ></Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {orderData?.map((row, index) => {
                  if (row.status === "Cancelled") {
                    dummyTrackingDetails = [
                      { label: "Ordered", sl: 1 },
                      { label: "Cancelled", sl: 2 },
                    ];
                  } else {
                    dummyTrackingDetails = [
                      { label: "Ordered", sl: 1 },
                      { label: "Accepted", sl: 2 },
                      { label: "Confirmed", sl: 3 },
                      { label: "Fulfilled", sl: 4 },
                      { label: "Shipped", sl: 5 },
                      { label: "In Transit", sl: 6 },
                      { label: "Out for Delivery", sl: 7 },
                      { label: "Delivered", sl: 8 },
                    ];
                  }
                  let progress = dummyTrackingDetails.find(
                    (x) => x.label === row.status
                  );
                  return (
                    <>
                      <StyledTableRow
                        sx={{ cursor: "pointer" }}
                        isExpandable={expandedRow === row.order_id}
                        onClick={() => {
                          if (expandedRow === row.order_id) {
                            setExpandedRow(null);
                          } else {
                            handleRowClick(row);
                            fetchOrderTrail(row?._id);
                          }
                        }}
                        key={row.order_id}
                      >
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                            textAlign={"start"}
                          >
                            {startIndex + index + 1}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color={row.error ? "#ba090c" : "#667085"}
                            fontWeight={400}
                            fontFamily={"poppins"}
                            //textAlign={"start"}
                          >
                            {row.order_id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {`${row.customer?.first_name || ""} ${
                              row.customer?.last_name || ""
                            }`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {`${row.shipping_address?.province || ""}`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {dayjs(row.order_date).format("YYYY-MM-DD")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {new Set(row?.shipments?.map((x) => x.shipment))}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.status ? (
                            <Typography
                              fontSize="12px"
                              fontWeight={400}
                              fontFamily={"poppins"}
                            >
                              <span
                                style={{
                                  padding: "5px 10px",
                                  display: "inline-block",
                                  backgroundColor:
                                    row.status === "Confirmed"
                                      ? "#D7E9FF"
                                      : row.status === "Shipped"
                                      ? "#F6F6F6"
                                      : row.status === "Cancelled"
                                      ? "#FFDEDF"
                                      : "",
                                  color:
                                    row.status === "Confirmed"
                                      ? "#0070FF"
                                      : row.status === "Shipped"
                                      ? "#037847"
                                      : row.status === "Cancelled"
                                      ? "#D13438"
                                      : "#333333",
                                  borderRadius: "14px",
                                }}
                              >
                                {row.status}
                              </span>
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{ width: "3%", textAlign: "center" }}
                        >
                          {row?.action}
                        </StyledTableCell>
                      </StyledTableRow>
                      {expandedRow === row.order_id && (
                        <TableRow key={row._id}>
                          <TableCell
                            style={{ width: "100%" }}
                            colSpan={8}
                            //style={{ padding: 0, borderBottom: "none" }}
                          >
                            <Collapse
                              in={expandedRow === row.order_id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box
                                marginBottom={6}
                                marginTop={1}
                                marginLeft={10}
                                sx={{
                                  maxWidth: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  minHeight: 70,
                                }}
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {errorData.error &&
                                  errorData.message.length ? (
                                    <Typography>{errorData.message}</Typography>
                                  ) : (
                                    [...dummyTrackingDetails]?.map(
                                      (item, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              flexDirection: "row",
                                              display: "flex",
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          >
                                            <div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    progress?.sl >= item?.sl
                                                      ? row.status ===
                                                        "Cancelled"
                                                        ? "red"
                                                        : "green"
                                                      : "#AAA7A7",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  height: 30,
                                                  width: 30,
                                                  borderRadius: 100,
                                                }}
                                              >
                                                <div style={{ color: "#FFF" }}>
                                                  {index + 1}
                                                </div>
                                              </div>
                                              <div>
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    left: -15,
                                                  }}
                                                >
                                                  {item?.label}
                                                </span>
                                              </div>
                                              <div>
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    left: -5,
                                                    marginTop: 20,
                                                  }}
                                                >
                                                  {orderTrail.find(
                                                    (x) =>
                                                      x.status === item.label
                                                  )
                                                    ? getFormattedDate(
                                                        orderTrail.find(
                                                          (x) =>
                                                            x.status ===
                                                            item.label
                                                        )?.parent?.createdAt
                                                      ).time
                                                    : ""}
                                                  <br />
                                                  <span
                                                    style={{
                                                      position: "absolute",
                                                      right: -40,
                                                      minWidth: 100,
                                                    }}
                                                  >
                                                    {orderTrail.find(
                                                      (x) =>
                                                        x.status === item.label
                                                    )
                                                      ? getFormattedDate(
                                                          orderTrail.find(
                                                            (x) =>
                                                              x.status ===
                                                              item.label
                                                          )?.parent?.createdAt
                                                        ).dateStr
                                                      : ""}
                                                  </span>
                                                </span>
                                              </div>
                                              {/* <div>
                                            <span
                                              style={{
                                                position: 'absolute',
                                                left: -10,
                                                marginTop: 20
                                              }}
                                            >
                                              {orderTrail.find((x) => x.status === item.label)
                                                ? getFormattedDate(
                                                    orderTrail.find((x) => x.status === item.label)?.createdAt
                                                  ).dateStr
                                                : ''}
                                            </span>
                                          </div> */}
                                            </div>
                                            {index + 1 ===
                                            dummyTrackingDetails?.length ? (
                                              <></>
                                            ) : (
                                              <div
                                                style={{
                                                  height: 1,
                                                  width: "82%",
                                                  position: "absolute",
                                                  left: "15%",
                                                  top: 15,
                                                  border:
                                                    progress?.sl >= item?.sl
                                                      ? `1px dotted ${
                                                          row.status ===
                                                          "Cancelled"
                                                            ? "red"
                                                            : "green"
                                                        }`
                                                      : "1px dotted #C0C3D7",
                                                }}
                                              ></div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )
                                  )}
                                </div>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableScrollLock={true}
      >
        <FilterModal
          open={openFilterModal}
          onClose={handleCloseFilter}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleSaveFilter}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedfilterData?.key}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
          type={selectedfilterData?.type}
        />
      </Popover>

      <OrderDetailsModal
        open={modalOpen}
        edit={edit}
        fetchOrderData={fetchOrderData}
        onClose={() => {
          setModalOpen(false);
          setEdit(false);
        }}
        order={selectedOrder}
      />
      <DeleteConfirmationModal
        handleDelete={() => cancelOrder()}
        handleClose={() => setShowCancelOrderConfirmModal(false)}
        title={`Are you sure to cancel this order ${selectedOrder?.order_id}`}
        open={showCancelOrderConfirmModal}
        deleteButtonTitle={"Cancel Order"}
      ></DeleteConfirmationModal>
      <WarehouseList
        handleClose={() => setShowWarehouseDataModal(false)}
        openModal={showWarehouseDataModal}
        selectedOrder={selectedOrder}
      ></WarehouseList>
    </>
  );
};

export default OrderList;
