import React, { useEffect, useState,  useCallback } from 'react'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import CloseIcon from '@mui/icons-material/Close'
import { shippingPriceService } from '../../utils/services/shippingPriceService'
import { shippingCostService } from '../../utils/services/shippingCostService'
import ClientAutocomplete from '../clientAutocomplete'
import debounce from 'lodash.debounce'
import { toast } from 'react-toastify'
import ShipmentServiceAutocomplete from '../shipmentServiveAutocomplete'
import { isMobile } from '../../globalStyles/mobile'
const ShipmentCostModal = ({ open, handleClose, onAddData, selectedShipmentCost, onSelectClient }) => {
  const [shipmentService, setShipmentService] = useState({ type: '' })
  const [selectedClient, setSelectedClient] = useState({
    name: '',
    business_name: ''
  })
  const [costDetails, setCostDetails] = useState({
    min_weight: '',
    max_weight: '',
    price: ''
  })
  const [serviceData, setServiceData] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [serviceSearchValue, setServiceSearchValue] = useState('')
  const clientId = localStorage.getItem('client_id')


  const fetchServiceData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true)
      try {
        if (selectedClient?._id) {
          const response = await shippingPriceService.fetch(
            15,
            pageNumber * 15,
            selectedClient._id,
            searchQuery
          )
          if (pageNumber === 0) {
            setServiceData(response.data)
          } else {
            setServiceData((prevData) => [...prevData, ...response.data])
          }
          setTotal(response.total)
        } else {
          setServiceData([])
        }
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [selectedClient?._id]
  )

  useEffect(() => {
    if (selectedClient?._id) {
      fetchServiceData(serviceSearchValue, page)
    }
  }, [serviceSearchValue, page, fetchServiceData, selectedClient?._id])

  useEffect(() => {
    if (open) {
      setPage(0)
      setServiceData([])
      fetchServiceData(serviceSearchValue, 0)
    }
  }, [serviceSearchValue, open, fetchServiceData])
  const handleScroll = (event) => {
    if (event && event.target) {
      const bottom =
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
      if (bottom && serviceData.length < total && !loading) {
        setPage((prevPage) => prevPage + 1)
      }
    } else {
      console.error('Scroll event or event.target is null')
    }
  }

  // const handleServiceSearchChange = (event) => {
  //   if (event && event.target) {
  //     const value = event.target.value;
  //     setServiceSearchValue(value); // Update the search value
  //     setPage(0); // Reset the page to 0
  //     setServiceData([]); // Clear the current service data

  //     // Fetch new service data based on the updated search value
  //     fetchServiceData(value, 0);
  //   } else {
  //     console.error(
  //       "Event or event.target is null in handleServiceSearchChange"
  //     );
  //   }
  // };

  useEffect(() => {
    // fetchServiceData();
    setCostDetails({
      min_weight: '',
      max_weight: '',
      price: ''
    })
    return () => {
      setFormErrors({})
      setCostDetails({
        min_weight: '',
        max_weight: '',
        price: ''
      })
    }
  }, [])

  const validateForm = () => {
    const errors = {}
    if (!shipmentService) {
      errors.shipment_service = 'Service Type is required'
    }

    if (costDetails.min_weight === '') {
      errors.min_weight = 'Min Weight is required'
    } else if (costDetails.min_weight < 0) {
      errors.min_weight = 'Min Weight must be greater than 0'
    }
    if (costDetails.max_weight === '') {
      errors.max_weight = 'Max Weight is required'
    } else if (costDetails.max_weight < 0) {
      errors.max_weight = 'Max Weight must be greater than 0'
    }
    // if (Number(costDetails.min_weight) > Number(costDetails.max_weight)) {
    //   errors.min_weight = "Min Weight must be less than Max Weight";
    //   errors.max_weight = "Max Weight must be greater than Min Weight";
    // }
    if (
      costDetails.min_weight >= 0 &&
      costDetails.max_weight >= 0 &&
      costDetails.min_weight > costDetails.max_weight
    ) {
      errors.min_weight = 'Min Weight must be less than Max Weight'
      errors.max_weight = 'Max Weight must be greater than Min Weight'
    }

    if (!costDetails.price) {
      errors.price = 'Price is required'
    } else if (isNaN(costDetails.price)) {
      errors.price = 'Price must be a number'
    } else if (costDetails.price < 0) {
      errors.price = 'Price must be greater than 0'
    }

    if (!clientId && !selectedClient?.name) {
      errors.selectedClient = 'Client is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  useEffect(() => {
    if (selectedShipmentCost) {
      setShipmentService(
        selectedShipmentCost?.shipment_service || {
          type: selectedShipmentCost?.shipment_service?.type,
          _id: selectedShipmentCost?.shipment_service?._id
        }
      )
      setCostDetails(
        selectedShipmentCost?.pricing || {
          min_weight: '',
          max_weight: '',
          price: ''
        }
      )
      setSelectedClient({
        name: selectedShipmentCost?.client_id?.name || '',
        _id: selectedShipmentCost?.client_id?._id || '',
        business_name: selectedShipmentCost?.client_id?.business_name || ''
      })
    } else {
      initialData()
    }
  }, [selectedShipmentCost])

  const initialData = () => {
    setShipmentService('')
    setCostDetails({ min_weight: '', max_weight: '', price: '' })
    setSelectedClient(null)
    setFormErrors({})
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCostDetails((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }
const handleEditData = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  // Check if the selected shipment service belongs to the selected client
  const serviceBelongsToClient = serviceData.some(
    (service) => service._id === shipmentService._id
  );

  if (!serviceBelongsToClient) {
    toast.error(
      "The selected shipment service does not belong to the selected client."
    );
    return;
  }

  try {
    await shippingCostService.edit(selectedShipmentCost._id, {
      shipment_service: shipmentService._id,
      pricing: costDetails,
      client_id: selectedClient?._id,
    });
    onAddData && onAddData();
    toast.success("Shipment Cost edited successfully!");
    initialData();
  } catch (e) {
    toast.error("Failed to edit Shipment Cost");
    console.error(e);
  }

  initialData();
  handleClose();
};


  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const client_id = clientId ? clientId : selectedClient?._id
      await shippingCostService.create({
        shipment_service: shipmentService._id,
        pricing: costDetails,
        client_id: selectedClient?._id
      })
      onAddData && onAddData()
      toast.success("Shipment Cost created successfully!")
      handleClose()
      initialData()
    } catch (e) {
      toast.error("Failed to create Shipment Cost")
      console.error(e)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: isMobile()?16:24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedShipmentCost ? "Edit" : "Create"} Shipment Cost
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Choose Client <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <ClientAutocomplete
              hideLabel={true}
              selectedClient={selectedClient}
              onClientChange={(client) => {
                setSelectedClient(client);
                onSelectClient && onSelectClient(client);
              }}
              errorMessage={formErrors.selectedClient}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Shipment Service <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <ShipmentServiceAutocomplete
              selectedClient={selectedClient}
              shipmentService={shipmentService}
              setShipmentService={setShipmentService}
              formErrors={formErrors}
              errorMessage={formErrors.shipment_service}
              hideLabel={true}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card style={{ marginBottom: 16, backgroundColor: "#EEEEEE" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <CustomTypography
                      color="#11193F"
                      fontSize="14px"
                      fontWeight={400}
                    >
                      {"Min Weight (Kg)"}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </CustomTypography>
                    <TextField
                      name="min_weight"
                      type="number"
                      style={{ backgroundColor: "#FFFFFF" }}
                      size="small"
                      value={costDetails.min_weight}
                      onChange={handleInputChange}
                      inputProps={{ min: 0 }}
                      fullWidth
                      margin="dense"
                      error={!!formErrors.min_weight}
                      placeholder="Enter Minimum Weight"
                    />
                    {!!formErrors.min_weight && (
                      <FormHelperText error>
                        {formErrors.min_weight}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTypography
                      color="#11193F"
                      fontSize="14px"
                      fontWeight={400}
                    >
                      {" Max Weight(Kg)"}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </CustomTypography>
                    <TextField
                      name="max_weight"
                      type="number"
                      style={{ backgroundColor: "#FFFFFF" }}
                      size="small"
                      value={costDetails.max_weight}
                      onChange={handleInputChange}
                      disabled={costDetails.min_weight === ""}
                      inputProps={{ min: costDetails.min_weight }}
                      fullWidth
                      margin="dense"
                      error={!!formErrors.max_weight}
                      placeholder="Enter Maximum Weight"
                    />
                    {!!formErrors.max_weight && (
                      <FormHelperText error>
                        {formErrors.max_weight}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTypography
                      color="#11193F"
                      fontSize="14px"
                      fontWeight={400}
                    >
                      {"Price (USD)"} <span style={{ color: "red" }}>*</span>
                    </CustomTypography>
                    <TextField
                      name="price"
                      type="number"
                      style={{ backgroundColor: "#FFFFFF" }}
                      size="small"
                      value={costDetails.price}
                      onChange={handleInputChange}
                      inputProps={{ min: 0 }}
                      fullWidth
                      margin="dense"
                      error={!!formErrors.price}
                      placeholder="Enter Price"
                    />
                    {!!formErrors.price && (
                      <FormHelperText error>{formErrors.price}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            color: "#007DFF",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedShipmentCost ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedShipmentCost ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShipmentCostModal
