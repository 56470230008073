import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import AddModal from "../../components/add-modal";
import { clientService } from "../../utils/services/clientService";
import ClientList from "../../components/client-list";
import TablePagination from "@mui/material/TablePagination";
import { CircularProgress, Box} from "@mui/material";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import { isMobile } from "../../globalStyles/mobile";
import CustomPagination from "../../components/pagination";

const initialData = {
  name: "",
  contactCode: "",
  email: "",
  contactNumber: "",
  businessName: "",
  address1: "",
  address2: "",
  city: "",
  zipcode: "",
  state: "",
  country: "",
  companyUrl: "",
};

export default function Client() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [viewMode, setViewMode] = useState("list");

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (validRegex.test(input)) {
      return true;
    } else {
      toast.error("Invalid email address!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneno.test(inputtxt)) {
      return true;
    } else {
      return false;
    }
  }
  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Client name is required";
    }
    // if (!formData.email) {
    //   errors.email = "Client email is required";
    // }
    // if (!formData.address1) {
    //   errors.address = "Client address is required";
    // }
    // if (!formData.state) {
    //   errors.state = "Client state is required";
    // }
    // if (!formData.city) {
    //   errors.city = "Client city is required";
    // }
    if (!formData.businessName) {
      errors.businessName = "Client Business Name is required";
    }
    // if (!formData.country) {
    //   errors.country = "Client country is required";
    // }
    // if (!formData.zipcode) {
    //   errors.zipCode = "Client zip code is required";
    // }
    // if (!ValidateEmail(formData.email)) {
    //   errors.email = "Enter a valid email";
    // }
    if (!phonenumber(formData.contactNumber)) {
      errors.mobileNumber = "Enter a valid number";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const InitialData = () => {
    setFormData(initialData);
    setSelectedClient(null);
    setLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await clientService.fetch(
        rowsPerPage,
        skip,
        searchValue
      );
      setClientData(response.data);
      setDataCount(response.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [page, rowsPerPage, searchValue]);

  useEffect(() => {
    if (selectedClient) {
      const contactParts = selectedClient.contact.split("-");
      const contactCode = contactParts.length > 1 ? contactParts[0] : "";
      const contactNumber =
        contactParts.length > 1 ? contactParts[1] : contactParts[0];

      setFormData({
        name: selectedClient.name,
        email: selectedClient.email,
        contactCode: contactCode || "",
        contactNumber: contactNumber || "",
        address1: selectedClient.address1,
        address2: selectedClient.address2,
        state: selectedClient.state,
        country: selectedClient.country,
        zipcode: selectedClient.zipcode,
        businessName: selectedClient.business_name,
        city: selectedClient.city,
        companyUrl: selectedClient?.company_website,
      });
    }
  }, [selectedClient]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const createClient = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);
      const contact = `${formData.contactCode}-${formData.contactNumber}`;
      if (selectedClient) {
        await clientService.edit(
          {
            name: formData.name,
            email: formData.email,
            address1: formData.address1,
            address2: formData.address2,
            state: formData.state,
            country: formData.country,
            zipcode: formData.zipcode,
            contact,
            city: formData.city,
            business_name: formData.businessName,
          },
          selectedClient._id
        );
        toast.success("Client Edited successfully!");
      } else {
        await clientService.create({
          name: formData.name,
          email: formData.email,
          address1: formData.address1,
          address2: formData.address2,
          state: formData.state,
          country: formData.country,
          zipcode: formData.zipcode,
          contact,
          city: formData.city,
          business_name: formData.businessName,
        });
        toast.success("Client Created successfully!");
      }
      fetchClientData();
      InitialData();
      setShowAddModal(false);
    } catch (error) {
      console.error("Client create/edit failed:", error);
      InitialData();
      setShowAddModal(false);
    }
  };

  const deleteClient = async (e) => {
    try {
      setLoading(true);
      await clientService.delete(e._id);
      fetchClientData();
      InitialData();
    } catch (error) {
      console.error("Delete failed:", error);
      InitialData();
    }
  };

  const NoDataImage = () => (
    <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
      <img
        src="no_data_client.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );

  return (
    <DashboardContainer
      onSearchData={(x) => {
        setSearchValue(x.trim() ? x : "");
        setPage(0);
      }}
      // onClickAddButton={() => {
      //   setShowAddModal(true)
      //   setFormErrors({})
      // }}
      hideAddButton
      hideUploadButton={true}
      header={"Clients"}
      hideFilterClient={true}
      setViewMode={setViewMode}
      title={`${isMobile() ? "+ Add" : "+ Add Client"}`}
      client
      onClickRefresh={fetchClientData}
    >
      <AddModal
        companyUrl={formData?.companyUrl}
        mobileNoValue={formData.contactNumber}
        countryCodeValue={formData.contactCode}
        addressValue1={formData.address1}
        addressValue2={formData.address2}
        country={formData.country}
        zipCode={formData.zipcode}
        state={formData.state}
        onChangeMobileNo={(e) => handleInputChange("contactNumber", e)}
        onChangeCountryCode={(e) => handleInputChange("contactCode", e)}
        onChangeAddress1={(e) => handleInputChange("address1", e)}
        onChangeAddress2={(e) => handleInputChange("address2", e)}
        onChangeCountryValue={(e) => handleInputChange("country", e)}
        onChangeState={(e) => handleInputChange("state", e)}
        onChangeZipCode={(e) => handleInputChange("zipcode", e)}
        selectedClientData={selectedClient}
        emailValue={formData.email}
        nameValue={formData.name}
        onChangeEmail={(e) => handleInputChange("email", e)}
        client
        title={"Client"}
        onClickAdd={createClient}
        onChangeName={(e) => handleInputChange("name", e)}
        handleClose={() => {
          InitialData();
          setShowAddModal(false);
        }}
        open={showAddModal}
        formErrors={formErrors}
        onChangeBusiness={(e) => handleInputChange("businessName", e)}
        onChangeCity={(e) => handleInputChange("city", e)}
        businessName={formData.businessName}
        city={formData.city}
      ></AddModal>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {clientData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <>
          <ClientList
            onSuccess={fetchClientData}
            onChangeStatus={fetchClientData}
            handleDeleteClient={deleteClient}
            client
            onDeleteData={fetchClientData}
            handleEditClient={(e) => {
              setShowAddModal(true);
              setSelectedClient(e);
              setFormErrors({});
            }}
            data={clientData}
            viewMode={viewMode}
          />
        </>
      )}
      {/* <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount <= rowsPerPage * (page + 1),
            },
          },
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  );
}
