export default function buildQuery(obj, page) {
  let query = [];
  if (obj.limit) {
    query.push(`$limit=${obj.limit}`);
  } else {
    query.push("$limit=15");
  }
  if (obj.skip) {
    query.push(`$skip=${obj.skip}`);
  } else {
    if (page) {
      query.push(`$skip=${page * obj.limit || 15}`);
    } else {
      query.push(`$skip=0`);
    }
  }

  if (obj.columns) {
    obj.columns.forEach((x) => {
      if (x.type == "date-range") {
        query.push(`${x.key}[$gte]=${x.filter.startDate}`);
        query.push(`${x.key}[$lte]=${x.filter.endDate}`);
      } else {
        x.filter
          // .filter((xx) => xx && !(x.excludeValues || []).includes(xx))
          .forEach((xx) => {
            query.push(`${x.key}[$in][]=${xx}`);
          });
      }
      if (x.sort) {
        query.push(`$sort[${x.key}]=${x.sort}`);
      }
    });
  }
  if (obj.populate) {
    obj.populate.forEach((x) => {
      query.push(`$populate=${x}`);
    });
  }
  return query.join("&");
}
