import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { providerService } from "../../utils/services/providerService";
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '../../globalStyles/icons/deleteIcon'

const InitialData =
{
  name: "",
  type: '',
  provider_details: [{ key: '', value: '' }]
}

const ProviderModal = ({ open, handleClose, onAddData, selectedProvider }) => {
  const [provider, setProvider] = useState(InitialData);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!provider.name) {
      errors.name = "Name is required";
    }
    if (!provider.type) {
      errors.type = "Type is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedProvider) {
      setProvider(selectedProvider)
    } else {
      resetData();
    }
  }, [selectedProvider]);

  const resetData = () => {
    setProvider(InitialData)
    setFormErrors({});
  };

  const addNewProviderDetails = () => {
    setProvider((prevState) => ({
      ...prevState,
      provider_details: [...prevState.provider_details, { key: "", value: "" }],
    }));
  };

  const manageProviderDetails = (i, field, value) => {
    setProvider((prevState) => {
      const updatedDetails = [...prevState.provider_details];
      updatedDetails[i][field] = value;
      return { ...prevState, provider_details: updatedDetails };
    });
  };

  const removeProviderDetail = (i) => {
    setProvider((prevState) => {
      const updatedDetails = prevState.provider_details.filter((_, idx) => idx !== i);
      return { ...prevState, provider_details: updatedDetails };
    });
  };
  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await providerService.edit(selectedProvider._id, {
        name: provider.name,
        type: provider.type,
        provider_details: provider.provider_details
      });
      onAddData && onAddData();
      toast.success("Provider edited successfully!");
      resetData();
    } catch (error) {
      console.error(error);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await providerService.create({
        name: provider.name,
        type: provider.type,
        provider_details: provider.provider_details

      });
      onAddData && onAddData();
      toast.success("Provider created successfully!");
      resetData();
      handleClose();
    } catch (error) {
      toast.error("Failed to create event.");
      console.error(error);
    }
  };


  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        resetData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle id="form-dialog-title" style={{ fontSize: 24 }}>
          {selectedProvider ? "Edit" : "Create"} Provider
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              size="small"
              name="name"
              value={provider.name}
              onChange={(e) =>
                setProvider({ ...provider, name: e.target.value })
              }
              fullWidth
              placeholder="Enter Name"
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <CustomTypography>
                Type <span style={{ color: "red" }}>*</span>
              </CustomTypography>
              <Select
                size="small"
                margin="dense"
                value={provider.type}
                onChange={(e) =>
                  setProvider({ ...provider, type: e.target.value })
                }
                displayEmpty
                error={!!formErrors.type}
              >
                <MenuItem value="WMS">WMS</MenuItem>
                <MenuItem value="OMS">OMS</MenuItem>
                <MenuItem value="TMS">TMS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: 'center' }}>
              <CustomTypography
                style={{ textAlign: "left", fontWeight: 400 }}
                fontSize={16}
              >
                Provider Details
              </CustomTypography>
              <Button
                onClick={() => addNewProviderDetails()}
                style={{
                  textTransform: "none",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                <AddIcon
                  fontSize="small"
                  sx={{ color: (theme) => theme.palette.text.main }}
                />

              </Button>
            </Box>
            <Card
              style={{
                marginBottom: 10,
                backgroundColor: "#EEEEEEEE",
                marginTop: 10,
              }}
            >
              <CardContent>
                {provider.provider_details?.map((x, i) => (

                  <Grid container spacing={2} key={i}>

                    <Grid item xs={12} sm={6}>
                      <CustomTypography>
                        Key
                      </CustomTypography>
                      <TextField
                        size="small"
                        name="key"
                        value={x.key}
                        onChange={(e) => manageProviderDetails(i, 'key', e.target.value)}
                        fullWidth
                        placeholder="Enter Key"
                        margin="dense"
                      // error={!!formErrors.name}
                      // helperText={formErrors.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTypography>
                        Value
                      </CustomTypography>
                      <TextField
                        size="small"
                        name="value"
                        value={x.value}
                        onChange={(e) => manageProviderDetails(i, 'value', e.target.value)}
                        fullWidth
                        placeholder="Enter Value"
                        margin="dense"
                      // error={!!formErrors.name}
                      // helperText={formErrors.name}
                      />
                      {provider.provider_details.length > 1 && (
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <IconButton onClick={() => removeProviderDetail(i)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}
                    </Grid>

                  </Grid>
                ))}
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose();
            resetData();
          }}
          color="primary"
          variant="outlined"
          className="cancel-button-style"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedProvider ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          className="add-button-style"
          style={{ textTransform: "none" }}
        >
          {selectedProvider ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProviderModal;
