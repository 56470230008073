import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { portTypes } from "../../utils/portTypes";
import { portService } from "../../utils/services/portService";

const PortModal = ({ open, handleClose, onAddData, selectedPort }) => {
  const [portDetails, setPortDetails] = useState({
    name: "",
    country: "",
    location: "",
    postal_code: "",
    type: "",
  });
  const initialData = () => {
    setPortDetails({
      name: "",
      country: "",
      location: "",
      postal_code: "",
      type: "",
    });
    setFormErrors({});
  };
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!portDetails.name) errors.portName = "Port name is required.";
    if (!portDetails.type) errors.portType = "Port type is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedPort) {
      setPortDetails({
        name: selectedPort?.name || "",
        country: selectedPort?.country || "",
        location: selectedPort?.location || "",
        postal_code: selectedPort?.postal_code || "",
        type: selectedPort?.type || "",
      });
    } else {
      initialData();
    }
  }, [selectedPort]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPortDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await portService.edit(
        {
          name: portDetails.name,
          country: portDetails.country,
          location: portDetails.location,
          postal_code: portDetails.postal_code,
          type: portDetails.type,
        },
        selectedPort._id
      );
      onAddData && onAddData();
      toast.success("Port edited successfully!");
      initialData();
    } catch (e) {
      console.error(e);
    }
    initialData();
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await portService.create({
        name: portDetails.name,
        country: portDetails.country,
        location: portDetails.location,
        postal_code: portDetails.postal_code,
        type: portDetails.type,
      });
      onAddData && onAddData();
      toast.success("Port created successfully!");
      initialData();
    } catch (e) {
      toast.error("Failed to create port.");
      console.error(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        initialData();
        handleClose();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedPort ? "Edit Port" : "Create Port"}
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Port Name </CustomTypography>
            <TextField
              size="small"
              name="name"
              value={portDetails.name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.portName}
              helperText={formErrors.portName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Country Name </CustomTypography>
            <TextField
              size="small"
              name="country"
              value={portDetails.country}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Location </CustomTypography>
            <TextField
              size="small"
              name="location"
              value={portDetails.location}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Postal Code </CustomTypography>
            <TextField
              type="number"
              size="small"
              name="postal_code"
              value={portDetails.postal_code}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.postalCode}
              helperText={formErrors.postalCode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Port Type</CustomTypography>
            <Autocomplete
              value={portDetails.type}
              options={portTypes}
              onChange={handleInputChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="dense"
                  error={!!formErrors.portType}
                  helperText={formErrors.portType}
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose();
            initialData();
          }}
          variant="outlined"
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedPort ? handleEditData(e) : handleSave(e))}
          variant="contained"
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {selectedPort ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PortModal;
