import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import { eventService } from "../../utils/services/eventService";

const EventAutocomplete = ({
  selectedEvent,
  onEventChange,
  clientFilter,
  width = 200,
  label = "Select Event",
  errorMessage,
  hideLabel,
}) => {
  const [eventList, setEventList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchEventData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await eventService.fetch(
          10,
          pageNumber * 10,
          clientFilter?._id,
          searchQuery
        );
        if (pageNumber === 0) {
          setEventList(response.data);
        } else {
          setEventList((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    [clientFilter]
  );

  useEffect(() => {
    fetchEventData(searchValue, page);
  }, [searchValue, page, fetchEventData]);

  useEffect(() => {
    if (searchValue === "") {
      fetchEventData("", 0);
    }
  }, [searchValue, fetchEventData]);

  const handleEventChange = (event, value) => {
    if (value && value.name === "Load More") {
      return;
    }
    if (onEventChange) {
      onEventChange(value);
    }
    setSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setPage(0);
    setEventList([]);
  };

  const handleLoadMoreClick = () => {
    if (eventList.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const optionsWithLoadMore = [...eventList];
  if (eventList.length < total && eventList.length % 10 === 0) {
    optionsWithLoadMore.push({ name: "Load More" });
  }

  return (
    <Autocomplete
      size="small"
      id="event-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.code || ""}
      value={selectedEvent || null}
      onChange={handleEventChange}
      renderOption={(props, option) =>
        option.name === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.code}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorMessage}
          helperText={errorMessage}
          placeholder="Select RL Event Code"
          margin="dense"
          label={hideLabel ? "" : label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default EventAutocomplete;
