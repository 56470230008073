import axiosInstance from "../axiosInstance";

export const wmsService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post("/warehouse", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (id, limit, skip) => {
    try {
      let response = await axiosInstance.get(
        `/warehouse?$limit=${limit || 15}&$skip=${
          skip || 0
        }&client_id=${id}&$populate=provider`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/warehouse/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/warehouse/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
