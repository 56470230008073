import { SvgIcon } from "@mui/material";
import React from "react";

const ImportIcon = ({ fill }) => {

  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.7666 9.7334L9.89994 11.8667L12.0333 9.7334"
          stroke={fill}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.8999 3.33325V11.8083"
          stroke={fill} 
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6668 10.1499C16.6668 13.8332 14.1668 16.8166 10.0002 16.8166C5.8335 16.8166 3.3335 13.8332 3.3335 10.1499"
          stroke={fill}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ImportIcon;
