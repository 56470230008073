import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  useTheme
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'

const AddParameterDialog = ({ open, onClose, onSubmit }) => {
  const [name, setName] = useState('')
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const theme = useTheme()
  const defaultColor = '#D13438'
  const textColor = theme.palette?.text?.main || defaultColor
  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0]
    setFile(uploadedFile)
    setFileName(uploadedFile?.name || '')
  }

  const handleSubmit = () => {
    onSubmit({ name, file })
    handleCancel() // Reset the form and close the modal on submit
  }

  const handleCancel = () => {
    setName('')
    setFile(null)
    setFileName('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <DialogTitle fontSize={'20px'} fontFamily={'poppins'} fontWeight={500} color={'#333333'}>
          {'Add Parameter'}
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      <DialogContent>
        <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
          Name
        </CustomTypography>
        <TextField
          margin="dense"
          fullWidth
          label="Name"
          value={name}
          onChange={handleNameChange}
          size="small"
        />
        <div style={{ marginTop: 20 }}>
          <CustomTypography color={'#333333'} fontSize={14} fontWeight={400}>
            Upload File
          </CustomTypography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            {' '}
            <Button
              variant="contained"
              component="label"
              sx={{ mt: 2 }}
              style={{ textTransform: 'none', backgroundColor: '#EBEBEB' }}
            >
              {fileName ? (
                <CustomTypography>{fileName}</CustomTypography>
              ) : (
                <Typography fontFamily={'poppins'} fontSize={'14px'} fontWeight={400} color={'#000000'}>
                  Choose File
                </Typography>
              )}
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            {!fileName ? (
              <Typography
                fontFamily={'poppins'}
                fontSize={'14px'}
                fontWeight={400}
                color={'#757477'}
                textAlign={'center'}
                style={{ marginTop: 10 }}
              >
                No file choosen
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          style={{ textTransform: 'none', borderColor: 'red', color: 'red' }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          style={{ textTransform: 'none', color: textColor }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddParameterDialog
