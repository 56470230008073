import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  IconButton,
  Grid,
  Paper,
  Select,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import { tmsService } from "../../utils/services/tmsService";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";

const TMSManageForm = ({
  selectedClient,
  handleClose,
  selectedData,
  onAddProviderData,
  onDeleteSuccess,
  setLoading,
  providerList = [],
}) => {
  const InitialData = [
    {
      provider: {},
      endpoint: "",
      authDetails: [{ key: "", value: "" }],
    },
  ];
  const [providers, setProviders] = useState(InitialData);
  const handleAddProvider = () => {
    setProviders((prevProviders) => [
      ...prevProviders,
      {
        provider: {},
        endpoint: "",
        authDetails: [{ key: "", value: "" }],
      },
    ]);
  };

  const handleRemoveProvider = async (index, provider) => {
    setLoading(true);
    if (selectedData?.length) {
      try {
        const tmsResponse = await tmsService.delete(provider?._id);
        if (tmsResponse) {
          onDeleteSuccess && onDeleteSuccess();
          setProviders((prevProviders) =>
            prevProviders.filter((_, i) => i !== index)
          );
          setLoading(false);
          handleClose && handleClose();
        }
      } catch (error) {
        console.error("Delete failed:", error);
        handleClose && handleClose();
        throw error;
      }
    } else {
      setProviders((prevProviders) =>
        prevProviders.filter((_, i) => i !== index)
      );
      setLoading(false);
    }
  };

  const handleChange = (index, field, value) => {
    const newProviders = [...providers];
    newProviders[index][field] = value;
    setProviders(newProviders);
  };

  const addNewAuthDetails = (e, index) => {
    e.preventDefault();
    const newProviders = [...providers];
    newProviders[index].authDetails = [
      ...(newProviders[index].authDetails || []),
      { key: "", value: "" },
    ];
    setProviders(newProviders);
  };
  const manageAuthDetails = (index, authIndex, field, value) => {
    const newProviders = [...providers];
    newProviders[index].authDetails = newProviders[index].authDetails.map(
      (x, i) => (i == authIndex ? { ...x, [field]: value } : x)
    );
    setProviders(newProviders);
  };

  useEffect(() => {
    setProviders(selectedData);
  }, [selectedData]);

  async function addConfig(configurations) {
    for (const config of configurations) {
      if (selectedData?.length) {
        try {
          const response = await tmsService.edit(
            {
              provider: config?.provider,
              endpoint: config?.endpoint,
              authDetails: config.authDetails,
            },
            config?._id
          );
          console.log(
            `Successfully added configuration for ${config.provider}`
          );
          console.log(response.data);
          handleClose && handleClose(); // Optionally, log the response data
        } catch (error) {
          console.error(
            `Error adding configuration for ${config.provider}:`,
            error.message
          );
          return;
        }
      } else {
        try {
          const response = await tmsService.create(config);
          console.log(
            `Successfully added configuration for ${config.provider.name}`
          );
          console.log(response.data);
        } catch (error) {
          console.error(
            `Error adding configuration for ${config.provider}:`,
            error.message
          );
          return;
        }
      }

      setProviders(InitialData);
      onAddProviderData && onAddProviderData();
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    addConfig(
      providers.map((item) => ({ ...item, client_id: selectedClient?._id }))
    );
  };

  const removeAuthDetail = (providerIndex, detailIndex) => {
    setProviders((prevState) => {
      const newState = [...prevState];
      if (newState[providerIndex]?.authDetails) {
        newState[providerIndex].authDetails = newState[
          providerIndex
        ].authDetails.filter((_, i) => i !== detailIndex);
      }
      return newState;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {providers.map((provider, index) => (
        <Paper
          key={index}
          variant="outlined"
          style={{
            padding: "16px",
            marginTop: "16px",
            background: "#EEEEEE",
            borderRadius: "4px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                fontWeight={500}
              >{`Provider${index + 1}`}</Typography>
              <IconButton
                aria-label="delete"
                onClick={() => handleRemoveProvider(index, provider)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Select Provider <span style={{ color: "red" }}>*</span>
                </label>

                <Select
                  style={{
                    marginTop: 5,
                    fontSize: 13,
                    backgroundColor: "white",
                    height: 33,
                  }}
                  placeholder="Select Provider"
                  size="small"
                  type="text"
                  value={provider.provider}
                  onChange={(e) =>
                    handleChange(index, "provider", e.target.value)
                  }
                >
                  {providerList.map((data) => (
                    <MenuItem value={data._id} sx={{ height: 30 }}>
                      {data?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Endpoint <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Endpoint"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.endpoint}
                  onChange={(e) =>
                    handleChange(index, "endpoint", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                Authentication Details
              </label>

              {provider.authDetails?.map((x, i) => (
                <Grid container spacing={2} key={i} style={{ marginTop: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <label style={{ fontFamily: "Poppins", fontSize: 12 }}>
                      Key <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      placeholder="Key"
                      style={{
                        border: "1px solid #D1D1D1",
                        borderRadius: 5,
                        padding: 5,
                        marginTop: 5,
                        fontSize: 13,
                        width: "100%",
                      }}
                      type="text"
                      value={x.key}
                      onChange={(e) =>
                        manageAuthDetails(index, i, "key", e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <label style={{ fontFamily: "Poppins", fontSize: 12 }}>
                      Value <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ display: "flex", width: "100%" }}>
                      <input
                        placeholder="Value"
                        style={{
                          border: "1px solid #D1D1D1",
                          borderRadius: 5,
                          padding: 5,
                          marginTop: 5,
                          fontSize: 13,
                          width: "100%",
                        }}
                        type="text"
                        value={x.value}
                        onChange={(e) =>
                          manageAuthDetails(index, i, "value", e.target.value)
                        }
                      />
                      <IconButton
                        style={{
                          backgroundColor:
                            i === provider.authDetails.length - 1
                              ? "#DC585C"
                              : "transparent",
                          width: 30,
                          height: 30,
                          borderRadius: "4px",
                          marginLeft: 10,
                          color: "#fff",
                          marginTop: 3,
                        }}
                        onClick={(e) =>
                          i === provider.authDetails.length - 1
                            ? addNewAuthDetails(e, index)
                            : removeAuthDetail(index, i)
                        }
                      >
                        {i === provider.authDetails.length - 1 ? (
                          <AddIcon style={{ color: "#fff" }} />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
      ))}
      <div
        style={{
          marginTop: "16px",
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {selectedData?.length ? (
          <></>
        ) : (
          <Button onClick={handleAddProvider} style={{ textTransform: "none" }}>
            <CustomTypography
              fontSize={"14px"}
              color={"#0038FF"}
              fontWeight={400}
            >{`+ Add Provider`}</CustomTypography>
          </Button>
        )}
      </div>

      <DialogActions style={{ padding: "16px" }}>
        <Button
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </form>
  );
};

const TMSProviderModal = ({
  setOpen,
  onClose,
  selectedClient,
  selectedData,
  onAddProviderData,
  setLoading,
  loading,
  onDeleteSuccess,
  providerList,
}) => {
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <TMSManageForm
      onDeleteSuccess={onDeleteSuccess}
      setLoading={setLoading}
      loading={loading}
      onAddProviderData={onAddProviderData}
      selectedData={selectedData}
      handleClose={handleClose}
      selectedClient={selectedClient}
      providerList={providerList}
    />
  );
};

export default TMSProviderModal;
