import React, { useEffect, useState } from 'react'
import { JsonForms } from '@jsonforms/react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import { Box, createTheme, TextField, ThemeProvider, Typography } from '@mui/material'
import { isObjectControl, rankWith } from '@jsonforms/core'
import { generateJsonSchema, generateUiSchema } from '../../utils/jsonSchemaGenerator' // Import the generator functions

const classes = {
  container: {
    padding: '1em',
    width: '100%'
  },
  title: {
    textAlign: 'center',
    padding: '0.25em'
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  }
}

// Custom MUI theme with Poppins font and left-aligned headers
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif' // Ensure Poppins font applies globally
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textAlign: 'left' // Make all headers left-aligned
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          minWidth: 100,
          marginBottom: '1rem',
          '& .MuiInputBase-root': {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: '100px',
          padding: '8px',
          textAlign: 'left'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          minWidth: 100
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          minWidth: 100
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          marginTop: '10px'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginTop: '10px'
        }
      }
    }
  }
})

// Fallback Renderer for unhandled fields
const FallbackRenderer = ({ data, path }) => (
  <TextField
    value={typeof data === 'object' ? JSON.stringify(data, null, 2) : data}
    disabled
    fullWidth
    label={`Unhandled Field at ${path}`}
    multiline
  />
)

// Fallback Tester for lowest priority
const fallbackTester = () => -1

// Custom line item renderer
const customLineItemRenderer = ({ data, path }) => (
  <TextField
    label={`Line Item ${path}`}
    value={data}
    sx={{ minWidth: '100px' }}
    disabled
    onChange={(e) => {
      // Handle change here
    }}
  />
)

const renderers = [
  ...materialRenderers,
  {
    tester: rankWith(2, isObjectControl),
    renderer: customLineItemRenderer
  },
  {
    tester: fallbackTester,
    renderer: FallbackRenderer
  }
]

const JsonForm = ({ renderData, readonly = true, disableFields = [''], layout = 'vertical' }) => {
  // Generate schemas dynamically based on input data
  const [schema, setSchema] = useState(null)
  const [uiSchema, setUiSchema] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  // Regenerate schemas if renderData changes
  useEffect(() => {
    if (renderData) {
      // Show loader while updating the schema and data
      setLoading(true)

      // Simulate schema generation delay
      setTimeout(() => {
        setData(renderData)
        const newSchema = generateJsonSchema(renderData)
        const newUiSchema = generateUiSchema(renderData, {
          readonly: disableFields,
          layout
        })

        setSchema(newSchema)
        setUiSchema(newUiSchema)
        setLoading(false) // Stop loader after schemas are set
      }, 1000) // Optional delay to mimic real-world async operations
    }
  }, [renderData])

  // Clear data function
  const clearData = () => {
    setData({})
  }

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {loading ? (
          <CircularProgress color="primary" sx={{ position: 'absolute', top: 400 }} />
        ) : (
          <>
            <div style={classes.demoform}>
              <JsonForms
                readonly={readonly}
                schema={schema}
                uischema={uiSchema}
                data={data}
                renderers={renderers}
                cells={materialCells}
                onChange={({ data }) => setData(data)}
              />
            </div>
          </>
        )}
      </Box>
    </ThemeProvider>
  )
}

export default JsonForm
