import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Button from '@mui/material/Button/Button'
import { supplierService } from '../../utils/services/supplierService'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import UpIcon from '@mui/icons-material/ArrowUpwardOutlined'
import DownIcon from '@mui/icons-material/ArrowDownwardOutlined'
import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Icon
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import { isMobile } from '../../globalStyles/mobile'
import SupplierDetailsModal from '../supplier-details-modal'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SortingIcon from '../../globalStyles/icons/sorting-icon'
import FilterModal from '../filterModal'
import { useTheme } from '@mui/styles'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    // color: theme.palette.common.black,
    padding: '4px',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '4px',
    textAlign: 'start'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },
  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const SupplierTableCell = ({ supplier, handleViewClick, handleEditClick, handleDeleteClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const handleActionClick = (action) => {
    handlePopoverClose()
    switch (action) {
      case 'view':
        handleViewClick(supplier)
        break
      case 'edit':
        handleEditClick(supplier)
        break
        // case "delete":
        //   handleDeleteClick(supplier);
        break
      default:
        break
    }
  }

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? 'primary.main' : 'inherit'
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
          <Button onClick={() => handleActionClick('view')} style={{ textTransform: 'none' }}>
            <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
              View
            </Typography>
          </Button>
          <Button onClick={() => handleActionClick('edit')} style={{ textTransform: 'none' }}>
            <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
              Edit
            </Typography>
          </Button>
          {/* <IconButton
            onClick={() => handleActionClick("delete")}
            title="Delete"
          >
            <DeleteIcon />
          </IconButton> */}
        </Box>
      </Popover>
    </Box>
  )
}
const SupplierList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleDeleteClient,
  viewMode,
  selectedSupplier,
  setSelectedSupplier,
  onChangeFilter,
  filterData,
  currentPage,
  itemsPerPage
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openRow, setOpenRow] = useState(null)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [selectedSupplierData, setSelectedSupplierData] = useState([])
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const secondary_color = theme.palette?.secondary?.main || defaultColor
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [selectedFilterKey, setSelectedFilterKey] = useState('')
  const [filterOptions, setFilterOptions] = useState([])
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: '',
    name: '',
    filter: [],
    sort: 0,
    id: '_id',
    displayKey: 'name',
    fetchApi: () => {},
    options: [],
    loadMoreButton: false,
    searchKey: ''
  })
  const startIndex = currentPage * itemsPerPage
  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget)
    setSelectedFilterKey(filterKey)
    setOpenFilterModal(true)
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey))
  }
  const handleSaveFilter = (selectedOptions, filterKey, sortOrder, searchKey) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey
    })
    handleCloseFilter()
  }

  const handleCloseFilter = () => {
    setAnchorElFilter(null)
    setOpenFilterModal(false)
  }

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index)
  }

  const handleDeleteClick = (supplier) => {
    setSelectedSupplier(supplier)
    setOpenConfirmation(true)
  }

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await supplierService.delete(selectedSupplier._id)
      onDeleteData && onDeleteData()
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleViewClick = (supplier) => {
    setSelectedSupplier(supplier)
    setOpenViewModal(true)
  }
  useEffect(() => {
    setSelectedSupplierData(
      data?.map((supplier) => ({
        ...supplier,
        action: (
          <SupplierTableCell
            supplier={supplier}
            handleViewClick={handleViewClick}
            handleEditClick={onEditData}
            //handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: '1px',
        maxHeight: '100%',
        paddingBottom: 5,
        overflow: 'auto'
      }}
      container
      spacing={2}
    >
      {selectedSupplierData?.map((supplier) => (
        <Grid item xs={12} sm={6} md={3} key={supplier.id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={supplier?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {supplier?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                {supplier?.action}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data?.map((supplier, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: '#ffffff',
              borderRadius: 4,
              position: 'relative'
            }}
          >
            <CardContent>
              <Box position="absolute" top={2} right={5}>
                {supplier?.action}
              </Box>
              <Box display="flex" flexDirection="column" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Supplier Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}
                    >
                      : {supplier?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Client
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}
                    >
                      : {supplier?.client_id?.business_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}
                    >
                      : {supplier?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}
                    >
                      : {supplier?.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        // paddingBottom: 100,
        paddingBottom: 20,
        marginTop: 5
      }}
    >
      {isMobile() ? (
        <MobileCardView data={selectedSupplierData} />
      ) : (
        <TableContainer
          //  className="custom-container"
          sx={{
            // maxHeight: "70vh",
            overflowY: 'auto',
            paddingInline: 0.4,
            maxHeight: '100%'
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: '1px solid #EAECF0'
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                      //marginLeft: 20,
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'name')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'name')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      SUPPLIER NAME
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'name')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'email')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'email')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      EMAIL
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'email')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                    textAlign={'start'}
                  >
                    Address 1
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'address.city')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'address.city')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      CITY
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'address.city')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'province')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'province')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      PROVINCE
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'address.province')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'address.country')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'address.country')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      COUNTRY
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'address.country')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedSupplierData?.map((supplier, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={'Poppins'}
                      style={{ marginLeft: 30 }}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <Tooltip title={supplier?.name}>
                    <StyledTableCell>
                      <Typography
                        style={{
                          // marginLeft: 22,
                          maxWidth: 100,
                          whiteSpace: 'nowrap', // Ensure single line
                          overflow: 'hidden', // Prevent text overflow
                          textOverflow: 'ellipsis' // Show ellipsis for overflow text
                        }}
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        title={supplier?.name} // Add a title attribute to show full text on hover
                      >
                        {supplier?.name}
                      </Typography>
                    </StyledTableCell>
                  </Tooltip>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={'poppins'}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px'
                      }}
                    >
                      {supplier?.email}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={'poppins'}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '250px'
                      }}
                    >
                      {supplier?.address?.address1}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                      {supplier?.address?.city}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                      {supplier?.address?.province}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                      {supplier?.address?.country}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                    {supplier?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )

  return (
    <div sx={{ minWidth: '100%' }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: 'scroll',
            display: 'flex',
            maxHeight: isMobile() ? '60vh' : `calc(100vh - 250px)`,
            width: '100%',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::WebkitScrollbar': { width: 0 }
          }}
        >
          {viewMode === 'grid' ? renderGridView() : renderListView()}
        </div>
      )}
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableScrollLock={true}
      >
        <FilterModal
          open={openFilterModal}
          onClose={handleCloseFilter}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleSaveFilter}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedFilterKey}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
        />
      </Popover>
      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedSupplier?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            handleDeleteClient && handleDeleteClient(selectedSupplier)
            setOpenConfirmation(false)
          } else {
            confirmDelete(e)
          }
        }}
      />
      {/* <AssignClient
        selectedData={selectedSupplier}
        mapKey="supplier"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      /> */}

      <SupplierDetailsModal
        supplier={selectedSupplier}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  )
}

export default SupplierList
