import React, { useState, useEffect, useContext } from 'react'
import Container from '@mui/material/Container/Container'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import TextField from '@mui/material/TextField/TextField'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import { carrierServiceManagement } from '../../utils/services/serviceManagement'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { clientService } from '../../utils/services/clientService'

import AppContext from '../context/context'
import CloseIcon from '@mui/icons-material/Close'
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import AssignClient from '../client-assign'
import ServiceListDetailsModal from '../serviceListViewDetailsModal'
import { isMobile } from '../../globalStyles/mobile'

const ServiceList = ({
  data,
  onEditData,
  onDeleteData,
  carrierList,
  onSelectClient,
  viewMode,
  setCarrierSearchValue,
  fetchCarrierData,
  handleScroll,
  setPage,
  setCarrierList
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [serviceName, setServiceName] = useState('')
  const [selectedCarrier, setSelectedCarrier] = useState('') // State for selected carrier in Autocomplete
  const [openConfirmation, setOpenConfirmation] = useState(false) // State for confirmation dialog
  const [loading, setLoading] = useState(false)
  const [clientData, setClientData] = useState([])
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const [formErrors, setFormErrors] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const { user } = useContext(AppContext)
  const [selectedData, setSelectedData] = useState(null)
  const [openViewModal, setOpenViewModal] = useState(false)
  const clientId = localStorage.getItem('client_id')

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  useEffect(() => {
    fetchClientData()
  }, [])

  const handleEditClick = (carrier) => {
    setSelectedService(carrier)
    setServiceName(carrier.name)
    setSelectedCarrier({
      name: carrier?.carrier_id?.name,
      _id: carrier?.carrier_id?._id
    })
    setSelectedClient({
      name: carrier.client_id?.name,
      _id: carrier?.client_id?._id
    })
    setOpenModal(true)
    setFormErrors({})
  }

  const handleDeleteClick = (carrier) => {
    setSelectedService(carrier)
    setOpenConfirmation(true) // Open confirmation dialog before deleting carrier
  }

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await carrierServiceManagement.delete(selectedService._id)
      setLoading(false)
      onDeleteData && onDeleteData()
      setAnchorEl(null)
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
      setLoading(false)
      setOpenConfirmation(false)
    }
  }
  const handleClientAssignClick = () => {
    // setSelectedClient(client);
    setModalOpen(true)
  }

  const validateForm = () => {
    const errors = {}
    if (!serviceName) {
      errors.serviceName = 'Service name is required'
    }
    if (!selectedCarrier.name) {
      errors.selectedCarrier = 'Carrier is required'
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = "Please select Client";
    // }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleEditService = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      setLoading(true)
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await carrierServiceManagement.edit(
        serviceName,
        selectedService._id,
        selectedCarrier._id,
        client_id
      )
      setLoading(false)
      onEditData && onEditData()
    } catch (error) {
      console.error('Edit failed:', error)
      setLoading(false)
    }
    setOpenModal(false) // Close the modal after adding carrier
  }
  const serviceData = data?.map((service) => ({
    ...service,
    label: service.carrier_id?.name
  }))

  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_service.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '340px' }}
      />
    </div>
  )
  const handleViewClick = (service) => {
    {
      setSelectedService(service)
      setOpenViewModal(true)
    }
  }
  const renderGridView = () => (
    <Grid container spacing={2} sx={{ padding: 1, marginTop: 1 }}>
      {serviceData?.map((service) => (
        <Grid item xs={12} sm={6} md={3} key={service.id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={`${service?.name} (${service?.carrier_id?.name})`} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: '500'
                        }}
                        variant="h7"
                      >
                        {`${service?.name} (${service?.carrier_id?.name})`}
                      </Typography>
                    </div>
                  </Tooltip>
                  {/* <Tooltip title={service?.client_id?.name} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {user.role === "admin" ? (
                        <Typography
                          style={{
                            fontWeight: "500",
                          }}
                          variant="h7"
                        >
                          Client : {service?.client_id?.name}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip> */}
                </div>
                {user.role == 'admin' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 15,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="edit"
                      onClick={() => handleEditClick(service)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="delete"
                      onClick={() => handleDeleteClick(service)}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data?.map((service, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffff",
              borderRadius: 4,
            }}
          >
            <CardContent>
              <div style={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        fontSize={14}
                        fontFamily="poppins"
                        color="#333333"
                        fontWeight={600}
                        textAlign="left"
                      >
                        Service Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        fontSize={14}
                        fontFamily="poppins"
                        color="#606060"
                        fontWeight={500}
                        textAlign="left"
                      >
                        : {service?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        fontSize={14}
                        fontFamily="poppins"
                        color="#333333"
                        fontWeight={600}
                        textAlign="left"
                      >
                        Carrier Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        fontSize={14}
                        fontFamily="poppins"
                        color="#606060"
                        fontWeight={500}
                        textAlign="left"
                      >
                        : {service?.carrier_id?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} justifyContent={"flex-end"} display="flex" gap={2}>
                  <IconButton
                    style={{
                      color: "#007DFF",
                      padding: 0,
                      background: "#C3E1FF",
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                    aria-label="view"
                    onClick={() => {
                      handleViewClick(service);
                    }}
                  >
                    <img
                      style={{ height: 14, width: 20 }}
                      alt="view"
                      src="/eye.png"
                    ></img>
                  </IconButton>
                  {user.role == "admin" && (
                    <>
                      <IconButton
                        style={{
                          color: "#007613",
                          padding: 0,
                          background: "#00761333",
                          borderRadius: 5,
                          height: 30,
                          width: 30,
                          padding: 0,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleClientAssignClick();
                          setSelectedData(service);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="client"
                          src="/client-assign.png"
                        ></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#FEC50F",
                          padding: 0,
                          background: "#FEC50F33",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          boxShadow: "0px 0px 4px 0px #00000040",
                       
                        }}
                        aria-label="edit"
                        onClick={() => handleEditClick(service)}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#D13438",
                          padding: 0,
                          background: "#D1343833",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          
                        }}
                        aria-label="delete"
                        onClick={() => handleDeleteClick(service)}
                      >
                        <img
                          style={{ height: 15, width: 15 }}
                          alt="delete"
                          src="/delete.png"
                        ></img>
                      </IconButton>
                    </>
                  )}
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  }

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 100
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            marginTop: 1,
            maxHeight: '70vh',
            overflowY: 'auto',
            paddingInline: 0.4
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0 5px',
              paddingBottom: 7
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: '#EEEEEE',
                  borderRadius: 8,
                  borderWidth: 1,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: 8,
                    padding: 10
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Service Name
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 10
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Carrier Name
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    padding: 10
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Action
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((service, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#fff',
                      borderRadius: 8,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 4
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {service?.name}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 4
                      }}
                    >
                      <CustomTypography fontSize="14px" color="#333333" fontWeight={400}>
                        {service?.carrier_id?.name}
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 4
                      }}
                    >
                      <IconButton
                        style={{
                          color: '#007DFF',
                          padding: 0,
                          background: '#C3E1FF',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleViewClick(service)
                        }}
                      >
                        <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                      </IconButton>
                      {user.role == 'admin' && (
                        <>
                          <IconButton
                            style={{
                              color: '#007613',
                              padding: 0,
                              background: '#00761333',
                              borderRadius: 5,
                              marginLeft: 10,
                              height: 30,
                              width: 30,
                              padding: 0,
                              boxShadow: '0px 0px 4px 0px #00000040'
                            }}
                            aria-label="view"
                            onClick={() => {
                              handleClientAssignClick()
                              setSelectedData(service)
                            }}
                          >
                            <img
                              style={{ height: 14, width: 20 }}
                              alt="client"
                              src="/client-assign.png"
                            ></img>
                          </IconButton>
                          <IconButton
                            style={{
                              color: '#FEC50F',
                              padding: 0,
                              background: '#FEC50F33',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 15,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="edit"
                            onClick={() => handleEditClick(service)}
                          >
                            <BorderColorIcon sx={{ height: 20, width: 20 }} />
                          </IconButton>
                          <IconButton
                            style={{
                              color: '#D13438',
                              padding: 0,
                              background: '#D1343833',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 10,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="delete"
                            onClick={() => handleDeleteClick(service)}
                          >
                            <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
  return (
    <div sx={{ minWidth: "100%" }}>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {serviceData?.length == 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              maxHeight: "70vh",
              width: "100%",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::WebkitScrollbar": { width: 0 },
            }}
          >
            {viewMode === "grid" ? renderGridView() : renderListView()}
          </div>

          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            maxWidth="md"
            fullWidth
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>{"Edit Service"}</DialogTitle>
              <IconButton onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: 10 }} />

            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTypography
                    fontSize={14}
                    fontWeight={400}
                    color={"#11193F"}
                  >
                    Service Name <span style={{ color: "red" }}>*</span>
                  </CustomTypography>

                  <TextField
                    size="small"
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    error={!!formErrors.serviceName}
                    helperText={formErrors.serviceName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTypography
                    fontSize={14}
                    fontWeight={400}
                    color={"#11193F"}
                  >
                    Choose Carrier <span style={{ color: "red" }}>*</span>
                  </CustomTypography>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option._id === value.id
                    }
                    size="small"
                    value={selectedCarrier}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedCarrier({
                          carrier_id: newValue,
                          ...newValue,
                        });
                      } else {
                        setSelectedCarrier(null);
                        setCarrierSearchValue("");
                        fetchCarrierData("", 0); // Fetch all carriers again if necessary
                      }
                    }}
                    ListboxProps={{
                      onScroll: handleScroll, // Attach scroll handler here
                    }}
                    options={carrierList}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography variant="body1">{option.name}</Typography>
                      </li>
                    )}
                    onOpen={() => {
                      setPage(0); // Reset the page to 0
                      setCarrierList([]); // Clear current list
                      fetchCarrierData("", 0); // Fetch all data when the dropdown is opened
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "input") {
                        setCarrierSearchValue(newInputValue);
                        setCarrierList([]); // Clear current list
                        fetchCarrierData(newInputValue, 0); // Fetch data based on input value
                      } else if (reason === "clear") {
                        setSelectedCarrier(null);
                        setPage(0);
                        setCarrierSearchValue("");
                        setCarrierList([]); // Clear current list
                        fetchCarrierData("", 0); // Fetch all carriers again when input is cleared
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Carrier"
                        margin="dense"
                        error={!!formErrors.selectedCarrier}
                        helperText={formErrors.selectedCarrier}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* {user.role === "admin" ? (
                  <Autocomplete
                    sx={{ marginBottom: 2 }}
                    size="small"
                    value={selectedClient}
                    onChange={(event, newValue) => {
                      setSelectedClient(newValue);
                      onSelectClient && onSelectClient(newValue);
                    }}
                    options={clientData}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography variant="body1">{option.name}</Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Client"
                        margin="normal"
                        error={!!formErrors.selectedClient}
                        helperText={formErrors.selectedClient}
                      />
                    )}
                  />
                ) : (
                  <></>
                )} */}
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 10 }}>
              <Button
                onClick={() => setOpenModal(false)}
                className="cancel-button-style"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="add-button-style"
                variant="contained"
                color="primary"
                onClick={(e) => handleEditService(e)}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <DeleteConfirmationModal
            DeleteConfirmationModal
            title={`Are you sure you want to delete ${selectedService?.name} ?`}
            open={openConfirmation}
            handleClose={() => setOpenConfirmation(false)}
            handleDelete={(e) => confirmDelete(e)}
          />
          <AssignClient
            selectedData={selectedData}
            mapKey="service_type"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          />
          <ServiceListDetailsModal
            service={selectedService}
            open={openViewModal}
            onClose={() => setOpenViewModal(false)}
          />
        </>
      )}
    </div>
  );
}

export default ServiceList
