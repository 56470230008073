import React, { useState, useEffect, useContext } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Tooltip,
  Box,
  Typography,
  Card,
  CardContent,
  Grid
} from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import AppContext from '../context/context'
import CustomTypography from '../../globalStyles/fonts'
import { customCleranceService } from '../../utils/services/customCleranceService'
import CustomCleranceDetailsModal from '../customer-clerance-details-modal'
import { isMobile } from '../../globalStyles/mobile'
import CustomPagination from '../pagination'

const CustomerClearanceList = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedCustomClearance,
  setSelectedCustomClearance
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null)
  const [customClearanceData, setCustomClearanceData] = useState([])
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const { user } = useContext(AppContext)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [openTriggeredModal, setOpenTriggeredModal] = useState(false)

  const deleteData = async () => {
    try {
      await customCleranceService.delete(deletingItemId)
      setOpenConfirmation(false)
      onDeleteData && onDeleteData()
    } catch (error) {
      console.error('Delete failed:', error)
    }
  }
  const handleViewClick = (clearance) => {
    setSelectedCustomClearance(clearance)
    setOpenViewModal(true)
  }
  const handleTriggeredClick = (clearance) => {
    setSelectedCustomClearance(clearance)
    setOpenTriggeredModal(true)
    console.log('I am triggered')
  }
  useEffect(() => {
    setCustomClearanceData(
      data?.map((item) => ({
        ...item,
        action: (
          <Box sx={{display:"flex",gap:2,justifyContent:"center"}}>
            <IconButton
              style={{
                color: '#007DFF',
                padding: 0,
                background: '#C3E1FF',
                height: 30,
                width: 30,
                borderRadius: 5,
                boxShadow: '0px 0px 4px 0px #00000040'
              }}
              aria-label="view"
              onClick={() => {
                handleViewClick(item)
              }}
            >
              <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
            </IconButton>
            <IconButton
              style={{
                color: '#007613',
                padding: 0,
                background: '#00761333',
                height: 30,
                width: 30,
                borderRadius: 5,
                boxShadow: '0px 0px 4px 0px #00000040'
              }}
              aria-label="view"
              onClick={() => {
                handleTriggeredClick(item)
              }}
            >
              <img style={{ height: 14, width: 20 }} alt="view" src="/tap.png"></img>
            </IconButton>

            {user.role == 'admin' ? (
              <>
                <IconButton
                  onClick={() => onEditData && onEditData(item)}
                  style={{
                    color: '#FEC50F',
                    padding: 0,
                    background: '#FEC50F33',
                    height: 30,
                    width: 30,
                    borderRadius: 5,
                    boxShadow: '0px 0px 4px 0px #00000040'
                  }}
                >
                  <BorderColorIcon sx={{ height: 20, width: 20 }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSelectedCustomClearance(item)
                    setDeletingItemId(item._id)
                    setOpenConfirmation(true)
                  }}
                  style={{
                    color: '#D13438',
                    padding: 0,
                    background: '#D1343833',
                    height: 30,
                    width: 30,
                    borderRadius: 5,
                    boxShadow: '0px 0px 4px 0px #00000040'
                  }}
                >
                  <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                </IconButton>
              </>
            ) : (
              <></>
            )}
          </Box>
        )
      }))
    )
  }, [data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  // const renderGridView = () => (
  //   <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
  //     {customClearanceData?.map((x) => (
  //       <Grid item xs={12} sm={6} md={3} key={x._id}>
  //         <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
  //           <CardContent>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "space-between",
  //                 alignItems: "center",
  //                 width: "100%",
  //               }}
  //             >
  //               <div
  //                 style={{
  //                   alignItems: "flex-start",
  //                   width: "30%",
  //                   display: "flex",
  //                   flexDirection: "column",
  //                   // marginLeft: 15,
  //                 }}
  //               >
  //                 <Tooltip title={x?.shipment_service?.type} arrow>
  //                   <div
  //                     style={{
  //                       fontWeight: "500",
  //                       whiteSpace: "nowrap",
  //                       textOverflow: "ellipsis",
  //                       maxWidth: "50%",
  //                       overflow: "hidden",
  //                     }}
  //                   >
  //                     <Typography style={{ fontWeight: "500" }} variant="h7">
  //                       {x?.shipment_service?.type}
  //                     </Typography>
  //                   </div>
  //                 </Tooltip>
  //               </div>
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   width: "60%",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                 }}
  //               >
  //                 {" "}
  //                 {x.action}
  //               </div>
  //             </div>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //     ))}
  //   </Grid>
  // );

  // const renderListView = () => (
  //   <TableContainer
  //     sx={{
  //       marginTop: 1,
  //       maxHeight: "60vh",
  //       overflowY: "auto",
  //       paddingInline: 0.4,
  //     }}
  //   >
  //     <Table
  //       stickyHeader
  //       aria-label="sticky table"
  //       sx={{
  //         borderCollapse: "separate",
  //         borderSpacing: "0 5px",
  //         paddingBottom: 7,
  //       }}
  //     >
  //       <TableHead>
  //         <TableRow
  //           style={{
  //             backgroundColor: "#EEEEEE",
  //             borderRadius: 8,
  //             boxShadow: "0px 0px 4px 0px #00000040",
  //             borderTopRightRadius: 8,
  //             borderBottomRightRadius: 8,
  //           }}
  //         >
  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderTopLeftRadius: "8px",
  //               borderBottomLeftRadius: 8,
  //               borderBottom: "none",
  //               textAlign: "center",
  //               padding: 10,
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Air-Waybill Number
  //             </CustomTypography>
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderBottom: "none",
  //               textAlign: "center",
  //               padding: 10,
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Email Date
  //             </CustomTypography>
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderBottom: "none",
  //               padding: 0,
  //               textAlign: "center",
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Flight name
  //             </CustomTypography>
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderBottom: "none",
  //               padding: 0,
  //               textAlign: "center",
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Depart Date
  //             </CustomTypography>
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderBottom: "none",
  //               padding: 0,
  //               textAlign: "center",
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Manifest(IDs)
  //             </CustomTypography>
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderBottom: "none",
  //               padding: 0,
  //               textAlign: "center",
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Routing
  //             </CustomTypography>
  //           </TableCell>

  //           <TableCell
  //             style={{
  //               backgroundColor: "#EEEEEE",
  //               borderBottom: "none",
  //               textAlign: "center",
  //               borderTopRightRadius: 8,
  //               borderBottomRightRadius: 8,
  //               padding: 10,
  //             }}
  //           >
  //             <CustomTypography
  //               fontSize="14px"
  //               title={true}
  //               color="#000"
  //               fontWeight={600}
  //             >
  //               Actions
  //             </CustomTypography>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {customClearanceData?.map((x, index) => (
  //           <React.Fragment>
  //             <TableRow
  //               key={index}
  //               style={{
  //                 marginBottom: 10,
  //                 backgroundColor: "#fff",
  //                 borderRadius: 8,
  //                 boxShadow: "0px 0px 4px 0px #00000040",
  //               }}
  //             >
  //               <TableCell
  //                 style={{
  //                   borderBottom: "none",
  //                   textAlign: "center",
  //                   borderTopLeftRadius: 8,
  //                   borderBottomLeftRadius: 8,
  //                   padding: 4,
  //                 }}
  //               >
  //                 <CustomTypography
  //                   fontSize="13px"
  //                   color="#333333"
  //                   fontWeight={400}
  //                 >
  //                   {x?.air_waybill_number}
  //                 </CustomTypography>
  //               </TableCell>
  //               <TableCell
  //                 style={{
  //                   borderBottom: "none",
  //                   textAlign: "center",
  //                   padding: 4,
  //                 }}
  //               >
  //                 <CustomTypography
  //                   fontSize="13px"
  //                   color="#333333"
  //                   fontWeight={400}
  //                 >
  //                   {x?.email_date}
  //                 </CustomTypography>
  //               </TableCell>
  //               <TableCell
  //                 component="th"
  //                 scope="row"
  //                 style={{
  //                   borderBottom: "none",
  //                   padding: 4,
  //                   textAlign: "center",
  //                 }}
  //               >
  //                 <CustomTypography
  //                   fontSize="13px"
  //                   color="#333333"
  //                   fontWeight={400}
  //                 >
  //                   {x?.flight_name}
  //                 </CustomTypography>
  //               </TableCell>
  //               <TableCell
  //                 component="th"
  //                 scope="row"
  //                 style={{
  //                   borderBottom: "none",
  //                   padding: 4,
  //                   textAlign: "center",
  //                 }}
  //               >
  //                 <CustomTypography
  //                   fontSize="13px"
  //                   color="#333333"
  //                   fontWeight={400}
  //                 >
  //                   {x?.depart_date}
  //                 </CustomTypography>
  //               </TableCell>
  //               <TableCell
  //                 component="th"
  //                 scope="row"
  //                 style={{
  //                   borderBottom: "none",
  //                   padding: 4,
  //                   textAlign: "center",
  //                 }}
  //               >
  //                 <CustomTypography
  //                   fontSize="13px"
  //                   color="#333333"
  //                   fontWeight={400}
  //                 >
  //                   {x?.manifest_ids.slice(0, 2).map((id, index) => (
  //                     <React.Fragment key={id._id || index}>
  //                       {id._id}
  //                       {index > 1 && ", "}{" "}
  //                     </React.Fragment>
  //                   ))}
  //                   {x?.manifest_ids.length > 2 &&
  //                     `... (${x?.manifest_ids.length - 2} more)`}{" "}
  //                 </CustomTypography>
  //               </TableCell>
  //               <TableCell
  //                 component="th"
  //                 scope="row"
  //                 style={{
  //                   borderBottom: "none",
  //                   padding: 4,
  //                   textAlign: "center",
  //                 }}
  //               >
  //                 <CustomTypography
  //                   fontSize="13px"
  //                   color="#333333"
  //                   fontWeight={400}
  //                 >
  //                   {x?.routing}
  //                 </CustomTypography>
  //               </TableCell>
  //               <TableCell
  //                 style={{
  //                   borderBottom: "none",
  //                   textAlign: "center",
  //                   padding: 4,
  //                   borderTopRightRadius: 8,
  //                   borderBottomRightRadius: 8,
  //                 }}
  //               >
  //                 {x?.action}
  //               </TableCell>
  //             </TableRow>
  //           </React.Fragment>
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: 'fill', height: '300px' }}
      />
    </div>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {customClearanceData.length === 0 ? (
          <NoDataImage />
        ) : (
          customClearanceData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Air-Waybill Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {x?.air_waybill_number}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Email Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {x?.email_date}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Flight Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {x?.flight_name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Depart Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {x?.depart_date}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Manifest(IDs)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          :{" "}
                          {x?.manifest_ids
                            ?.slice(0, 2)
                            .map((manifest, index) => (
                              <React.Fragment key={index}>
                                {manifest._id}
                                {index === 0 && x?.manifest_ids.length > 1
                                  ? ", "
                                  : ""}
                              </React.Fragment>
                            ))}
                          {x?.manifest_ids.length > 2 &&
                            ` ... (${x?.manifest_ids.length - 2} more)`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Routing
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {x?.routing}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  }

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        {/* <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div> */}

        <div
          style={{
            width: "100%",
            overflow: isMobile() ? "auto" : "hidden",
            paddingBottom: 100,
            maxHeight: "70vh",
          }}
        >
          {isMobile() ? (
            <MobileCardView data={data} />
          ) : (
            <TableContainer
              sx={{
                marginTop: 1,
                maxHeight: "70vh",
                overflowY: "auto",
                paddingInline: 0.4,
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                  paddingBottom: 7,
                }}
              >
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderRadius: 8,
                      boxShadow: "0px 0px 4px 0px #00000040",
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: 8,
                        borderBottom: "none",
                        textAlign: "center",
                        padding: 10,
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Air-Waybill Number
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                        textAlign: "center",
                        padding: 10,
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Email Date
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                        padding: 0,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Flight name
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                        padding: 0,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Depart Date
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                        padding: 0,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Manifest(IDs)
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                        padding: 0,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Routing
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                        textAlign: "center",
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 10,
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Actions
                      </CustomTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customClearanceData?.map((x, index) => (
                    <React.Fragment>
                      <TableRow
                        key={index}
                        style={{
                          marginBottom: 10,
                          backgroundColor: "#fff",
                          borderRadius: 8,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                      >
                        <TableCell
                          style={{
                            borderBottom: "none",
                            textAlign: "center",
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            padding: 4,
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {x?.air_waybill_number}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: "none",
                            textAlign: "center",
                            padding: 4,
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {x?.email_date}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            padding: 4,
                            textAlign: "center",
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {x?.flight_name}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            padding: 4,
                            textAlign: "center",
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {x?.depart_date}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            padding: 4,
                            textAlign: "center",
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {x?.manifest_ids.slice(0, 2).map((id, index) => (
                              <React.Fragment key={id._id || index}>
                                {id._id}
                                {index > 1 && ", "}{" "}
                              </React.Fragment>
                            ))}
                            {x?.manifest_ids.length > 2 &&
                              `... (${x?.manifest_ids.length - 2} more)`}{" "}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            padding: 4,
                            textAlign: "center",
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {x?.routing}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: "none",
                            textAlign: "center",
                            padding: 4,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                          }}
                        >
                          {x?.action}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        {/* <TablePagination
          slotProps={{
            actions: {
              nextButton: {
                disabled: dataCount < rowsPerPage ? true : false
              }
            }
          }}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#ffffff',
            padding: '2px',
            borderTop: '1px solid #dddddd'
          }}
        /> */}
        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedCustomClearance?.air_waybill_number} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />

        <CustomCleranceDetailsModal
          customClerance={selectedCustomClearance}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        ></CustomCleranceDetailsModal>
      </div>
    </>
  );
}

export default CustomerClearanceList
