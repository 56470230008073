import React, { useEffect, useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  styled,
  tableCellClasses,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { omsLocationService } from '../../utils/services/omsLocationService'
import CustomTypography from '../../globalStyles/fonts'
import MoreVertIcon from '@mui/icons-material/MoreVert'
const WarehouseSelectionModal = ({
  open,
  onClose,
  data,
  providers,
  warehouseLocation,
  omsData,
  onAddSuccess
}) => {
  const [selectedWMS, setSelectedWMS] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [currentWarehouse, setCurrentWarehouse] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 })

  const itemsPerPage = 5

  const addOmsLocation = async () => {
    console.log(data)
    try {
      const response = await omsLocationService.create({
        client_id: omsData.client_id,
        oms_config: omsData._id,
        location: data?.find((x) => x.id == selectedLocation),
        provider: selectedWMS
      })
      onAddSuccess && onAddSuccess()
      return response
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }

  const updateOmsLocation = async () => {
    try {
      const response = await omsLocationService.edit(
        {
          client_id: omsData.client_id,
          oms_config: omsData._id,
          location: data?.find((x) => x.id == selectedLocation),
          provider: selectedWMS
        },
        currentWarehouse?._id
      )
      onAddSuccess && onAddSuccess()
      return response
    } catch (error) {
      console.error('Update failed:', error)
    }
  }

  const deleteOmsLocation = async () => {
    try {
      const response = await omsLocationService.delete(currentWarehouse?._id)

      setDeleteConfirmOpen(false)
      onAddSuccess && onAddSuccess()
    } catch (error) {
      console.error('Delete failed:', error)
    }
  }

  const handleConfirm = async () => {
    console.log(selectedLocation, selectedWMS, isEditMode)
    if (isEditMode) {
      await updateOmsLocation()
    } else {
      await addOmsLocation()
    }
    handleReset()
  }

  const handleReset = () => {
    setSelectedWMS('')
    setSelectedLocation(null)
    setIsEditMode(false)
    setCurrentWarehouse(null)
  }

  const handleClose = () => {
    handleReset()
    onClose()
  }

  const handleEdit = (warehouse) => {
    setCurrentWarehouse(warehouse)
    setSelectedWMS(warehouse.provider._id)
    setSelectedLocation(warehouse.location?.id)
    setIsEditMode(true)
  }

  const handleDelete = (warehouse) => {
    setCurrentWarehouse(warehouse)
    setDeleteConfirmOpen(true)
  }

  // Pagination logic
  const totalPages = Math.ceil((warehouseLocation?.length || 0) / itemsPerPage)
  const paginatedWarehouses =
    warehouseLocation?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) || []

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1))
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#EEEEEE',
      border: '1px solid #EAECF0',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: '6px'
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #EAECF0'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F6F6F6',
      border: '1px solid #EAECF0'
    },
    '&:lastChild td, &:lastChild th': {
      border: 0
    }
  }))
  const handleOptionsClick = (event, warehouse) => {
    setCurrentWarehouse(warehouse)
    setAnchorEl(event.currentTarget)
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX
    })
  }
  const handleOptionsClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: 700,
            overflow: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <CustomTypography fontSize={22} fontWeight={600}>
              {isEditMode ? 'Edit WMS & Location Mapping' : 'WMS & Location Mapping'}
            </CustomTypography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="row" gap={2} mb={2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="first-select-label">Select WMS Provider</InputLabel>
              <Select
                key={isEditMode ? 'edit-wms' : 'add-wms'} // Set key based on edit mode
                label="Select WMS Provider"
                labelId="first-select-label"
                value={selectedWMS}
                onChange={(e) => setSelectedWMS(e.target.value)}
                autoFocus={isEditMode && !selectedWMS} // Auto-focus if in edit mode and WMS is not selected
              >
                {providers.map((x) => (
                  <MenuItem key={x._id} value={x._id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth>
              <InputLabel id="second-select-label">Select Location</InputLabel>
              <Select
                key={isEditMode ? 'edit-location' : 'add-location'} // Set key based on edit mode
                label="Select Location"
                labelId="second-select-label"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
                autoFocus={isEditMode && !selectedLocation} // Auto-focus if in edit mode and location is not selected
              >
                {data.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={!selectedWMS || !selectedLocation}
            >
              {isEditMode ? 'Update' : 'Add'}
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <CustomTypography style={{ marginLeft: 1 }}>SL NO</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomTypography>WMS PROVIDER</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomTypography>LOCATION</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomTypography>ACTION</CustomTypography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {paginatedWarehouses.map((warehouse, index) => (
                  <StyledTableRow key={warehouse.id}>
                    <StyledTableCell>
                      <CustomTypography style={{ marginLeft: 1 }}>
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <CustomTypography>{warehouse.provider?.name}</CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <CustomTypography>{warehouse.location?.name}</CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={(e) => handleOptionsClick(e, warehouse)}
                        style={{ position: 'relative' }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <IconButton onClick={handlePreviousPage} disabled={currentPage === 1}>
              <ChevronLeftIcon />
            </IconButton>
            <Typography variant="body2">
              Page {currentPage} of {totalPages}
            </Typography>
            <IconButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
        <MenuItem onClick={() => handleEdit(currentWarehouse)}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete(currentWarehouse)}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} color="error" /> Delete
        </MenuItem>
      </Menu>
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete this ${currentWarehouse?.provider?.name} and ${currentWarehouse?.location?.name} mapping?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteOmsLocation} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default WarehouseSelectionModal
