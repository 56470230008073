import { SvgIcon } from "@mui/material";
import React from "react";

const SortingIcon = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 5.83325H17.5"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M5 10H15"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M8.3335 14.1667H11.6668"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default SortingIcon;
