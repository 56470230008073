import React, { useEffect, useState } from 'react'
import { Card, CardContent, Box, IconButton } from '@mui/material'
import { Bar, Line, Pie } from 'react-chartjs-2'

import CustomTypography from '../../globalStyles/fonts'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isMobile } from '../../globalStyles/mobile'
import createLastSevenDaysLabels, { fetchColorByStatus } from '../../utils/utility'

const GraphCard = ({ title, backgroundColor, smallRoundBoxBg, data_obj, type }) => {
  const [options, setOptions] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (data_obj.length) {
      if (type === 'pie') {
        setData({
          labels: data_obj.map((x) => x.status),
          datasets: [
            {
              label: 'Statuses',
              data: data_obj.map((x) => x.count || 0),
              backgroundColor: data_obj.map((x) => fetchColorByStatus(x.status, 'background_color')),
              borderColor: data_obj.map((x) => fetchColorByStatus(x.status, 'border_color')),
              borderWidth: 1
            }
          ]
        })
        setOptions({
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            }
          }
        })
      } else if (type === 'line') {
        setOptions({
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day'
              },
              grid: {
                display: false
              }
            },
            y: {
              beginAtZero: true,
              max: 100,
              ticks: {
                callback: (value) => `${value}%`
              },
              grid: {
                display: false
              }
            }
          }
        })

        setData({
          labels: createLastSevenDaysLabels(),
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0],
              fill: false,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)'
            }
          ]
        })
      } else if (type === 'bar') {
        setData({
          labels: data_obj.map((x) => x.status),
          datasets: [
            {
              label: 'Last Week',
              data: data_obj.map((x) => x.lastWeekCount),
              backgroundColor: '#156082',
              borderColor: '#156082',
              borderWidth: 1
            },
            {
              label: 'Current Week',
              data: data_obj.map((x) => x.currentWeekCount),
              backgroundColor: '#E97132',
              borderColor: '#E97132',
              borderWidth: 1
            }
          ]
        })
        setOptions({
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom'
            },
            title: {
              display: true,
              text: ''
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              // ticks: {
              //   stepSize: 1,
              // },
              title: {
                display: true,
                text: 'Count'
              }
            },
            x: {
              title: {
                display: true,
                text: 'Status'
              }
            }
          }
        })
      }
    }
  }, [title, data])

  return (
    <Card
      sx={{
        minWidth: isMobile() ? '95%' : 300,
        minHeight: 280,
        padding: 1,
        backgroundColor: backgroundColor || '#F3E8FF',
        borderRadius: '16px',
        position: 'relative',
        margin: 1,
        maxWidth: '100%'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: -30,
          right: -10,
          width: '80px',
          height: '80px',
          backgroundColor: smallRoundBoxBg || '#BF82FF',
          borderRadius: '100%'
        }}
      />
      <CardContent>
        <div
          style={{
            width: '95%',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <CustomTypography fontSize={25} sx={{ fontWeight: '600', color: '#151D48', textAlign: 'left' }}>
              {title || 'SLA'}
            </CustomTypography>
            {/* <IconButton>
            <MoreVertIcon color="primary" />
          </IconButton> */}
          </div>
        </div>
        {data && options ? (
          type === 'pie' ? (
            <Pie data={data} options={options} />
          ) : type === 'line' ? (
            <Line data={data} options={options} />
          ) : type === 'bar' ? (
            <Bar
              data={data}
              options={{
                animation: true,
                responsive: true,
                plugins: {
                  legend: {
                    position: 'bottom'
                  },
                  title: {
                    display: false,
                    text: ''
                  }
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    // ticks: {
                    //   stepSize: 1,
                    // },
                    title: {
                      display: true,
                      text: 'Count'
                    }
                  },
                  x: {
                    title: {
                      display: false,
                      text: 'Status'
                    }
                  },
                  y: {
                    title: {
                      display: false,
                      text: 'Status'
                    }
                  }
                }
              }}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  )
}

export default GraphCard
