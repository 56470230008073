// api.js

import axiosInstance from "../axiosInstance";

export const supplierService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post("/supplier", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (searchValue, client_id, query) => {
    try {
      let api_query = `/supplier?$populate=client`;
      if (client_id) {
        api_query += `&client=${client_id}`;
      }

      // Add search value
      if (searchValue) api_query += `&name[$search]=${searchValue}`;
      if (query.length) api_query += `&${query}`;
      const response = await axiosInstance.get(api_query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (id, data) => {
    try {
      let response = await axiosInstance.patch(`/supplier/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/supplier/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchName: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(`/fetch-supplier-name?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchEmail: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(`/fetch-supplier-email?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCity: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(`/fetch-supplier-city?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchProvince: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/fetch-supplier-province?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCountry: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/fetch-supplier-country?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
