import React, { useState } from 'react'
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Typography
} from '@mui/material'

// Recursive Field Component
const RecursiveFieldInput = ({ field, depth = 0, onFieldChange, onRemove }) => {
  const fieldTypes = [
    { value: '', label: 'Choose Type' },
    { value: 'string', label: 'String' },
    { value: 'number', label: 'Number' },
    { value: 'boolean', label: 'Boolean' },
    { value: 'object', label: 'Object' }
  ]

  // Handle field value changes
  const handleFieldChange = (key, value) => {
    const updatedField = { ...field, [key]: value }

    // Special handling for type changes
    if (key === 'type') {
      if (value === 'object') {
        // If type is object, ensure isNested is true and initialize nestedFields
        updatedField.isNested = true
        updatedField.nestedFields = updatedField.nestedFields || []
      } else {
        // If not an object, reset nested properties
        updatedField.isNested = false
        updatedField.nestedFields = []
      }
    }

    onFieldChange(updatedField)
  }

  // Handle nested field addition
  const handleAddNestedField = () => {
    const newNestedField = {
      key: '',
      type: '',
      isNested: false,
      nestedFields: []
    }

    const updatedField = {
      ...field,
      isNested: true,
      nestedFields: [...(field.nestedFields || []), newNestedField]
    }
    onFieldChange(updatedField)
  }

  // Handle nested field removal
  const handleRemoveNestedField = (nestedIndex) => {
    const updatedNestedFields = field.nestedFields.filter((_, idx) => idx !== nestedIndex)

    onFieldChange({
      ...field,
      nestedFields: updatedNestedFields,
      isNested: updatedNestedFields.length > 0
    })
  }

  // Handle nested field changes
  const handleNestedFieldChange = (nestedIndex, updatedNestedField) => {
    const updatedNestedFields = [...(field.nestedFields || [])]
    updatedNestedFields[nestedIndex] = updatedNestedField

    onFieldChange({
      ...field,
      nestedFields: updatedNestedFields
    })
  }

  return (
    <Box
      mb={2}
      pl={depth * 2}
      sx={{
        backgroundColor: depth % 2 === 0 ? 'rgba(0,0,0,0.05)' : 'transparent',
        borderRadius: 2,
        padding: 1
      }}
    >
      <Box display="flex" alignItems="center">
        <TextField
          size="small"
          label={depth > 0 ? 'Nested Key' : 'Key'}
          value={field.key}
          onChange={(e) => handleFieldChange('key', e.target.value)}
          fullWidth
          variant="outlined"
          margin="dense"
          sx={{ marginRight: 1 }}
        />

        <FormControl size="small" fullWidth margin="dense" sx={{ marginRight: 1 }}>
          <InputLabel>Type</InputLabel>
          <Select
            label="Type"
            value={field.type || ''}
            onChange={(e) => handleFieldChange('type', e.target.value)}
          >
            {fieldTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {onRemove && (
          <IconButton
            color="error"
            onClick={onRemove}
            sx={{
              marginLeft: 1,
              backgroundColor: 'rgba(209, 52, 56, 0.2)',
              '&:hover': { backgroundColor: 'rgba(209, 52, 56, 0.3)' }
            }}
          >
            <img style={{ height: '15px', width: '15px' }} alt="delete" src="/delete.png" />
          </IconButton>
        )}
      </Box>

      {field.isNested && field.type === 'object' && (
        <Box mt={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="subtitle2">Nested Fields for "{field.key || 'Unnamed'}"</Typography>
            <Button variant="outlined" size="small" onClick={handleAddNestedField}>
              Add Nested Field
            </Button>
          </Box>

          {field.nestedFields?.map((nestedField, nestedIndex) => (
            <RecursiveFieldInput
              key={nestedIndex}
              field={nestedField}
              depth={depth + 1}
              onFieldChange={(updatedField) => handleNestedFieldChange(nestedIndex, updatedField)}
              onRemove={() => handleRemoveNestedField(nestedIndex)}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

// Main Event Modal Component
const AddEventModal = ({ handleClose, open }) => {
  const [eventName, setEventName] = useState('')
  const [schemaFields, setSchemaFields] = useState([
    {
      key: '',
      type: '',
      isNested: false,
      nestedFields: []
    }
  ])

  // Handle adding a new top-level field
  const handleAddField = () => {
    setSchemaFields([
      ...schemaFields,
      {
        key: '',
        type: '',
        isNested: false,
        nestedFields: []
      }
    ])
  }

  // Handle removing a top-level field
  const handleRemoveField = (index) => {
    const updatedFields = schemaFields.filter((_, idx) => idx !== index)
    setSchemaFields(updatedFields)
  }

  // Handle changes to top-level fields
  const handleFieldChange = (index, updatedField) => {
    const updatedFields = [...schemaFields]
    updatedFields[index] = updatedField
    setSchemaFields(updatedFields)
  }

  // Save handler
  const handleSave = () => {
    console.log('Event Name:', eventName)
    console.log('Schema:', JSON.stringify(schemaFields, null, 2))
    // Implement your save logic here
  }
  const onClose = () => {
    setSchemaFields([
      {
        key: '',
        type: '',
        isNested: false,
        nestedFields: []
      }
    ])
    setEventName('')
    handleClose && handleClose()
  }
  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>Add New Event</DialogTitle>
      <DialogContent>
        <TextField
          size="small"
          margin="dense"
          label="Event Name"
          fullWidth
          variant="outlined"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />

        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Schema Structure
          </Typography>

          {schemaFields.map((field, index) => (
            <RecursiveFieldInput
              key={index}
              field={field}
              onFieldChange={(updatedField) => handleFieldChange(index, updatedField)}
              onRemove={() => handleRemoveField(index)}
            />
          ))}

          <Button color="primary" variant="contained" onClick={handleAddField} sx={{ marginTop: 2 }}>
            Add Schema Field
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddEventModal
