import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  Card,
  CardContent,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const ProviderDetailsModal = ({ provider, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Provider  Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Name
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="Client Name"
                value={provider?.name || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Type
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={provider?.type || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTypography
                style={{ textAlign: "left", fontWeight: 400 }}
                fontSize={16}
              >
                Provider Details
              </CustomTypography>
              <Card
                style={{
                  marginBottom: 10,
                  backgroundColor: "#EEEEEEEE",
                  marginTop: 10,
                }}
              >
                <CardContent>
                  {provider?.provider_details?.map((x, i) => (
                    <Grid container spacing={2} key={i}>
                      <Grid item xs={12} sm={6}>
                        <CustomTypography>
                          Key
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="key"
                          fullWidth
                          value={x?.key || ""}
                          InputProps={{
                            readOnly: true,
                            style: { color: "#000", backgroundColor: "#f0f0f0" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTypography>
                          Value
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="value"
                          fullWidth
                          value={x?.value || ""}
                          InputProps={{
                            readOnly: true,
                            style: { color: "#000", backgroundColor: "#f0f0f0" },
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProviderDetailsModal;
