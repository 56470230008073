import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  useTheme
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { clientService } from '../../utils/services/clientService'
import CustomTypography from '../../globalStyles/fonts'
const ASPECT_RATIO = 2 / 3
const AccountSettings = ({ open, handleClose }) => {
  const user = localStorage.getItem('user')
  const [imagePreview, setImagePreview] = useState(null)
  const [base64Image, setBase64Image] = useState('')
  const [clientDetails, setClientDetails] = useState(null)
  const [isEditingLogo, setIsEditingLogo] = useState(false)
  const [editPassword, setEditPassword] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isEditingReturnAddress, setIsEditingReturnAddress] = useState(false)
  const [isBillingAddress, setIsbillingAddress] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [crop, setCrop] = useState({
    unit: 'px',
    aspect: ASPECT_RATIO // Fixed aspect ratio 2:3
  })
  const [imageRef, setImageRef] = useState(null)
  const [formData, setFormData] = useState({
    logo: '', // Replace with actual logo
    clientName: '',
    email: '',
    contact: '',
    business_name: '',
    primary_color: '',
    secondary_color: '',
    text_color: ''
  })
  const [returnAddress, setReturnAddress] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    country: '',
    zipcode: ''
  })
  const [billingAddress, setBillingAddress] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    country: '',
    zipcode: ''
  })
  const theme = useTheme()
  const defaultColor = '#FFF'
  const mainTextColor = theme.palette.text.main || defaultColor
  const isStrongPassword = (password) => {
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).*$/
    return strongPasswordRegex.test(password)
  }
  const onClose = () => {
    setIsEditing(false)
    setIsEditingReturnAddress(false)
    setIsbillingAddress(false)
    setEditPassword(false)
    setIsEditingLogo(false)
    setFormData({
      ...formData,
      logo: clientDetails.logo,
      clientName: clientDetails.name,
      business_name: clientDetails?.business_name ?? clientDetails.name,
      contact: clientDetails?.contact ? clientDetails?.contact : '',
      email: clientDetails?.email,
      primary_color: clientDetails?.primary_color,
      secondary_color: clientDetails?.secondary_color,
      text_color: clientDetails?.text_color
    })
    setReturnAddress({
      ...returnAddress,
      name: clientDetails?.return_address?.name,
      address1: clientDetails?.return_address?.address1,
      address2: clientDetails?.return_address?.address2,
      city: clientDetails?.return_address?.city,
      province: clientDetails?.return_address?.province,
      country: clientDetails?.return_address?.country,
      zipcode: clientDetails?.return_address?.zipcode
    })
    setBillingAddress({
      ...returnAddress,
      name: clientDetails?.billing_address?.name,
      address1: clientDetails?.billing_address?.address1,
      address2: clientDetails?.billing_address?.address2,
      city: clientDetails?.billing_address?.city,
      province: clientDetails?.billing_address?.province,
      country: clientDetails?.billing_address?.country,
      zipcode: clientDetails?.billing_address?.zipcode
    })
    handleClose && handleClose()
  }
  const handleColorChange = (color, field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: color.hex
    }))
  }
  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target?.value })
  }
  const handleReturnAddressChange = (e) => {
    const { name, value } = e.target
    setReturnAddress((prevReturnAddress) => ({
      ...prevReturnAddress,
      [name]: value
    }))
  }
  const handleBillingAddressChange = (e) => {
    const { name, value } = e.target
    setBillingAddress((prevReturnAddress) => ({
      ...prevReturnAddress,
      [name]: value
    }))
  }

  // Toggle editing mode
  const toggleEdit = () => {
    setIsEditing(!isEditing)
  }
  const onImageLoaded = (image) => {
    setImageRef(image)
  }
  const checkAspectRatio = () => {
    const aspectRatio = crop.width / crop.height
    console.log(crop.width / crop.height)
    console.log(2 / 3)
    if (aspectRatio < 2 / 3) {
      return false
    }
    return true
  }
  // Crop the image using the selected area
  const getCroppedImage = () => {
    // Check aspect ratio before cropping
    if (!checkAspectRatio()) {
      return toast.warning('Give a proper aspect ratio of 2:3 ')
    }
    if (imageRef && crop.width && crop.height) {
      const canvas = document.createElement('canvas')
      const scaleX = imageRef.naturalWidth / imageRef.width
      const scaleY = imageRef.naturalHeight / imageRef.height
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')

      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )

      const base64Image = canvas.toDataURL('image/jpeg')
      setBase64Image(base64Image)
      setCroppedImage(base64Image)
      setImagePreview(base64Image)
      setIsCropModalOpen(false)
    }
  }
  // Handle image upload and convert to base64
  const {
    getRootProps,
    getInputProps,
    open: triggerFileInput
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const reader = new FileReader()
      reader.onload = () => {
        setImagePreview(URL.createObjectURL(acceptedFiles[0]))
        setIsCropModalOpen(true) // Open crop modal after selecting the image
      }
      reader.readAsDataURL(acceptedFiles[0])
    },
    noClick: true,
    noKeyboard: true
  })

  const fetchClientById = async () => {
    try {
      let client = await clientService.get(localStorage.getItem('client_id'))
      setClientDetails(client)
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    if (clientDetails) {
      setFormData({
        ...formData,
        logo: clientDetails.logo,
        clientName: clientDetails.name,
        business_name: clientDetails?.business_name ?? clientDetails.name,
        contact: clientDetails?.contact ? clientDetails?.contact : '',
        email: clientDetails?.email,
        primary_color: clientDetails.primary_color,
        secondary_color: clientDetails.secondary_color,
        text_color: clientDetails.text_color
      })
      setReturnAddress({
        name: clientDetails?.return_address?.name || '',
        address1: clientDetails?.return_address?.address1 || '',
        address2: clientDetails?.return_address?.address2 || '',
        city: clientDetails?.return_address?.city || '',
        province: clientDetails?.return_address?.province || '',
        country: clientDetails?.return_address?.country || '',
        zipcode: clientDetails?.return_address?.zipcode || ''
      })
      setBillingAddress({
        name: clientDetails?.billing_address?.name || '',
        address1: clientDetails?.billing_address?.address1 || '',
        address2: clientDetails?.billing_address?.address2 || '',
        city: clientDetails?.billing_address?.city || '',
        province: clientDetails?.billing_address?.province || '',
        country: clientDetails?.billing_address?.country || '',
        zipcode: clientDetails?.billing_address?.zipcode || ''
      })
    }
  }, [clientDetails, user])

  useEffect(() => {
    if (localStorage.getItem('client_id')) {
      fetchClientById(user)
    }
  }, [user])

  const handleSave = async () => {
    try {
      let response = await clientService.edit(
        base64Image
          ? {
              ...formData,
              logo: base64Image,
              return_address: {
                ...returnAddress
              }
            }
          : isBillingAddress
          ? {
              clientName: formData.name,
              business_name: formData?.business_name,
              contact: formData?.contact ? formData?.contact : '',
              email: formData?.email,
              primary_color: formData?.primary_color,
              secondary_color: formData?.secondary_color,
              text_color: formData?.text_color,

              billing_address: {
                name: billingAddress?.name,
                address1: billingAddress?.address1,
                address2: billingAddress?.address2,
                city: billingAddress?.city,
                province: billingAddress?.province,
                country: billingAddress?.country,
                zipcode: billingAddress?.zipcode
              }
            }
          : {
              clientName: formData.name,
              business_name: formData?.business_name,
              contact: formData?.contact ? formData?.contact : '',
              email: formData?.email,
              primary_color: formData?.primary_color,
              secondary_color: formData?.secondary_color,
              text_color: formData?.text_color,
              return_address: {
                name: returnAddress?.name,
                address1: returnAddress?.address1,
                address2: returnAddress?.address2,
                city: returnAddress?.city,
                province: returnAddress?.province,
                country: returnAddress?.country,
                zipcode: returnAddress?.zipcode
              }
            },
        localStorage.getItem('client_id')
      )
      if (response) {
        fetchClientById()
        toast.success('Updated Successfully')
        if (base64Image) {
          window.location.reload()
        }
      }
      setIsEditingLogo(false)
      setIsEditing(false)
      setIsEditingReturnAddress(false)
      setIsbillingAddress(false)
    } catch (e) {
      console.error(e)
      throw e
    }

    // Here, you'd usually send the data to the backend or handle state accordingly.
    onClose()
  }

  const handleImageLoad = (event) => {
    setImageRef(event.target) // Capturing the image element
  }
  const [showPassword, setShowPassword] = useState(false)
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value })
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleSavePassword = async () => {
    if (!passwordData.newPassword || !passwordData.confirmPassword) {
      toast.error('Please enter new password and confirm password', {
        autoClose: 3000
      })
      return
    } else if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast.error('Passwords do not match', { autoClose: 3000 })
      return
    } else if (!isStrongPassword(passwordData.confirmPassword)) {
      toast.error(
        'Password must be at least 4 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character!',
        {
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        }
      )
      return
    } else {
      try {
        let response = await clientService.updatePassword({
          email: JSON.parse(user).email,
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword
        })
        toast.success('Password updated successfully.')
        setEditPassword(false)
        setPasswordData({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        })
      } catch (error) {
        toast.error('Failed to update password. Please try again.')
        console.error('Password update error:', error)
      }
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexDirection: 'column',
        maxHeight: '90vh',
        paddingBottom: 5
      }}
    >
      <Grid style={{ margin: 15 }} container justifyContent="space-between" alignItems="center">
        <CustomTypography style={{ fontWeight: 'bold' }} variant="h6">
          ACCOUNT SETTINGS
        </CustomTypography>
      </Grid>

      <Box sx={{ padding: 2, width: '100%', position: 'relative' }}>
        {/* Logo Section */}
        <Grid container spacing={2}>
          <Grid
            container
            sx={{
              borderRadius: '8px',
              padding: '16px',
              alignItems: 'center',
              margin: 2,
              backgroundColor: '#FFF',
              borderBottom: '0.5px solid #979797'
            }}
          >
            <Grid
              style={{
                minWidth: '40%',
                justifyContent: 'start',
                display: 'flex',
                flexDirection: 'row'
              }}
              item
            >
              <CustomTypography color={'#33333'} style={{ fontWeight: '500', height: 20 }} variant="body1">
                Logo
              </CustomTypography>
            </Grid>
            <Grid item>
              <div {...getRootProps()}>
                {imagePreview || formData.logo ? (
                  <Tooltip title={'Update your logo'}>
                    <img
                      src={imagePreview || formData.logo}
                      alt="Logo"
                      style={{
                        width: '150px',
                        height: '80px',
                        objectFit: 'contain',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setIsEditingLogo(true)
                        triggerFileInput()
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Box
                    onClick={() => {
                      setIsEditingLogo(true)
                      triggerFileInput()
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40, marginRight: 10 }}
                      src="./upload.png"
                      alt="plus"
                    ></img>
                    <CustomTypography
                      style={{
                        fontWeight: '500',
                        color: '#979797',
                        textDecoration: 'underline'
                      }}
                    >
                      Add your logo
                    </CustomTypography>
                  </Box>
                )}
                <input {...getInputProps()} />
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Client Information Section */}
        <Box
          sx={{
            borderRadius: '8px',
            marginTop: '20px',
            padding: '20px',
            backgroundColor: '#FFF',
            width: '99%',
            position: 'relative',
            borderBottom: '0.5px solid #979797'
          }}
        >
          <Grid container spacing={2}>
            {/* Client Name */}
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography color={'#33333'} style={{ fontWeight: '500' }} variant="body1">
                  Client Name
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditing ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="clientName"
                    value={formData.clientName}
                    onChange={handleChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {formData.clientName}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography color={'#33333'} style={{ fontWeight: '500' }} variant="body1">
                  Client UID
                </CustomTypography>
              </Grid>
              <Grid item>
                <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                  : {clientDetails?.client_uid}
                </CustomTypography>
              </Grid>
            </Grid>

            {/* Email */}
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography color={'#33333'} style={{ fontWeight: '500' }} variant="body1">
                  Email
                </CustomTypography>
              </Grid>
              <Grid item>
                <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                  : {formData.email}
                </CustomTypography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Mobile Number
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditing ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {formData.contact}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            {/* Mobile Number */}
            {/*  */}

            {/* Business Name */}
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Business Name
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditing ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {formData.business_name}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: 500 }} variant="body1">
                  Primary Color
                </CustomTypography>
              </Grid>
              <Grid item xs={2}>
                {isEditing ? (
                  <TextField
                    type="color"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="primary_color"
                    value={formData.primary_color}
                    onChange={(e) => handleColorChange({ hex: e.target.value }, 'primary_color')}
                    sx={{
                      '& input[type="color"]': {
                        width: '100%'
                      }
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    type="color"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="primary_color"
                    value={formData.primary_color}
                    InputProps={{
                      readOnly: true
                    }}
                    sx={{
                      '& input[type="color"]': {
                        width: '100%'
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography fontWeight={500} variant="body1">
                  Secondary Color
                </CustomTypography>
              </Grid>
              <Grid item xs={2}>
                {isEditing ? (
                  <TextField
                    type="color"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="secondary_color"
                    value={formData.secondary_color}
                    onChange={(e) => handleColorChange({ hex: e.target.value }, 'secondary_color')}
                    sx={{
                      //  padding: 0,
                      '& input[type="color"]': {
                        width: '100%'
                      }
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    type="color"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="secondary_color"
                    value={formData.secondary_color}
                    InputProps={{
                      readOnly: true
                    }}
                    sx={{
                      '& input[type="color"]': {
                        width: '100%'
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: 500 }} variant="body1">
                  Text Color
                </CustomTypography>
              </Grid>
              <Grid item xs={2}>
                {isEditing ? (
                  <TextField
                    type="color"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="text_color"
                    value={formData.text_color}
                    onChange={(e) => handleColorChange({ hex: e.target.value }, 'text_color')}
                    sx={{
                      //  padding: 0,
                      '& input[type="color"]': {
                        width: '100%'
                      }
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    type="color"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="secondary_color"
                    value={formData.text_color}
                    InputProps={{
                      readOnly: true
                    }}
                    sx={{
                      '& input[type="color"]': {
                        width: '100%'
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={2} sx={{ position: 'absolute', right: 1, top: -15 }}>
              {/* Edit Icon for Logo */}
              {!isEditing && (
                <IconButton onClick={() => setIsEditing(true)}>
                  <DriveFileRenameOutlineIcon sx={{ color: '#FF5733' }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
        {isEditing || isEditingLogo ? (
          <Box
            sx={{
              margin: 2,
              width: '95%',
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            <Button
              style={{ marginRight: 15, color: 'red', borderColor: 'red' }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button style={{ color: mainTextColor }} variant="contained" onClick={handleSave}>
              Update
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid
          container
          sx={{
            borderRadius: '8px',
            padding: '16px',
            alignItems: 'center',
            margin: 4,
            backgroundColor: '#FFF',
            position: 'relative',
            borderBottom: '0.5px solid #979797'
          }}
        >
          <Grid item container>
            {editPassword ? (
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <Grid
                    sx={{
                      textAlign: 'left',
                      minWidth: '40%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    item
                    xs={4}
                  >
                    <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                      Old Password
                    </CustomTypography>
                  </Grid>
                  <TextField
                    placeholder="Old Password"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="oldPassword"
                    type={showPassword ? 'text' : 'password'}
                    value={passwordData.oldPassword}
                    onChange={handlePasswordChange}
                  />
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 1
                  }}
                >
                  <Grid
                    sx={{
                      textAlign: 'left',
                      minWidth: '40%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    item
                    xs={4}
                  >
                    <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                      New Password
                    </CustomTypography>
                  </Grid>
                  <TextField
                    placeholder="New Password"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="newPassword"
                    type={showPassword ? 'text' : 'password'}
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                  />
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 1
                  }}
                >
                  <Grid
                    sx={{
                      textAlign: 'left',
                      minWidth: '40%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    item
                    xs={4}
                  >
                    <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                      Confirm Password
                    </CustomTypography>
                  </Grid>
                  <TextField
                    placeholder="Confirm Password"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                  />
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Grid
                  sx={{
                    textAlign: 'left',
                    minWidth: '40%',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  item
                  xs={4}
                >
                  <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                    Password
                  </CustomTypography>
                </Grid>
                <Box
                  style={{
                    color: '#273894',
                    fontWeight: '500',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CustomTypography>:</CustomTypography>
                  <CustomTypography style={{ marginTop: 1, marginLeft: 1 }}>*********</CustomTypography>
                </Box>
              </Box>
            )}

            <Grid item>
              <Grid item xs={2} sx={{ position: 'absolute', right: 1, top: 1 }}>
                {!editPassword && (
                  <IconButton onClick={() => setEditPassword(true)}>
                    <DriveFileRenameOutlineIcon sx={{ color: '#FF5733' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {editPassword ? (
          <Box
            sx={{
              margin: 2,
              width: '95%',
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            <Button
              style={{ marginRight: 15, color: 'red', borderColor: 'red' }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSavePassword} style={{ color: mainTextColor }}>
              Update
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
      <Box sx={{ padding: 2, width: '100%', position: 'relative' }}>
        <Box
          sx={{
            borderRadius: '8px',
            marginTop: '20px',
            padding: '20px',
            backgroundColor: '#FFF',
            width: '99%',
            position: 'relative',
            borderBottom: '0.5px solid #979797'
          }}
        >
          <Grid container spacing={2}>
            <CustomTypography style={{ fontWeight: '600', padding: 2, color: '#5D5FEF' }} variant="body1">
              Billing Address
            </CustomTypography>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Name
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="name"
                    value={billingAddress?.name}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.name}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Address1
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="address1"
                    value={billingAddress?.address1}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.address1}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Address2
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="address2"
                    value={billingAddress?.address2}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.address2}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  City
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="city"
                    value={billingAddress?.city}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.city}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Province
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="province"
                    value={billingAddress?.province}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.province}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Country
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="country"
                    value={billingAddress?.country}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.country}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Zipcode
                </CustomTypography>
              </Grid>
              <Grid item>
                {isBillingAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="zipcode"
                    value={billingAddress?.zipcode}
                    onChange={handleBillingAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {billingAddress?.zipcode}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2} sx={{ position: 'absolute', right: 1, top: -15 }}>
              {!isBillingAddress && (
                <IconButton onClick={() => setIsbillingAddress(true)}>
                  <DriveFileRenameOutlineIcon sx={{ color: '#FF5733' }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
        {isBillingAddress ? (
          <Box
            sx={{
              margin: 2,
              width: '95%',
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            <Button
              style={{ marginRight: 15, color: 'red', borderColor: 'red' }}
              variant="outlined"
              onClick={onClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave} style={{ color: mainTextColor }}>
              Update
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{ padding: 2, width: '100%', position: 'relative' }}>
        <Box
          sx={{
            borderRadius: '8px',
            marginTop: '20px',
            padding: '20px',
            backgroundColor: '#FFF',
            width: '99%',
            position: 'relative',
            borderBottom: '0.5px solid #979797'
          }}
        >
          <Grid container spacing={2}>
            <CustomTypography style={{ fontWeight: '600', padding: 2, color: '#5D5FEF' }} variant="body1">
              Return Address
            </CustomTypography>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Name
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="name"
                    value={returnAddress?.name}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.name}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Address1
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="address1"
                    value={returnAddress?.address1}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.address1}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Address2
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="address2"
                    value={returnAddress?.address2}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.address2}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  City
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="city"
                    value={returnAddress?.city}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.city}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Province
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="province"
                    value={returnAddress?.province}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.province}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Country
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="country"
                    value={returnAddress?.country}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.country}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid sx={{ textAlign: 'left', minWidth: '40%' }} item xs={4}>
                <CustomTypography style={{ fontWeight: '500' }} variant="body1">
                  Zipcode
                </CustomTypography>
              </Grid>
              <Grid item>
                {isEditingReturnAddress ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="zipcode"
                    value={returnAddress?.zipcode}
                    onChange={handleReturnAddressChange}
                  />
                ) : (
                  <CustomTypography style={{ color: '#273894', fontWeight: '500' }}>
                    : {returnAddress?.zipcode}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2} sx={{ position: 'absolute', right: 1, top: -15 }}>
              {!isEditingReturnAddress && (
                <IconButton onClick={() => setIsEditingReturnAddress(true)}>
                  <DriveFileRenameOutlineIcon sx={{ color: '#FF5733' }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
        {isEditingReturnAddress ? (
          <Box
            sx={{
              margin: 2,
              width: '95%',
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            <Button
              style={{ marginRight: 15, color: 'red', borderColor: 'red' }}
              variant="outlined"
              onClick={onClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave} style={{ color: mainTextColor }}>
              Update
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Dialog open={isCropModalOpen} onClose={() => setIsCropModalOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          <ReactCrop crop={crop} onChange={setCrop} onImageLoaded={onImageLoaded}>
            <img onLoad={handleImageLoad} src={imagePreview}></img>
          </ReactCrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCropModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={getCroppedImage} color="primary">
            Crop & Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default AccountSettings
